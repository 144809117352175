import React, { Component } from 'react';
import { BrowserRouter, Route, Redirect, Switch } from 'react-router-dom';

import './App.css';
import ApiData from './ApiData';
import ApiDomainReport from './ApiDomainReport';
import ApiReport from './ApiReport';
import Agreement from './Agreement';
import Earnings from './Earnings';
import Header from './Header';
import Home from './Home';
import Item from './Item';
import ItemData from './ItemData';
import ItemFeed from './ItemFeed';
import Items from './Items';
import Live from './Live';
import Login from './Login';
import Messages from './Messages';
import Monitor from './Monitor';
import Order from './Order';
import Orders from './Orders';
import Payout from './Payout';
import Preview from './Preview';
import Previews from './Previews';
import PreviewTrash from './PreviewTrash';
import Publish from './Publish';
import Reception from './Reception';
import Receptionist from './Receptionist';
import Register from './Register';
import Scenario from './Scenario';
import Scenarios from './Scenarios';
import ScenarioTrash from './ScenarioTrash';
import Shop from './Shop';
import ShopDetail from './ShopDetail';
import Streamer from './Streamer';
import Theme from './Theme';
import Themes from './Themes';
import Whitelist from './Whitelist';
import firebase from './firebase';
import Utilities from './Utilities';
import PasswordChange from './PasswordChange';

let permission = {};

const renderMergedProps = (component, ...rest) => {
  const finalProps = Object.assign({}, ...rest, {permission: permission});
  return React.createElement(component, finalProps);
};

const PrivateRoute = ({ component, authed, allow, ...rest }) => {
  const match = window.location.pathname.match(/^\/(receptionist|streamer)/);
  const root = match ? `/${match[1]}` : '';

  if (!authed) {
    return (
      <Route {...rest} render={(props) => 
        <Redirect to={{
          pathname: `${root}/login`,
          state: { from: props.location },
        }} />
      } />
    );
  } else if (allow) {

    if (allow === 'seller') {
      return (
        <Route {...rest} render={(props) => permission.seller ?
          renderMergedProps(component, props, rest) :
          <Redirect to={{
            pathname: `/streamer`,
            state: { from: props.location },
          }} />
        } />
      );
    }

    if (allow === 'reception') {
      return (
        <Route {...rest} render={(props) => permission.reception ?
          renderMergedProps(component, props, rest) :
          <Redirect to={{
            pathname: permission.seller ? `/` : `/streamer`,
            state: { from: props.location },
          }} />
        } />
      );
    }

  } else {
    return (
      <Route {...rest} render={(props) => 
        renderMergedProps(component, props, rest)
      } />
    );
  }
};

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      authed: false,
      loading: true,
    };
  }

  componentDidMount = async () => {
    this.offAuth = firebase.auth().onAuthStateChanged( async (user) => {
      if (user) {
        this.setState({ loading: true });
        for (let retry = 0; retry < 5; retry++) {
          await Utilities.getAccountPermission(user.uid, permission);
          if (permission.seller !== undefined) {
            break;
          }
        }
        this.setState({ authed: true , loading: false });
      } else {
        this.setState({ authed: false, loading: false });
      }
    });
  }
  componentWillUnmount() {
    this.offAuth();
  }

  render = () => (
    <BrowserRouter>
      <div className="App">
        <Switch>
          <Route path="/login" />
          <Route><Header loading={this.state.loading} /></Route>
        </Switch>
        {!this.state.loading &&
        <Switch>
          <Route path="/register" component={Register} />
          <Route path="/login" component={Login} />
          <Route path="/streamer/register/:shopId" component={Register} />
          <Route path="/streamer/register" component={Register} />
          <Route path="/streamer/login" component={Login} />
          <Route path="/receptionist/register/:shopId" component={Register} />
          <Route path="/receptionist/register" component={Register} />
          <Route path="/receptionist/login" component={Login} />
          <PrivateRoute authed={this.state.authed} allow="seller" exact path="/" component={Home} />
          <PrivateRoute authed={this.state.authed} allow="seller" path="/shop" component={Shop} />
          <PrivateRoute authed={this.state.authed} path="/shopdetail" component={ShopDetail} />
          <PrivateRoute authed={this.state.authed} allow="seller" path="/itemdata" component={ItemData} />
          <PrivateRoute authed={this.state.authed} allow="seller" exact path="/items" component={Items} />
          <PrivateRoute authed={this.state.authed} allow="seller" path="/itemFeed" component={ItemFeed} />
          <PrivateRoute authed={this.state.authed} allow="seller" path="/items/:id" component={Item} />
          <PrivateRoute authed={this.state.authed} allow="seller" path="/publish" component={Publish} />
          <PrivateRoute authed={this.state.authed} allow="seller" exact path="/orders" component={Orders} />
          <PrivateRoute authed={this.state.authed} allow="seller" path="/orders/:id" component={Order} />
          <PrivateRoute authed={this.state.authed} allow="seller" path="/earnings" component={Earnings} />
          <PrivateRoute authed={this.state.authed} allow="seller" path="/payout" component={Payout} />
          <PrivateRoute authed={this.state.authed} allow="seller" exact path="/themes" component={Themes} />
          <PrivateRoute authed={this.state.authed} allow="seller" path="/themes/new" component={Theme} />
          <PrivateRoute authed={this.state.authed} allow="seller" path="/themes/edit/:id" component={Theme} edit={true} />
          <PrivateRoute authed={this.state.authed} allow="seller" exact path="/scenarios"
            component={Scenarios} />
          <PrivateRoute authed={this.state.authed} allow="seller" exact path="/scenarios/trash"
            component={ScenarioTrash} />
          <PrivateRoute authed={this.state.authed} allow="seller" path="/scenarios/new"
            component={Scenario} />
          <PrivateRoute authed={this.state.authed} allow="seller" path="/scenarios/edit/:id"
            component={Scenario} edit={true} />
          <PrivateRoute authed={this.state.authed} exact path="/streamer" component={Streamer} />
          <PrivateRoute authed={this.state.authed} exact path="/streamer/previews"
            component={Previews} />
          <PrivateRoute authed={this.state.authed} exact path="/streamer/previews/trash"
            component={PreviewTrash} />
          <PrivateRoute authed={this.state.authed} path="/streamer/previews/new"
            component={Preview} />
          <PrivateRoute authed={this.state.authed} path="/streamer/previews/edit/:id"
            component={Preview} edit={true} />
          <PrivateRoute authed={this.state.authed} path="/streamer/live/:id" component={Live} />
          <PrivateRoute authed={this.state.authed} path="/streamer/monitor/:id" component={Monitor} />
          <PrivateRoute authed={this.state.authed} path="/streamer/messages/:id" component={Messages} />
          <PrivateRoute authed={this.state.authed} path="/streamer/apidata/:id" component={ApiData} />
          <PrivateRoute authed={this.state.authed} path="/streamer/report" component={ApiReport} />
          <PrivateRoute authed={this.state.authed} allow="reception" exact path="/receptionist" component={Receptionist} />
          <PrivateRoute authed={this.state.authed} allow="reception" path="/receptionist/reception" component={Reception} />
          <PrivateRoute authed={this.state.authed} path="/agreement" component={Agreement} />
          <PrivateRoute authed={this.state.authed} path="/admin/report" component={ApiDomainReport} />
          <PrivateRoute authed={this.state.authed} path="/admin/whitelist" component={Whitelist} />
          <PrivateRoute path="/ib/password-change" authed={this.state.authed} component={PasswordChange} />
        </Switch>}
      </div>
    </BrowserRouter>
  );
}

export default App;