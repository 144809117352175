import React, { Component } from 'react';
import {
  Button,
  Col,
  ControlLabel,
  FormGroup,
  FormControl,
} from 'react-bootstrap';
import AdminNav from './AdminNav';
import firebase from './firebase';

export default class Whitelist extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      isAdmin: false,
      isDirty: false,
      isSaved: false,
      domains: [],
      newDomain: '',
      errorMessage: '',
    };
  }

  componentDidMount() {
    const uid = firebase.auth().currentUser.uid;
    firebase.firestore().collection('admins').doc(uid).get().then((documentSnapshot) => {
      this.setState({
        loading: false,
        isAdmin: documentSnapshot.exists,
      });
      if (!this.state.isAdmin) {
        return;
      }
      firebase.firestore().collection('admin_settings').doc('whitelist').get().then((querySnapshot) => {
        const domains = querySnapshot.exists ? querySnapshot.data().domains : [];
        this.setState({ domains: domains.sort() });
      });
    });
  }

  editNewDomain(event) {
    this.setState({ newDomain: event.target.value });
  }

  appendDomain() {
    const { domains, newDomain } = this.state;
    if (!newDomain) {
      this.setState({ errorMessage: 'ドメインが未入力です' })
      return;
    }
    if (domains.includes(newDomain)) {
      this.setState({ errorMessage: `${newDomain} は既に存在します` })
      return;
    }
    domains.push(newDomain);
    this.setState({
      isDirty: true,
      isSaved: false,
      domains: domains.sort(),
      newDomain: '',
      errorMessage: ''
    });
  }

  removeDomain(index) {
    const { domains } = this.state;
    domains.splice(index, 1);
    this.setState({
      isDirty: true,
      isSaved: false,
      domains: domains.sort(),
    });
  }

  updateDomains() {
    const { domains } = this.state;
    firebase.firestore().collection('admin_settings').doc('whitelist').update({
      domains: Array.from(domains.reduce((previous, current) => previous.add(current), new Set()).values()),
    });
    this.setState({
      isDirty: false,
      isSaved: true,
    });
  }

  render() {
    const content = () => {
      if (this.state.loading) {
        return <span>読み込み中...</span>;
      } else {
        if (this.state.isAdmin) {
          return (
            <div>
              <div className="card" style={{ marginBottom: 60 }}>
                <form className="form form-horizontal">
                  <FormGroup controlId="append">
                    <Col xs={12} sm={1} componentClass={ControlLabel}>
                    </Col>
                    <Col xs={12} sm={8}>
                      <FormControl
                        className="mb10"
                        name="append"
                        type="text"
                        placeholder="example.com"
                        value={this.state.newDomain}
                        onChange={(event) => this.editNewDomain(event) }
                      />
                      <p className="error mt10">{this.state.errorMessage}</p>
                    </Col>
                    <Col xs={12} sm={3}>
                      <Button className="normalBtn" onClick={() => this.appendDomain()}>
                        追加
                      </Button>
                    </Col>
                  </FormGroup>
                  <hr />
                  {this.state.domains.map((domain, index) => (
                  <FormGroup key={index} controlId={domain}>
                    <Col xs={12} sm={1} componentClass={ControlLabel}>
                      {index + 1}
                    </Col>
                    <Col xs={12} sm={8}>
                      <FormControl
                        className="mb10"
                        name={domain}
                        type="text"
                        placeholder="example.com"
                        value={domain}
                      />
                    </Col>
                    <Col xs={12} sm={3}>
                      <Button className="cautionBtn" onClick={() => this.removeDomain(index)}>
                        削除
                      </Button>
                    </Col>
                  </FormGroup>
                  ))}
                </form>
              </div>
              <div className="fixFooter">
                <div className="fixFooterInner">
                  <div className="fixFooterBtnArea">
                    {this.state.isDirty && (
                      <span className="error mr10">Domain Whitelist は編集されています</span>
                    )}
                    {this.state.isSaved && (
                      <span className="success mr10">Domain Whitelist は保存されました</span>
                    )}
                    <Button bsStyle="warning" className="marginRightL" onClick={() => this.updateDomains()}>
                      登録する
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          );
        } else {
          return <span>閲覧権限がありません</span>;
        }
      }
    };
    return (
      <div>
        <AdminNav />
        <div className="content">
          <h2>Domain Whitelist</h2>
          {content()}
        </div>
      </div>
    );
  }
}
