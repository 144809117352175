import React, { Component } from 'react';
import Dropzone from 'react-dropzone'
import {
  Button,
  Checkbox,
  Col,
  ControlLabel,
  DropdownButton,
  FormControl,
  FormGroup,
  MenuItem,
  Nav,
  NavItem,
  Radio,
} from 'react-bootstrap';
import { Redirect } from 'react-router-dom';
import DateTime from 'react-datetime';
import moment from 'moment';

import SellerNav from './SellerNav';
import firebase from './firebase';
import model from './Model';
import Help from './Help';

import doneIcon from './img/icon/icon_done.svg';

import { SCENARIO_MAX } from './Scenarios';

import Config from './Config';

const db = firebase.firestore();
const ENABLE_MIDDLE_OPTION = false;

const DefaultScenario = {
  name: '',
  from: null,
  to: null,
  pcImage: null,
  spImage: null,
  pcImageWidth: 300,
  spImageWidth: 300,
  delay: 0,
  position: 'bottom-left',
  marginTop: 30,
  marginLeft: 30,
  marginRight: 30,
  marginBottom: 30,
  spPosition: 'bottom-left',
  spMarginTop: 30,
  spMarginLeft: 30,
  spMarginRight: 30,
  spMarginBottom: 30,
  includeUrlPatterns: [],
  excludeUrlPatterns: [],
  restrictionUrlPattern: null,
  inLive: false,
  autoPlay: false,
  visibleTime: 100,
  action: 'live',
  liveSs: 0,
  shopId: null,
  liveId: null,
  receptionistIds: [],
  recent: 'next',
  active: false,
};

export default class Scenario extends Component {
  constructor(props) {
    super(props);
    this.state = {
      processing: false,
      succeeded: false,
      loading: false,
      action: 'live',
      shopIndex: 0,
      shops: [],
      receptionists: [],
      liveIndex: 0,
      lives: [],
      id: null,
      scenariosCount: 0,
      ...DefaultScenario,
    };
  }

  componentDidMount = async () => {
    this.setState({ loading: true });
    const shops = [];
    let receptionists = [];
    const addShop = doc => {
      if (!shops.find(shop => shop.id === doc.id)) {
        shops.push(doc.data());
      }
    }
    const uid = firebase.auth().currentUser.uid;
    (await db.collection('shops').where('uid', '==', uid).get()).forEach(addShop);
    (await db.collection('shops').where('admin', '==', uid).get()).forEach(addShop);

    for(let shop of shops) {
      const receptionsDoc = await db.collection('shops').doc(shop.id).collection('receptionists').get();
      receptionists = receptionists.concat(receptionsDoc.docs.map(data => {
        return {
          id: data.id,
          ...data.data()
        }
      }))
    }
    if (this.props.edit) {
      const scenarioDoc = await db.collection('scenarios').doc(this.props.match.params.id).get();
      const scenario = scenarioDoc.exists ? scenarioDoc.data() : null;
      if (!scenario || !shops.map(s=>s.id).includes(scenario.shopId)) {
        window.location.href = '/scenarios';
        return;
      }
      this.setState({
        ...this.state,
        ...scenario,
        from: moment(scenario.from.toMillis()),
        to: moment(scenario.to.toMillis()),
      });
    }else{
      const query = db
        .collection('scenarios')
        .where('uid', '==', uid)
        .orderBy('from', 'desc')
        .orderBy('to', 'desc')
        .orderBy('updated', 'desc');
      const scenarios = (await query.get()).docs.map(doc => doc.data()).filter(data => !data.deletedAt);
      const receptionistIds = receptionists.map(receptionist => receptionist.id);
      this.setState({ scenariosCount: scenarios.length, receptionistIds });
    }
    this.setState({ loading: false, shops, receptionists });
    if (shops.length) {
      await this.onSelectShop(0);
    }
  };

  normalizeText = (value) => {
    const text = value.trim();
    return text;
  };

  normalizeNumber = (value) => {
    const text = value.trim();
    if (!text) {
      return '';
    }
    return Number(text);
  };

  validate = () => {
    const errors = {
      name: [],
      from: [],
      to: [],
      pcImage: [],
      spImage: [],
      pcImageWidth: [],
      spImageWidth: [],
      delay: [],
      position: [],
      spPosition: [],
      marginTop: [],
      marginLeft: [],
      marginRight: [],
      marginBottom: [],
      includeUrlPatterns: [],
      excludeUrlPatterns: [],
      visibleTime: [],
      inLive: [],
      autoPlay: [],
      liveSs: [],
      shopId: [],
      liveId: [],
      recent: [],
      active: [],
      count: 0,
    };

    if (!this.state.name) {
      errors.name.push('入力してください');
      errors.count += 1;
    }

    if (!this.state.from) {
      errors.from.push('選択してください');
      errors.count += 1;
    }

    if (!this.state.to) {
      errors.to.push('選択してください');
      errors.count += 1;
    }

    if (this.state.to <= this.state.from) {
      errors.to.push('開始より後の日時を選択してください');
      errors.count += 1;
    }

    if (!this.state.pcImage) {
      errors.pcImage.push('選択してください');
      errors.count += 1;
    }

    if (!this.state.pcImageWidth) {
      errors.pcImageWidth.push('入力してください');
      errors.count += 1;
    }

    if (!this.state.spImageWidth) {
      errors.spImageWidth.push('入力してください');
      errors.count += 1;
    }

    if (this.state.pcImageWidth && this.state.pcImageWidth > 1000) {
      errors.pcImageWidth.push('1000以下で入力してください');
      errors.count += 1;
    }

    if (this.state.spImageWidth && this.state.spImageWidth > 1000) {
      errors.spImageWidth.push('1000以下で入力してください');
      errors.count += 1;
    }

    if (this.state.delay && this.state.delay > 60) {
      errors.delay.push('60以下で入力してください');
      errors.count += 1;
    }

    if (this.state.marginTop && this.state.marginTop > 100) {
      errors.marginTop.push('100以下で入力してください');
      errors.count += 1;
    }

    if (this.state.marginLeft && this.state.marginLeft > 100) {
      errors.marginLeft.push('100以下で入力してください');
      errors.count += 1;
    }

    if (this.state.marginRight && this.state.marginRight > 100) {
      errors.marginRight.push('100以下で入力してください');
      errors.count += 1;
    }

    if (this.state.marginBottom && this.state.marginBottom > 100) {
      errors.marginBottom.push('100以下で入力してください');
      errors.count += 1;
    }

    if (this.state.visibleTime && (this.state.visibleTime > 100 || this.state.visibleTime < 1)) {
      errors.visibleTime.push('100以下で入力してください');
      errors.count += 1;
    }

    if (this.state.liveSs > 7200 || this.state.liveSs < 0 || !/^-?[0-9]+$/.test(this.state.liveSs)) {
      errors.liveSs.push('0以上、7200以下で正数を入力してください');
      errors.count += 1;
    }

    return errors;
  };

  setScenario = async () => {
    this.setState({
      processing: true,
      succeeded: false,
    });
    const {
      id,
      name,
      from,
      to,
      pcImage,
      spImage,
      pcImageWidth,
      spImageWidth,
      delay,
      position,
      marginTop,
      marginLeft,
      marginRight,
      marginBottom,
      spPosition,
      spMarginTop,
      spMarginLeft,
      spMarginRight,
      spMarginBottom,
      includeUrlPatterns,
      excludeUrlPatterns,
      restrictionUrlPattern,
      visibleTime,
      inLive,
      autoPlay,
      action,
      liveSs,
      shopId,
      liveId,
      recent,
      active,
      receptionistIds,
    } = this.state;
    const data = {
      uid: firebase.auth().currentUser.uid,
      id,
      name,
      from: from.toDate(),
      to: to.toDate(),
      pcImage,
      spImage: spImage ? spImage : pcImage,
      pcImageWidth,
      spImageWidth,
      delay,
      position,
      marginTop,
      marginLeft,
      marginRight,
      marginBottom,
      spPosition,
      spMarginTop,
      spMarginLeft,
      spMarginRight,
      spMarginBottom,
      includeUrlPatterns: includeUrlPatterns.map(v => v.trim()).filter(v => !!v),
      excludeUrlPatterns: excludeUrlPatterns.map(v => v.trim()).filter(v => !!v),
      restrictionUrlPattern,
      visibleTime,
      inLive,
      autoPlay,
      action,
      liveSs: recent === 'live' ? liveSs : 0,
      shopId,
      liveId,
      recent,
      active,
      receptionistIds
    }
    if (data.id) {
      await db.collection('scenarios').doc(data.id).update({
        ...data,
        updated: firebase.firestore.FieldValue.serverTimestamp(),
      });
      await model.fetch('updateScenarioCache', 'POST', { shopId });
    } else {
      const doc = db.collection('scenarios').doc();
      await doc.set({
        ...data,
        id: doc.id,
        created: firebase.firestore.FieldValue.serverTimestamp(),
        updated: firebase.firestore.FieldValue.serverTimestamp(),
      });
      await model.fetch('updateScenarioCache', 'POST', { shopId });
      this.setState({ id: doc.id });
    }
    this.setState({
      processing: false,
      succeeded: true,
    });
  };

  deleteScenario = async () => {
    if (window.confirm('本当にこのシナリオを削除しますか？')) {
      await db.collection('scenarios').doc(this.state.id).update({
        deletedAt: firebase.firestore.FieldValue.serverTimestamp(),
        active: false,
      });
      await model.fetch('updateScenarioCache', 'POST', { shopId: this.state.shopId });
      document.location.pathname = '/scenarios/';
    }
  };

  onPcImageChange = async (files) => {
    const file = files[0];
    if (file) {
      const size = file.size / 1024
      if (size > 200) {
        alert('200KB以下の画像を選択してください。');
        return;
      }
      const filename = `image${moment().format('YYYYMMDDHHmmss')}`;
      const snapshot = await firebase.storage().ref().child(`${firebase.auth().currentUser.uid}/${filename}`).put(file);
      const image = await snapshot.ref.getDownloadURL();
      this.setState({ pcImage: image });
    }
  };

  onSpImageChange = async (files) => {
    const file = files[0];
    if (file) {
      const size = file.size / 1024
      if (size > 200) {
        alert('200KB以下の画像を選択してください。');
        return;
      }
      const filename = `image${moment().format('YYYYMMDDHHmmss')}`;
      const snapshot = await firebase.storage().ref().child(`${firebase.auth().currentUser.uid}/${filename}`).put(file);
      const image = await snapshot.ref.getDownloadURL();
      this.setState({ spImage: image });
    }
  };

  onSelectShop = async (shopIndex) => {
    if (!this.state.shops[shopIndex]) {
      return;
    }
    const shopId = this.state.shops[shopIndex].id;
    this.livesRef = db.collection('lives').where('shopId', '==', shopId).where('deletedAt', '==', null);
    const lives = (await this.livesRef.get())
      .docs
      .map(doc => doc.data())
      .filter(live => !!live.datetime)
      .sort((a, b) => b.datetime.toMillis() - a.datetime.toMillis())
    ;
    let liveIndex = lives.findIndex(v => v.id === this.state.liveId);
    if (liveIndex === -1) liveIndex = 0;
    const liveId = lives.length ? lives[liveIndex].id : null;
    this.setState({
      shopId,
      shopIndex,
      liveId,
      liveIndex,
      lives,
    });
  };

  onSelectLive = async (liveIndex) => {
    const liveId = this.state.lives[liveIndex].id;
    this.setState({
      liveId,
      liveIndex,
    });
  };

  onRestrictionUrlPattern = (e) => {
    const name = e.target.name;
    const { restrictionUrlPattern } = this.state;
    if (name === 'restrictionUrlPatternLive') {
      this.setState({ restrictionUrlPattern: restrictionUrlPattern === 'live' ? '' : 'live' });
    } else if (name === 'restrictionUrlPatternShop') {
      this.setState({ restrictionUrlPattern: restrictionUrlPattern === 'shop' ? '' : 'shop' });
    }
  }

  onChangeReceptionst = (e) => {
    const name = e.target.name;
    const { receptionists, receptionistIds } = this.state;
    let _receptionistIds = receptionistIds
    if (name === 'allReceptionists') {
      _receptionistIds = _receptionistIds.length === receptionists.length ? [] : receptionists.map(receptionist => receptionist.id);
    } else {
      const value = e.target.value;
      if (_receptionistIds.includes(value)) {
        _receptionistIds = _receptionistIds.filter(receptionistId => receptionistId !== value)
      }else{
        _receptionistIds.push(value)
      }
    }
    this.setState({ receptionistIds: _receptionistIds })
  }

  render = () => {
    const { scenariosCount } = this.state;
    const { edit } = this.props;
    if (!edit && scenariosCount >= SCENARIO_MAX){
      return <Redirect to="/scenarios" />;
    }

    const errors = this.validate();
    const liveTitle = live => `${moment(live.datetime.toMillis()).format('YYYY年MM月DD日HH:mm')} ${live.id}`;
    return (
      <div>
        <SellerNav permission={this.props.permission}/>
        <div className="content scenario">
          <h2>シナリオ管理</h2>
          <Nav bsStyle="pills" className="detail-nav nav-m-bk">
            <NavItem href="/scenarios" active={this.props.edit}>シナリオ一覧</NavItem>
            {this.props.edit && <NavItem className="detailNav_navItemChild">編集</NavItem>}
            <NavItem href="/scenarios/new" active={!this.props.edit}>新しいシナリオ</NavItem>
            <NavItem href="/scenarios/trash">削除済みシナリオ</NavItem>
          </Nav>
          {this.state.loading ? (
            <div className="card">
              <p>読み込み中...</p>
            </div>
          ) : (
            <div className="pb10">
              <div className="card">
                <form className="form form-horizontal">
                  <FormGroup controlId="visibleTime">
                    <Col sm={6} componentClass={ControlLabel}>シナリオ名</Col>
                    <Col sm={6}>
                      <FormControl
                        name="name"
                        type="text"
                        placeholder="配信予定の直近のライブ"
                        value={this.state.name}
                        onChange={(e) => this.setState({ name: e.target.value })}
                      />
                      {errors.name && errors.name.map((message, index) => (
                        <p key={index} className="error">{message}</p>
                      ))}
                    </Col>
                  </FormGroup>
                  <FormGroup controlId="from">
                    <Col sm={6} componentClass={ControlLabel}>有効期間（開始）</Col>
                    <Col sm={6}>
                      <DateTime
                        input={false}
                        value={this.state.from}
                        onChange={(e) => this.setState({ from: e })}
                      />
                      {errors.from && errors.from.map((message, index) => (
                        <p key={index} className="error">{message}</p>
                      ))}
                    </Col>
                  </FormGroup>
                  <FormGroup controlId="to">
                    <Col sm={6} componentClass={ControlLabel}>有効期間（終了）</Col>
                    <Col sm={6}>
                      <DateTime
                        input={false}
                        value={this.state.to}
                        onChange={(e) => this.setState({ to: e })}
                      />
                      {errors.to && errors.to.map((message, index) => (
                        <p key={index} className="error">{message}</p>
                      ))}
                    </Col>
                  </FormGroup>
                  <FormGroup controlId="inLive">
                    <Col sm={6} componentClass={ControlLabel}>ライブ配信中のみ表示する</Col>
                    <Col sm={6}>
                      <Checkbox
                        name="inLive"
                        checked={this.state.inLive}
                        onChange={(e) => this.setState({ inLive: e.target.checked })}
                      >
                        有効
                      </Checkbox>
                    </Col>
                  </FormGroup>
                  {this.state.shops[0] && this.state.shops[0].autoPlayPermit === 'ON' && (
                    <FormGroup controlId="autoPlay">
                      <Col sm={6} componentClass={ControlLabel}>ライブウインドウ直接表示</Col>
                      <Col sm={6}>
                        <Checkbox
                          name="autoPlay"
                          checked={this.state.autoPlay}
                          onChange={(e) => this.setState({ autoPlay: e.target.checked })}
                        >
                          有効
                        </Checkbox>
                      </Col>
                    </FormGroup>
                  )}
                  <FormGroup controlId="pcImage">
                    <Col sm={6} componentClass={ControlLabel} className="displayBlock">
                      バナー画像（PC）
                      <div className="file-suggest-size">（200KB以下）</div>
                    </Col>
                    <Col sm={6} className="hiddenOverflow">
                      <Dropzone name="pcImage"  onDrop={this.onPcImageChange} accept={{ "image/*" : [] }}>
                        {({getRootProps, getInputProps}) => (
                          <section className="dropzoneContainer">
                            <div className="dropzone" {...getRootProps()}>
                              <input {...getInputProps()} />
                              <p>ファイルアップロード</p>
                              <input type="button" value="ファイルを選択" onChange={this.onPcImageChange}></input>
                            </div>
                          </section>)}
                      </Dropzone>
                      {this.state.pcImage && (
                        <img src={this.state.pcImage} alt="pcImage" style={{ maxWidth: 300, maxHeight: 250, marginTop: 16 }} />
                      )}
                      {errors.pcImage && errors.pcImage.map((message, index) => (
                        <p key={index} className="error">{message}</p>
                      ))}
                    </Col>
                  </FormGroup>
                  <FormGroup controlId="spImage">
                    <Col sm={6} componentClass={ControlLabel} className="displayBlock">
                      バナー画像（モバイル）
                      <div className="file-suggest-size">（200KB以下）</div>
                    </Col>
                    <Col sm={6} className="hiddenOverflow">
                      <Dropzone name="spImage"  onDrop={this.onSpImageChange} accept={{ "image/*" : [] }}>
                        {({getRootProps, getInputProps}) => (
                          <section className="dropzoneContainer">
                            <div className="dropzone" {...getRootProps()}>
                              <input {...getInputProps()} />
                              <p>ファイルアップロード</p>
                              <input type="button" value="ファイルを選択" onChange={this.onSpImageChange}></input>
                            </div>
                          </section>)}
                      </Dropzone>
                      {this.state.spImage && (
                        <img src={this.state.spImage} alt="spImage" style={{ maxWidth: 300, maxHeight: 250, marginTop: 16 }} />
                      )}
                      {errors.spImage && errors.spImage.map((message, index) => (
                        <p key={index} className="error">{message}</p>
                      ))}
                    </Col>
                  </FormGroup>
                  <FormGroup controlId="pcImageWidth">
                    <Col sm={6} componentClass={ControlLabel}>バナー画像の横幅（px）（PC）</Col>
                    <Col sm={6}>
                      <FormControl
                        name="pcImageWidth"
                        type="number"
                        placeholder="300"
                        value={this.state.pcImageWidth}
                        onChange={(e) => this.setState({ pcImageWidth: this.normalizeNumber(e.target.value) })}
                      />
                      {errors.pcImageWidth && errors.pcImageWidth.map((message, index) => (
                        <p key={index} className="error">{message}</p>
                      ))}
                    </Col>
                  </FormGroup>
                  <FormGroup controlId="spImageWidth">
                    <Col sm={6} componentClass={ControlLabel}>バナー画像の横幅（px）（モバイル）</Col>
                    <Col sm={6}>
                      <FormControl
                        name="spImageWidth"
                        type="number"
                        placeholder="300"
                        value={this.state.spImageWidth}
                        onChange={(e) => this.setState({ spImageWidth: this.normalizeNumber(e.target.value) })}
                      />
                      {errors.spImageWidth && errors.spImageWidth.map((message, index) => (
                        <p key={index} className="error">{message}</p>
                      ))}
                    </Col>
                  </FormGroup>
                  <FormGroup controlId="delay">
                    <Col sm={6} componentClass={ControlLabel}>バナーの表示遅延時間（秒）</Col>
                    <Col sm={6}>
                      <FormControl
                        name="delay"
                        type="number"
                        placeholder="10"
                        value={this.state.delay}
                        onChange={(e) => this.setState({ delay: this.normalizeNumber(e.target.value) })}
                      />
                      {errors.delay && errors.delay.map((message, index) => (
                        <p key={index} className="error">{message}</p>
                      ))}
                    </Col>
                  </FormGroup>
                  <FormGroup controlId="position" value={this.state.position}>
                    <Col sm={6} componentClass={ControlLabel}>バナーの表示位置（PC）</Col>
                    <Col sm={6}>
                      <Radio
                        name="position"
                        checked={this.state.position === 'top-left'}
                        value={'top-left'}
                        onChange={(e) => this.setState({ position: e.target.value })}
                        inline>
                        左上
                      </Radio>
                      {ENABLE_MIDDLE_OPTION && <Radio
                        name="position"
                        checked={this.state.position === 'top-middle'}
                        value={'top-middle'}
                        onChange={(e) => this.setState({ position: e.target.value })}
                        inline>
                        中央上
                      </Radio>}
                      <Radio
                        name="position"
                        checked={this.state.position === 'top-right'}
                        value={'top-right'}
                        onChange={(e) => this.setState({ position: e.target.value })}
                        inline>
                        右上
                      </Radio>
                      <Radio
                        name="position"
                        checked={this.state.position === 'center'}
                        value={'center'}
                        onChange={(e) => this.setState({ position: e.target.value })}
                        inline>
                        中央
                      </Radio>
                      <br />
                      <Radio
                        name="position"
                        checked={this.state.position === 'bottom-left'}
                        value={'bottom-left'}
                        onChange={(e) => this.setState({ position: e.target.value })}
                        inline>
                        左下
                      </Radio>
                      {ENABLE_MIDDLE_OPTION && <Radio
                        name="position"
                        checked={this.state.position === 'bottom-middle'}
                        value={'bottom-middle'}
                        onChange={(e) => this.setState({ position: e.target.value })}
                        inline>
                        中央下
                      </Radio>}
                      <Radio
                        name="position"
                        checked={this.state.position === 'bottom-right'}
                        value={'bottom-right'}
                        onChange={(e) => this.setState({ position: e.target.value })}
                        inline>
                        右下
                      </Radio>
                    </Col>
                  </FormGroup>
                  {this.state.position.match(/^(top|bottom)-left$/) && (
                    <FormGroup controlId="marginLeft">
                      <Col sm={6} componentClass={ControlLabel}>左マージン（px）（PC）</Col>
                      <Col sm={6}>
                        <FormControl
                          name="marginLeft"
                          type="number"
                          placeholder="0"
                          value={this.state.marginLeft}
                          onChange={(e) => this.setState({ marginLeft: this.normalizeNumber(e.target.value) })}
                        />
                        {errors.marginLeft && errors.marginLeft.map((message, index) => (
                          <p key={index} className="error">{message}</p>
                        ))}
                      </Col>
                    </FormGroup>
                  )}
                  {this.state.position.match(/^(top|bottom)-right$/) && (
                    <FormGroup controlId="marginRight">
                      <Col sm={6} componentClass={ControlLabel}>右マージン（px）（PC）</Col>
                      <Col sm={6}>
                        <FormControl
                          name="marginRight"
                          type="number"
                          placeholder="0"
                          value={this.state.marginRight}
                          onChange={(e) => this.setState({ marginRight: this.normalizeNumber(e.target.value) })}
                        />
                        {errors.marginRight && errors.marginRight.map((message, index) => (
                          <p key={index} className="error">{message}</p>
                        ))}
                      </Col>
                    </FormGroup>
                  )}
                  {this.state.position.match(/^top-(left|middle|right)$/) && (
                    <FormGroup controlId="marginTop">
                      <Col sm={6} componentClass={ControlLabel}>上マージン（px）（PC）</Col>
                      <Col sm={6}>
                        <FormControl
                          name="marginTop"
                          type="number"
                          placeholder="0"
                          value={this.state.marginTop}
                          onChange={(e) => this.setState({ marginTop: this.normalizeNumber(e.target.value) })}
                        />
                        {errors.marginTop && errors.marginTop.map((message, index) => (
                          <p key={index} className="error">{message}</p>
                        ))}
                      </Col>
                    </FormGroup>
                  )}
                  {this.state.position.match(/^bottom-(left|middle|right|)$/) && (
                    <FormGroup controlId="marginBottom">
                      <Col sm={6} componentClass={ControlLabel}>下マージン（px）（PC）</Col>
                      <Col sm={6}>
                        <FormControl
                          name="marginBottom"
                          type="number"
                          placeholder="0"
                          value={this.state.marginBottom}
                          onChange={(e) => this.setState({ marginBottom: this.normalizeNumber(e.target.value) })}
                        />
                        {errors.marginBottom && errors.marginBottom.map((message, index) => (
                          <p key={index} className="error">{message}</p>
                        ))}
                      </Col>
                    </FormGroup>
                  )}
                  <FormGroup controlId="spPosition" value={this.state.spPosition}>
                    <Col sm={6} componentClass={ControlLabel}>バナーの表示位置（モバイル）</Col>
                    <Col sm={6}>
                      <Radio
                        name="spPosition"
                        checked={this.state.spPosition === 'top-left'}
                        value={'top-left'}
                        onChange={(e) => this.setState({ spPosition: e.target.value })}
                        inline>
                        左上
                      </Radio>
                      <Radio
                        name="spPosition"
                        checked={this.state.spPosition === 'top-middle'}
                        value={'top-middle'}
                        onChange={(e) => this.setState({ spPosition: e.target.value })}
                        inline>
                        中央上
                      </Radio>
                      <Radio
                        name="spPosition"
                        checked={this.state.spPosition === 'top-right'}
                        value={'top-right'}
                        onChange={(e) => this.setState({ spPosition: e.target.value })}
                        inline>
                        右上
                      </Radio>
                      <Radio
                        name="spPosition"
                        checked={this.state.spPosition === 'center'}
                        value={'center'}
                        onChange={(e) => this.setState({ spPosition: e.target.value })}
                        inline>
                        中央
                      </Radio>
                      <br />
                      <Radio
                        name="spPosition"
                        checked={this.state.spPosition === 'bottom-left'}
                        value={'bottom-left'}
                        onChange={(e) => this.setState({ spPosition: e.target.value })}
                        inline>
                        左下
                      </Radio>
                      <Radio
                        name="spPosition"
                        checked={this.state.spPosition === 'bottom-middle'}
                        value={'bottom-middle'}
                        onChange={(e) => this.setState({ spPosition: e.target.value })}
                        inline>
                        中央下
                      </Radio>
                      <Radio
                        name="spPosition"
                        checked={this.state.spPosition === 'bottom-right'}
                        value={'bottom-right'}
                        onChange={(e) => this.setState({ spPosition: e.target.value })}
                        inline>
                        右下
                      </Radio>
                    </Col>
                  </FormGroup>
                  {this.state.spPosition.match(/^(top|bottom)-left$/) && (
                    <FormGroup controlId="spMarginLeft">
                      <Col sm={6} componentClass={ControlLabel}>左マージン（px）（モバイル）</Col>
                      <Col sm={6}>
                        <FormControl
                          name="spMarginLeft"
                          type="number"
                          placeholder="0"
                          value={this.state.spMarginLeft}
                          onChange={(e) => this.setState({ spMarginLeft: this.normalizeNumber(e.target.value) })}
                        />
                        {errors.spMarginLeft && errors.spMarginLeft.map((message, index) => (
                          <p key={index} className="error">{message}</p>
                        ))}
                      </Col>
                    </FormGroup>
                  )}
                  {this.state.spPosition.match(/^(top|bottom)-right$/) && (
                    <FormGroup controlId="spMarginRight">
                      <Col sm={6} componentClass={ControlLabel}>右マージン（px）（モバイル）</Col>
                      <Col sm={6}>
                        <FormControl
                          name="spMarginRight"
                          type="number"
                          placeholder="0"
                          value={this.state.spMarginRight}
                          onChange={(e) => this.setState({ spMarginRight: this.normalizeNumber(e.target.value) })}
                        />
                        {errors.spMarginRight && errors.spMarginRight.map((message, index) => (
                          <p key={index} className="error">{message}</p>
                        ))}
                      </Col>
                    </FormGroup>
                  )}
                  {this.state.spPosition.match(/^top-(left|middle|right)$/) && (
                    <FormGroup controlId="spMarginTop">
                      <Col sm={6} componentClass={ControlLabel}>上マージン（px）（モバイル）</Col>
                      <Col sm={6}>
                        <FormControl
                          name="spMarginTop"
                          type="number"
                          placeholder="0"
                          value={this.state.spMarginTop}
                          onChange={(e) => this.setState({ spMarginTop: this.normalizeNumber(e.target.value) })}
                        />
                        {errors.spMarginTop && errors.spMarginTop.map((message, index) => (
                          <p key={index} className="error">{message}</p>
                        ))}
                      </Col>
                    </FormGroup>
                  )}
                  {this.state.spPosition.match(/^bottom-(left|middle|right)$/) && (
                    <FormGroup controlId="spMarginBottom">
                      <Col sm={6} componentClass={ControlLabel}>下マージン（px）（モバイル）</Col>
                      <Col sm={6}>
                        <FormControl
                          name="spMarginBottom"
                          type="number"
                          placeholder="0"
                          value={this.state.spMarginBottom}
                          onChange={(e) => this.setState({ spMarginBottom: this.normalizeNumber(e.target.value) })}
                        />
                        {errors.spMarginBottom && errors.spMarginBottom.map((message, index) => (
                          <p key={index} className="error">{message}</p>
                        ))}
                      </Col>
                    </FormGroup>
                  )}
                  <FormGroup controlId="visibleTime">
                    <Col sm={6} componentClass={ControlLabel}>１セッションあたりの表示回数</Col>
                    <Col sm={6}>
                      <FormControl
                        name="visibleTime"
                        type="number"
                        placeholder="0"
                        value={this.state.visibleTime}
                        onChange={(e) => this.setState({ visibleTime: this.normalizeNumber(e.target.value) })}
                      />
                      {errors.visibleTime && errors.visibleTime.map((message, index) => (
                        <p key={index} className="error">{message}</p>
                      ))}
                    </Col>
                  </FormGroup>
                  <FormGroup controlId="action" value={this.state.action}>
                    <Col sm={6} componentClass={ControlLabel}>このシナリオで実行するアクション</Col>
                    <Col sm={6}>
                      <Radio
                        name="action"
                        checked={this.state.action === 'live'}
                        value={'live'}
                        onChange={(e) => this.setState({ action: e.target.value })}
                        inline
                      >
                        ストリーマー
                      </Radio>
                      <br />
                      {this.state.shops[0] && this.state.shops[0].receptionPermit === 'ON' && (
                        <Radio
                          name="action"
                          checked={this.state.action === '1on1'}
                          value={'1on1'}
                          onChange={(e) => this.setState({ action: e.target.value })}
                          inline
                        >
                          レセプショニスト
                        </Radio>
                      )}
                    </Col>
                  </FormGroup>
                  <FormGroup controlId="shop">
                    <Col sm={6} componentClass={ControlLabel}>このシナリオで使用するショップ</Col>
                    <Col sm={6}>
                      {this.state.shops && this.state.shops.length > 0 && (
                        <DropdownButton title={this.state.shops[this.state.shopIndex].name} id="shop">
                          {this.state.shops.map((shop, i) => (
                            <MenuItem
                              key={i}
                              eventKey={i}
                              active={i === this.state.shopIndex}
                              onSelect={this.onSelectShop}
                            >
                              {shop.name}
                            </MenuItem>
                          ))}
                        </DropdownButton>
                      )}
                    </Col>
                  </FormGroup>
                  {Config.SCENARIO_RECEPTIONIST && this.state.action === '1on1' && (
                    <FormGroup controlId="receptionst">
                      <Col sm={6} componentClass={ControlLabel}>このシナリオで接客するレセプショニスト</Col>
                      <Col sm={6}>
                          <Checkbox
                            name="allReceptionists"
                            checked={this.state.receptionists.length === this.state.receptionistIds.length}
                            onChange={(e) => this.onChangeReceptionst(e)}
                          >
                            全てを選択
                          </Checkbox>
                        {this.state.receptionists.map(receptionist => (
                          <Checkbox
                            key={receptionist.id}
                            name="receptionist"
                            checked={this.state.receptionistIds.includes(receptionist.id)}
                            value={receptionist.id}
                            onChange={(e) => this.onChangeReceptionst(e)}
                          >
                            {receptionist.email}
                          </Checkbox>
                        ))}
                        
                      </Col>
                    </FormGroup>
                  )}
                  {this.state.action === 'live' && (
                    <FormGroup controlId="recent" value={this.state.recent}>
                      <Col sm={6} componentClass={ControlLabel}>このシナリオで使用するライブ</Col>
                      <Col sm={6}>
                        <Radio
                          name="recent"
                          checked={this.state.recent === 'next'}
                          value={'next'}
                          onChange={(e) => this.setState({ recent: e.target.value })}
                          inline
                        >
                          配信予定の直近のライブ
                        </Radio>
                        <br />
                        <Radio
                          name="recent"
                          checked={this.state.recent === 'prev'}
                          value={'prev'}
                          onChange={(e) => this.setState({ recent: e.target.value })}
                          inline
                        >
                          配信済みの直近のライブ
                        </Radio>
                        <br />
                        <Radio
                          name="recent"
                          checked={this.state.recent === 'live'}
                          value={'live'}
                          onChange={(e) => this.setState({ recent: e.target.value })}
                          inline
                        >
                          任意のライブ
                        </Radio>
                        <br />
                        {this.state.recent === 'live' && this.state.lives && this.state.lives.length > 0 && (
                          <div>
                            <DropdownButton title={liveTitle(this.state.lives[this.state.liveIndex])} id="live">
                              {this.state.lives.map((live, i) => (
                                <MenuItem
                                  key={i}
                                  eventKey={i}
                                  active={i === this.state.liveIndex}
                                  onSelect={this.onSelectLive}
                                >
                                  <div className="pt3">{liveTitle(live)}</div>
                                  <div className="pb3"><strong>{live.name}</strong></div>
                                </MenuItem>
                              ))}
                            </DropdownButton>
                            <div>
                              <strong>{this.state.lives[this.state.liveIndex].name}</strong>
                            </div>
                            <div>
                              <img src={this.state.lives[this.state.liveIndex].image} alt="poster" style={{
                                width: 225,
                                height: 400,
                                objectFit: 'cover',
                              }} />
                            </div>
                          </div>
                        )}
                      </Col>
                    </FormGroup>
                  )}
                  <FormGroup controlId="includeUrlPatterns">
                    <Col sm={6} componentClass={ControlLabel}>
                      このシナリオを表示する URL
                      <Help />
                    </Col>
                    <Col sm={6}>
                      <div>URL パターン</div>
                      <FormControl
                        name="includeUrlPatterns"
                        componentClass="textarea"
                        rows="3"
                        placeholder="https://tagfab.jp/"
                        value={this.state.includeUrlPatterns ? this.state.includeUrlPatterns.join('\n') : null}
                        onChange={(e) => this.setState({ includeUrlPatterns: e.target.value.split('\n') })}
                      />
                      {errors.includeUrlPatterns && errors.includeUrlPatterns.map((message, index) => (
                        <p key={index} className="error">{message}</p>
                      ))}
                      {this.state.action === 'live' && (
                        <React.Fragment>
                          <div className="pt20">登録された商品に自動表示</div>
                          <Checkbox
                            name="restrictionUrlPatternLive"
                            value="live"
                            checked={this.state.restrictionUrlPattern === 'live'}
                            disabled={this.state.recent !== 'live'}
                            onChange={this.onRestrictionUrlPattern}
                          >
                            ライブに登録された商品
                          </Checkbox>

                          <Checkbox
                            name="restrictionUrlPatternShop"
                            value="shop"
                            checked={this.state.restrictionUrlPattern === 'shop'}
                            onChange={this.onRestrictionUrlPattern}
                          >
                            ショップに登録された商品
                          </Checkbox>
                        </React.Fragment>
                      )}
                    </Col>
                  </FormGroup>
                  <FormGroup controlId="excludeUrlPatterns">
                    <Col sm={6} componentClass={ControlLabel}>
                      このシナリオを表示しない URL
                      <Help />
                    </Col>
                    <Col sm={6}>
                      URL パターン
                      <FormControl
                        name="excludeUrlPatterns"
                        componentClass="textarea"
                        rows="3"
                        placeholder="https://tagfab.jp/"
                        value={this.state.excludeUrlPatterns ? this.state.excludeUrlPatterns.join('\n') : null}
                        onChange={(e) => this.setState({ excludeUrlPatterns: e.target.value.split('\n') })}
                      />
                      {errors.excludeUrlPatterns && errors.excludeUrlPatterns.map((message, index) => (
                        <p key={index} className="error">{message}</p>
                      ))}
                    </Col>
                  </FormGroup>
                  { this.state.recent === 'live' && 
                    <FormGroup controlId="liveSs">
                      <Col sm={6} componentClass={ControlLabel}>動画開始位置</Col>
                      <Col sm={6}>
                        <FormControl
                          name="liveSs"
                          type="number"
                          placeholder="0"
                          value={this.state.liveSs}
                          onChange={(e) => this.setState({ liveSs: this.normalizeNumber(e.target.value) })}
                        />
                        {errors.liveSs && errors.liveSs.map((message, index) => (
                          <p key={index} className="error">{message}</p>
                        ))}
                      </Col>
                    </FormGroup>
                  }
                  <FormGroup controlId="active">
                    <Col sm={6} componentClass={ControlLabel}>このシナリオの状態</Col>
                    <Col sm={6}>
                      <Checkbox
                        name="active"
                        checked={this.state.active}
                        onChange={(e) => this.setState({ active: e.target.checked })}
                      >
                        有効
                      </Checkbox>
                    </Col>
                  </FormGroup>
                </form>
              </div>
              <div className="fixFooter">
                <div className="fixFooterInner">
                  <div className="fixFooterBtnArea">
                    {!!errors.count &&
                      <span className="error mr10">{errors.count}件のエラーがあります</span>
                    }
                    {!errors.count && this.state.succeeded &&
                      <span className="success mr10">
                        <img className="mr10" src={doneIcon} alt="done_icon"/>
                        {this.props.edit ? 'シナリオが更新されました' : 'シナリオが登録されました'}
                      </span>
                    }
                    {this.props.edit && (
                      <Button className="strongDeleteButton" onClick={() => this.deleteScenario()}>
                        削除する
                      </Button>)}
                    <Button className="marginRightL strongBtn" disabled={!!errors.count || this.state.processing} onClick={() => this.setScenario()}>
                      {this.props.edit ? '更新する' : '登録する'}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}
