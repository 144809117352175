import React, { Component } from 'react';
import Dropzone from 'react-dropzone';
import {
  Col,
  ControlLabel,
  FormGroup,
  FormControl,
  Radio,
} from 'react-bootstrap';

import './App.css';
import StreamerNav from './StreamerNav';
import firebase from './firebase';
import model from './Model';
import Utilities from './Utilities';

export default class Streamer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      streamer: {
        name: '',
        info: '',
        height: '',
        /** 配信のコメントする際で閲覧者が名前で使えない言葉 */
        ngNameWords: '',
        image: null,
        autoPlay: 'ON',
      },
      errors: {
        name: '',
      },
      warnings: {
        height: '',
      },
      error: '',
      message: '',
      edit: false,
    };
  }

  componentDidMount() {
    model.collection('streamers').doc(firebase.auth().currentUser.uid).get().then((doc) => {
      if (doc.exists) {
        const streamer = doc.data();
        if (!streamer['autoPlay']) streamer['autoPlay'] = "ON";
        this.setState({
          streamer: streamer,
          edit: true,
        });
      }
    });
  }

  onChange = (e) => {
    const streamer = this.state.streamer;
    streamer[e.target.name] = e.target.value;
    this.setState({ streamer });
    if (e.target.name === 'height') this.setState({ warnings: { height: Utilities.validateNumber(e.target.value, 3, 2) }});
  };

  onFileChange = (files) => {
    const file = files[0];
    if (file) {
      if (!file.type.match(/^image\//)) {
        alert('画像ファイルをアップロードしてください。');
        return;
      }
      // TODO: separate public bucket and private TSV bucket.
      firebase.storage().ref().child(`${firebase.auth().currentUser.uid}/streamer`).put(file)
      .then((snapshot) => snapshot.ref.getDownloadURL().then(
        (url) => {
          const streamer = this.state.streamer;
          streamer['image'] = url;
          this.setState({ streamer });
        })
      );
    }
  };

  validate = () => {
    const streamer = this.state.streamer;
    const errors = this.state.errors;
    let validate = true;

    if (!streamer.name) {
      errors['name'] = 'ストリーマー名を入力してください';
      validate = false;
    } else {
      errors['name'] = '';
    }
    this.setState({ errors });
    return validate;
  }

  updateStreamer = (e) => {
    e.preventDefault();
    const streamer = this.state.streamer;
    if (streamer.ngNameWords) {
      streamer.ngNameWords = streamer.ngNameWords.split(',').map(e => e.replace(/\s/g, '')).filter(e => e).join(',');
    }
    if (this.validate()) {
      firebase.firestore().collection('streamers').doc(firebase.auth().currentUser.uid).set(streamer);
      this.setState({ message: this.state.edit ? '更新しました' : '登録しました', error: '' });
    } else {
      this.setState({ message: '', error: '必須項目を全て入力してください' });
    }
  };

  render() {
    const s = this.state.streamer;
    return (
      <div>
        <StreamerNav permission={this.props.permission}/>
        <div className="content streamer">
          <h2>ストリーマー情報</h2>
          <div className="card">
            <form className="form form-horizontal">
              <FormGroup controlId="id">
                <Col componentClass={ControlLabel} sm={4}>ID</Col>
                <Col sm={8}>{firebase.auth().currentUser.uid}</Col>
              </FormGroup>

              <FormGroup controlId="name">
                <Col componentClass={ControlLabel} sm={4}>ストリーマー名<div className="tagmust">
                  必須</div></Col>
                <Col sm={8}>
                  <FormControl name="name" type="text" placeholder="ストリーマー名" value={s.name} maxLength={20}
                    onChange={this.onChange} />
                  <p className="error">{this.state.errors.name}</p>
                </Col>
              </FormGroup>

              <FormGroup controlId="info">
                <Col componentClass={ControlLabel} sm={4}>自己紹介</Col>
                <Col sm={8}>
                  <FormControl name="info" componentClass="textarea" placeholder="" value={s.info} maxLength={1000}
                    rows={4} onChange={this.onChange} />
                    <div className="formcaption">
                      <p>TAGfab（アプリ）ストリーマーページに掲載される自己紹介です<br />
                        <br />
                        掲載場所：<br />
                        ・TAGfab（アプリ）「ストリーマーページ」</p>
                    </div>
                </Col>
              </FormGroup>

              <FormGroup controlId="height">
                <Col componentClass={ControlLabel} sm={4}>身長 (cm)
                </Col>
                <Col sm={8}>
                  <FormControl name="height" type="number" placeholder="160" value={s.height}
                    onChange={this.onChange} />
                      <p className="error">{this.state.warnings.height}</p>
                      <div className="formcaption">
                        <p>こちらは登録任意です。</p>
                      </div>
                </Col>
              </FormGroup>

              <FormGroup controlId="image">
                <Col componentClass={ControlLabel} sm={4}>プロフィール写真 (200x200)</Col>
                <Col sm={8}>
                  <Dropzone onDrop={this.onFileChange} accept={{ 'image/*' : [] }}>
                    {({getRootProps, getInputProps}) => (
                      <section className="dropzoneContainer">
                        <div className="dropzone" {...getRootProps()}>
                          <input {...getInputProps()} />
                          <p>ファイルアップロード</p>
                          <input type="button" value="ファイルを選択" onChange={this.onFileChange}></input>
                        </div>
                      </section>)}
                  </Dropzone>
                  {s.image && <img src={s.image} alt="streamer" className="profileImage" />}
                  <div className="formcaption">
                    <p>ライブ配信画面とTAGfab（アプリ）に表示されるプロフィール画像です。200×200 pxでご登録ください。<br />
                      <br />
                      掲載場所：<br />
                        ・TAGfab（アプリ）「ストリーマー紹介ページ」<br />
                        ・TAGsAPI 「ライブ閲覧ページ」</p>
                  </div>
                </Col>
              </FormGroup>
              <FormGroup controlId="ngNameWords">
                <Col componentClass={ControlLabel} sm={4}>ニックネームで禁止する文字列</Col>
                <Col sm={8}>
                  <FormControl name="ngNameWords" type="text" placeholder="カンマ区切りスペースなし" value={s.ngNameWords} maxLength={20}
                    onChange={this.onChange} />
                  <p className="error">{this.state.errors.ngNameWords}</p>
                  <div className="formcaption">
                    <p>カンマ区切りスペースなしでお願いします。（最大20文字）</p>
                  </div>
                </Col>
              </FormGroup>

              <FormGroup controlId="autoPlay">
                <Col componentClass={ControlLabel} sm={4}>
                  自動再生<div className="tagmust">必須</div>
                </Col>
                <Col sm={8}>
                  <Radio inline name="autoPlay" value="ON" checked={(s['autoPlay'] === "ON")} onChange={this.onChange}> ON </Radio>
                  <Radio inline name="autoPlay" value="OFF" checked={(s['autoPlay'] === "OFF")} onChange={this.onChange}> OFF </Radio>
                </Col>
              </FormGroup>

              <FormGroup>
                <div className="fixFooter">
                  <div className="fixFooterInner">
                    <div className="fixFooterBtnArea">
                      <p className="error mr10">{this.state.error}</p>
                      <p className="green mr10">{this.state.message}</p>
                      <button type="submit" className="strongBtn btn" onClick={this.updateStreamer}>{ this.state.edit ? '更新する' : '登録する' }</button>
                    </div>
                  </div>
                </div>
              </FormGroup>

            </form>
          </div>
        </div>
      </div>
    );
  }
}
