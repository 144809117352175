import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { Button, Col, ControlLabel, FormGroup, FormControl } from 'react-bootstrap';
import './App.css';
import firebase from './firebase';
import moment from 'moment';
import model from './Model';
import Cookies from 'js-cookie';


const db = firebase.firestore();

export default class Live extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      passwordConfirm: '',
      user: null,
      error: '',
      adminAuth: false,
      basicPassword: '',
      authError: '',
    };
    
  }

  componentDidMount() {
    const passwordCookie = Cookies.get('basic-auth');
    if (passwordCookie) {
      this.setState({ password: passwordCookie }, () => this.auth()); //パスワードをCookieで保持し再認証
    }
    this.unsub = firebase.auth().onAuthStateChanged((user) => this.setState({ user }));
  }

  componentWillUnmount() {
    this.unsub();
  }

  makeTestLive = (shopId, uid) => {
    db.collection('lives').where('uid', '==', uid).where('isTest', '==', true).get().then((data) => {
      if (data.empty) {
        const liveDoc = db.collection('lives').doc();
        liveDoc.set({
          name: 'テスト',
          topImage: 'https://firebasestorage.googleapis.com/v0/b/tagfab/o/images%2Ftop_test.png?alt=media',
          image: 'https://firebasestorage.googleapis.com/v0/b/tagfab/o/images%2Fpreview_test.png?alt=media',
          linkName: '',
          linkUrl: '',
          datetime: moment().toDate(),
          uid: firebase.auth().currentUser.uid,
          id: liveDoc.id,
          shopId: shopId,
          archiveComment: true,
          enabled: false,
          isTest: true,
          items: [],
          deletedAt: null,
        })
      }
    });
  }

  makeFtpAccount = (uid) => {
    const ref = db.collection('ftp_accounts').doc(uid);
    ref.get().then((doc) => {
      if (doc.exists) return;
      ref.set({
        username: uid,
        password: this.createPassword(),
        enabled: false,
        noQuotes: false,
      });
    });
  }

  createPassword = () => {
    const passwordBase = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    const passwordLength = 8; // 8桁
    let password = '';
    for (let i = 0; i < passwordLength; i++) {
      password += passwordBase.charAt(Math.floor(Math.random() * passwordBase.length));
    }
    return password;
  }

  onChange = (e) => this.setState({ [e.target.name]: e.target.value });

  register = (event) => {
    event.preventDefault();
    if (this.state.password !== this.state.passwordConfirm) {
      this.setState({ error: '入力したパスワード確認は同じパスワードではありません' });
      return;
    }
    firebase.auth().createUserWithEmailAndPassword(this.state.email, this.state.password)
      .then(() => firebase.auth().signInWithEmailAndPassword(this.state.email, this.state.password))
      .then((userCred) => {
        const user = userCred.user;
        const shopId = this.props.match.params.shopId;
        if (shopId) {
          this.makeTestLive(shopId, user.uid);
          return db.collection('shops').doc(shopId).get().then((shopDoc) =>
            db.collection('streamers').doc(user.uid).collection('shops')
              .doc(shopId).set({ name: shopDoc.data().name }));
        } else {
          const shop = db.collection('shops').doc();
          this.makeTestLive(shop.id, user.uid);
          this.makeFtpAccount(user.uid);
          shop.set({
            id: shop.id,
            uid: user.uid,
            admin: user.uid,
            adminEmail: user.email,
          });
        }
      }).then(() => firebase.auth().currentUser.sendEmailVerification())
      .catch((e) => this.setState({ error: e.message }));
  };

  // Basic認証--------------------------------------
  auth = () => {
    model.fetch('basicAuth', 'POST', {
      password: this.state.basicPassword
    }).then(() => {
      this.setState({ adminAuth: true });
      Cookies.set('basic-auth', this.state.basicPassword);
    }).catch(() => {
      this.setState({ authError: '認証に失敗しました。' });
      return;
    });
  }
  // Basic認証ここまで--------------------------------
  

  render() {
    const passwordCookie = Cookies.get('basic-auth');
    // Basic認証--------------------------------------
    if (!passwordCookie && !this.state.adminAuth) {
      return ( //ログイン画面
        <div className="loginContainer">
          <div className="AppTitleContainer">
            <h1 className="App-title">
              管理者パスワード
            </h1>
          </div>
          <div className="cardMini">
            <div className="cardMiniContainer">
              <form>
                <div className="formUplabel login_passwordArea">
                  <label htmlFor="formHorizontalPassword" className="control-label label-up">パスワード</label>
                  <input type="password" name="password" placeholder="パスワード" id="formHorizontalPassword" className="form-control"
                    onChange={(e) => this.setState({ basicPassword: e.target.value })} />
                </div>
                <button type="button" onClick={this.auth} className="strongBtn largeBtn">送信</button>
                <p className="error mt10">{this.state.authError}</p>
              </form>
            </div>
          </div>
        </div>
      )
    }
    // Basic認証ここまで--------------------------------------

    const match = this.props.location.pathname.match(/^\/(receptionist|streamer)/);
    const root = match ? `/${match[1]}` : '/';
    if (this.state.user) {
      return <Redirect to={root} />;
    }
    return (
      <div className="content register">
        <div className="card">
        <form className="form form-horizontal" onSubmit={this.register}>
          <FormGroup controlId="formHorizontalEmail">
            <Col componentClass={ControlLabel} sm={4}>メールアドレス</Col>
            <Col sm={8}>
              <FormControl name="email" type="email" placeholder="example@example.com"
                onChange={this.onChange} />
            </Col>
          </FormGroup>

          <FormGroup controlId="formHorizontalPassword">
            <Col componentClass={ControlLabel} sm={4}>パスワード</Col>
            <Col sm={8}>
              <FormControl name="password" type="password" placeholder="パスワード"
                onChange={this.onChange} />
            </Col>
          </FormGroup>

          <FormGroup controlId="passwordConfirm">
            <Col componentClass={ControlLabel} sm={4}>パスワード確認</Col>
            <Col sm={8}>
              <FormControl name="passwordConfirm" type="password" placeholder="パスワード"
                onChange={this.onChange} />
            </Col>
          </FormGroup>

          <FormGroup>
            <Col smOffset={4} sm={8}>
              <Button type="submit" bsStyle="warning">登録する</Button>
            </Col>
          </FormGroup>

          <FormGroup>
            <Col smOffset={4} sm={8}>
              <p className="error">{this.state.error}</p>
            </Col>
          </FormGroup>
        </form>
        </div>
      </div>
    );
  }
}
