import React, { Component } from 'react';
import {
  Col,
  Nav,
  NavItem,
  Row
} from 'react-bootstrap';

import './App.css';
import SellerNav from './SellerNav';
import firebase from './firebase';
import model from './Model';
import { SCENARIO_MAX } from './Scenarios';

const db = firebase.firestore();


export default class ScenarioTrash extends Component {
  constructor(props) {
    super(props);
    this.state = {
      scenarios: [],
      unDeletedScenarios: [],
    };
  }

  componentDidMount = async () => {
    const uid = firebase.auth().currentUser.uid;
    model.fetch(`api/scenarios/${uid}`)
      .then(async (res) => {
        const scenarios_ = await res.json();
        const scenarios = scenarios_.filter(scenario => !scenario.deletedAt === false);
        const unDeletedScenarios = scenarios_.filter(scenario => !scenario.deletedAt)
        this.setState({ scenarios, unDeletedScenarios });
      }).catch(() => {});
  };

  restore = (e, scenarioId) => {
    const unDeletedScenarios = this.state.unDeletedScenarios
    if (unDeletedScenarios.length > SCENARIO_MAX) {
      alert('シナリオの登録上限は40シナリオです。既存のシナリオを削除してからシナリオの復元を行って下さい')
      return
    }
    if (window.confirm('本当にシナリオIDを復元しますか？')) {
      db.collection('scenarios').doc(scenarioId).update({ deletedAt: null });
      unDeletedScenarios.push(this.state.scenarios.find(scenario => scenario.id === scenarioId))
      const scenarios = this.state.scenarios.filter(scenario => scenario.id !== scenarioId)
      this.setState({ scenarios, unDeletedScenarios });
    }
  }

  newScenarioClick = (event) => {
    if (this.state.unDeletedScenarios.length >= SCENARIO_MAX) {
      event.preventDefault()
      alert('シナリオの登録上限は40シナリオです。既存のシナリオを削除してから新シナリオの作成を行って下さい')
    }
  }

  render = () => (
    <div>
      <SellerNav permission={this.props.permission}/>
      <div className="content">
        <h2>シナリオ管理</h2>
        <Nav bsStyle="pills" className="detail-nav nav-m-bk">
          <NavItem href="/scenarios">
            <strong>シナリオ一覧</strong></NavItem>
          <NavItem href="/scenarios/new" onClick={this.newScenarioClick}>
            新しいシナリオ
          </NavItem>
          <NavItem href="/scenarios/trash" active>削除済みシナリオ</NavItem>
        </Nav>
        <div className="card">
        <Row className='scenario-item dtTablelist scenarios_listRow'>
            <Col xs={12} sm={4} className="scenario-item-col scenarios_listItem scenario-head">
              <div>シナリオID｜シナリオ名｜期間</div>
            </Col>
            <Col xs={12} sm={3} className="scenario-head">ショップID｜ショップ名</Col>
            <Col xs={12} sm={3} className="scenario-head">実行するアクション</Col>
          </Row>
          {this.state.scenarios.map((s) => (
            <Row key={s.id} className="scenarios_listRow">
              <Col xs={12} sm={4} className="scenarios_listItem">
                <div className="mb5">
                  {s.id}
                </div>
                <div className="mb5">
                  {s.name || 'シナリオ名を登録してください'}
                </div>
                <div className="mb5">
                  {s.term}
                </div>
              </Col>
              <Col xs={12} sm={3} className="scenarios_listItem">
                <div className="mb5">
                  {s.shopId}
                </div>
                <div>
                  {s.shopName}
                </div>
              </Col>
              <Col xs={12} sm={3} className="scenarios_listItem">
                {s.action === 'live' && (
                  <React.Fragment>
                    <div className="mb5">
                      {s.liveType}
                    </div>
                    <div>
                      {s.liveName}
                    </div>
                  </React.Fragment>
                )}
                {s.action === '1on1' && (
                  <React.Fragment>
                    <div className="mb5">
                      レセプション
                    </div>
                  </React.Fragment>
                )}
              </Col>
              <Col xs={12} sm={2}>
                <a className="normalBtn-strongColor inlineBtn" onClick={(e) => this.restore(e, s.id)} href="#/">復元する</a>
              </Col>
            </Row>
          ))}
        </div>
      </div>
    </div>
  );
}
