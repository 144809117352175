import React, { Component } from 'react';

import './App.css';
import SellerNav from './SellerNav';

export default class Home extends Component {
  render = () => (
    <div>
      <SellerNav permission={this.props.permission}/>
      <div className="content home">
        <h2>お知らせ</h2>
        <div className="card">
          <h3>ようこそ、TAGsAPI for Sellerへ</h3>
          <p>TAGsAPI for Sellerへのご登録まことにありがとうございます。</p>
        </div>
      </div>
    </div>
  );
}