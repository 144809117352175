import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faVolumeMute,
  faVolumeUp,
} from '@fortawesome/free-solid-svg-icons';

import Hls from 'hls.js';
import moment from 'moment';

import './App.css';
import firebase from './firebase';
import CtaItemLayer from './CtaItemLayer';
import TelopLayer from './TelopLayer';

const ENABLE_TELOP_AND_CTA = true;

const db = firebase.firestore();

export default class Monitor extends Component {
  constructor(props) {
    super(props);
    this.telopRef = React.createRef();
    this.ctaRef = React.createRef();
    this.videoRef = React.createRef();
    this.state = {
      live: null,
      items: [],
      session: null,
      error: null,
      muted: true,
      buyButtonUrl: '',
      streamStartTime: null,
      monitorStartTime: null,
      broadcastStartTime: null,
      redirect: false,
    };
  }

  componentDidMount() {
    const liveId = this.props.match.params.id;
    this.offLive = db.collection('lives').doc(liveId).onSnapshot(async (snapshot) => {
      if (!snapshot.exists) {
        return;
      }
      const live = snapshot.data();
      this.setState({ live });
      const itemIds = live.items.map((item) => item.id);
      const items = await this.getItemsByIds(itemIds);
      this.setState({ items });
    });

    this.offSessions = db
    .collection('sessions')
    .where('liveId', '==', liveId)
    .orderBy('created', 'desc')
    .limit(1)
    .onSnapshot((snapshot) => {
      if (snapshot.empty) {
        return;
      }
      const session = snapshot.docs[0].data();
      if (session.uid !== firebase.auth().currentUser.uid) {
        this.setState({ redirect: true });
        return;
      }
      if (session.pendingHls && (!this.state.session || !this.state.session.pendingHls)) {
        this.setState({
          session,
          error: null,
          monitorStartTime: new Date(),
        });
        this.play();
        return;
      }
      if (session.hls && (!this.state.session || !this.state.session.hls)) {
        this.setState({
          session,
          error: null,
          broadcastStartTime: new Date(),
        });
        this.play();
        return;
      }
      if (!this.state.streamStartTime) {
        this.setState({
          session,
          streamStartTime: new Date(),
        });
        return;
      }
      this.setState({
        session,
      });
    });
  }

  componentWillUnmount() {
    if (this.offSessions) {
      this.offSessions();
    }
    if (this.offLive) {
      this.offLive();
    }
  }

  async getItemsByIds(ids) {
    const items = [];
    for (let id of ids) {
      const doc = await db.collection("items").doc(id).get();
      if (!doc.exists) continue;
      items.push(Object.assign({ id }, doc.data()));
    }
    return items;
  }

  play = () => {
    if (!Hls.isSupported()) {
      return;
    }
    if (this.hls) {
      this.hls.destroy();
    }
    const video = document.getElementById('video');
    this.hls = new Hls();
    this.hls.attachMedia(video);
    this.hls.on(Hls.Events.MEDIA_ATTACHED, () => {
      this.hls.loadSource(this.state.session.pendingHls);
      this.hls.on(Hls.Events.MANIFEST_PARSED, () => {
        video.play();
      });
      this.hls.on(Hls.Events.ERROR, (event, data) => {
        console.log(Hls.Events.ERROR, {
          event,
          data,
        });
        this.setState({
          error: {
            event,
            data,
          },
        });
      });
    });
  }

  onTimeUpdate = () => {
    if (!ENABLE_TELOP_AND_CTA) {
      return;
    }
    const { session, live } = this.state;
    const started = session.created.toMillis();
    const latency = (session.availableAt - started) * 0.75;
    const currentTime = new Date().getTime() - latency;
    if (this.telopRef.current) {
      this.telopRef.current.onTimeUpdate(currentTime);
    }
    if (this.ctaRef.current) {
      this.ctaRef.current.onTimeUpdate(currentTime);
    }
    if (this.ctaRef.current && this.ctaRef.current.state.ctaEvents.length > 0) {
      const item = this.ctaRef.current.state.ctaEvents[this.ctaRef.current.state.ctaEvents.length - 1].item;
      this.setState({ buyButtonItemId: item.id, buyButtonUrl: item.url });
    } else if (live.items.length > 0 ) {
      this.setState({ buyButtonItemId: live.items[0].id, buyButtonUrl: live.items[0].url });
    }
  }

  render() {
    if (this.state.redirect) {
      return <Redirect to="/streamer/previews" />;
    }
    const {
      live,
      session,
      muted,
      buyButtonUrl,
      buyText,
    } = this.state;

    const scale = 1;
    const styles = {
      contentWrapper: {
        margin: '60px auto 0',
        borderRadius: 10,
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.1)',
      },
      info: {
        backgroundColor: '#fff',
        borderColor: '#ccc',
        borderWidth: 1,
        borderRadius: 3,
        padding: 10,
        width: '100%',
      },
      liveItemsWrapper: {
        display: 'flex',
      },
      item: {
        padding: 10,
        position: 'relative'
      },
      buyButton: {
        position: 'absolute',
        top: 20,
        right: 20,
        backgroundColor: '#F1B006',
        color: 'rgb(255, 255, 255)',
        fontSize: 14,
        padding: 6,
        borderRadius: 4,
        boxSizing: 'border-box',
        maxWidth: 'calc(100% - 20px)',
        textDecoration: 'none',
      },
      buyText: {
        paddingLeft: 10,
        paddingRight: 10,
        fontFamily: 'Arial, Helvetica, sans-serif',
        fontSize: 14,
        fontWeight: 'bold',
      },
      clear: {
        clear: 'both',
      },
      live: {
        padding: 20,
      },
      normalVideo: {
        width: 405 * scale,
        height: 720 * scale,
        marginBottom: 42 * scale,
        objectFit: 'cover',
      },
      rotateVideo: {
        width: 720 * scale,
        height: 405 * scale,
        marginTop: 158 * scale,
        marginLeft: -158 * scale,
        marginRight: -158 * scale,
        marginBottom: 200 * scale,
        transform: 'rotate(90deg)',
      },
      volumeIcon: {
        width: 20,
      },
    };

    const onClickVolumeIcon = (event) => {
      event.preventDefault();
      this.setState({
        muted: !muted,
      })
    }

    return (
      <div className="content" style={styles.contentWrapper}>
        <h2>ライブモニター</h2>
        {!!live && (
          <div style={styles.info}>
            <div style={styles.item}>
              <span className="titleLabel">ライブID</span>
              <span className="titleContent displayBlock">{live.id}</span>
            </div>
            <div style={styles.item}>
              <span className="titleLabel">タイトル</span>
              <span className="titleContent displayBlock">{live.name}</span>
            </div>
            <div style={styles.clear}>
            </div>
          </div>
        )}
        {Hls.isSupported() ? (
          <div style={styles.live}>
            {session && session.live && session.pendingHls ? (
              <div style={styles.liveItemsWrapper}>
                <div style={styles.item}>
                  <video
                    ref={this.videoRef}
                    id="video"
                    src={session.pendingHls}
                    style={session.rotated ? styles.rotateVideo : styles.normalVideo}
                    muted={muted}
                    onTimeUpdate={this.onTimeUpdate}
                  >
                    このブラウザは video タグがサポートされてません。
                  </video>


                  {ENABLE_TELOP_AND_CTA && (live.showTelop || live.showFullTelop) && !live.buyButton && (
                    <TelopLayer
                      live={live}
                      ref={this.telopRef}
                      session={session}
                      height="100%"
                      width="100%"
                      showFullFlag={live.showFullTelop}
                    />
                  )}

                  {ENABLE_TELOP_AND_CTA && live.ctaButton &&  (
                    <CtaItemLayer
                      collection="lives"
                      items={this.state.items}
                      id={live.id}
                      ref={this.ctaRef}
                      width={styles.normalVideo.width}
                      session={session}
                    />
                  )}

                  {ENABLE_TELOP_AND_CTA && live.buyButton && buyButtonUrl && (
                    <a style={styles.buyButton} href={buyButtonUrl} target="_blank" rel="noopener noreferrer">
                      <span style={buyText}>今すぐ購入</span>
                    </a>
                  )}

                </div>
                <div style={styles.item}>
                  <span className="titleLabel">
                    準備開始時刻
                  </span>
                  <span className="titleContent displayBlock mb10">
                    {!!session.created ? (
                      <span className="success">{moment(session.created.toDate()).format('yyyy-MM-DD HH:mm:ss')}</span>
                    ) : (
                      <span className="error">このライブはまだ配信準備されてません。</span>
                    )}
                  </span>

                  <span className="titleLabel">
                    ライブ画面の左上に商品を表示する
                  </span>
                  <span className="titleContent displayBlock mb10">
                    {!!live.ctaButton ? (
                      <span className="success">ライブ画面の左上に商品を表示されています。</span>
                    ) : (
                      <span className="error">ライブ画面の左上に商品を表示されていません。</span>
                    )}
                  </span>

                  <span className="titleLabel">
                    テロップ表示設定
                  </span>
                  <span className="titleContent displayBlock mb10">
                    {live.showTelop && (
                      <span className="success">右上表示</span>
                    )}
                    {live.showFullTelop && (
                      <span className="success">全画面表示</span>
                    )}
                    {live.buyButton && (
                      <span className="success">今すぐ購入</span>
                    )}
                    {!live.showTelop && !live.showFullTelop && !live.buyButton && (
                      <span className="success">非表示</span>
                    )}
                  </span>

                  <span className="titleLabel">
                    配信開始時刻
                  </span>
                  <span className="titleContent displayBlock mb10">
                    {!!session.stabilized ? (
                      <span className="success">{moment(session.stabilized.toDate()).format('yyyy-MM-DD HH:mm:ss')}</span>
                    ) : (
                      <span className="error">このライブはまだ配信されてません。</span>
                    )}
                  </span>
                  <span className="titleLabel">
                    遅延秒数
                  </span>
                  <span className="titleContent displayBlock mb10">
                    {!!session.stabilized ? (
                      <span className="success">{session.stabilizationTime.toLocaleString()}</span>
                    ) : (
                      <span className="error">このライブはまだ配信されてません。</span>
                    )}
                  </span>
                  <a href="#/" onClick={onClickVolumeIcon}>
                    <FontAwesomeIcon
                      icon={muted ? faVolumeMute : faVolumeUp}
                      style={styles.volumeIcon}
                    />
                  </a>
                  {muted ? (
                    <span className="warning ml10">現在、ボリュームは OFF です</span>
                  ) : (
                    <span className="warning ml10">現在、ボリュームは ON です</span>
                  )}
                </div>
              </div>
            ) : (
              <span className="error">このライブは現在配信されてません。</span>
            )}
          </div>
        ) : (
          <span className="error">このブラウザは HLS (HTTP Live Streaming) がサポートされてません。</span>
        )}
      </div>
    );
  }
}
