import React, { Component } from 'react';
import {
  Col,
  ControlLabel,
  FormGroup,
  FormControl,
} from 'react-bootstrap';

import './App.css';
import ReceptionistNav from './ReceptionistNav';
import firebase from './firebase';
import model from './Model';

export default class Receptionist extends Component {
  constructor(props) {
    super(props);
    this.state = {
      receptionist: {
        name: '',
      },
      error: '',
      message: ''
    };
  }

  componentDidMount() {
    model.collection('receptionists').doc(firebase.auth().currentUser.uid).get().then((doc) => {
      if (doc.exists) {
        this.setState({ receptionist: doc.data() });
      }
    });
  }

  onChange = (e) => {
    const receptionist = this.state.receptionist;
    receptionist[e.target.name] = e.target.value;
    this.setState({ receptionist });
  };

  updatereceptionist = (e) => {
    e.preventDefault();
    const receptionist = this.state.receptionist;
    if (receptionist.name === '') {
      this.setState({ message: '', error: 'レセプショニスト名が設定されていません' });
      return;
    }
    firebase.firestore().collection('receptionists').doc(firebase.auth().currentUser.uid).set(receptionist);
    this.setState({ message: '登録しました', error: '' });
  };

  render() {
    const s = this.state.receptionist;
    return (
      <div>
        <ReceptionistNav permission={this.props.permission}/>
        <div className="content">
          <h2>レセプショニスト情報</h2>
          <div className="card">
            <form className="form form-horizontal" onSubmit={this.updatereceptionist}>
              <FormGroup controlId="name">
                <Col componentClass={ControlLabel} sm={5}>レセプショニスト名<div className="tagmust">
                  必須</div></Col>
                <Col sm={7}>
                  <FormControl name="name" type="text" placeholder="レセプショニスト名" value={s.name}
                    onChange={this.onChange} />
                </Col>
              </FormGroup>
              <FormGroup>
                <Col smOffset={5} sm={7}>
                  <button type="submit" className="strongBtn btn">登録する</button>
                </Col>
              </FormGroup>
              <FormGroup>
                <Col smOffset={5} sm={7}>
                  <p className="error">{this.state.error}</p>
                  <p className="green">{this.state.message}</p>
                </Col>
              </FormGroup>
            </form>
          </div>
        </div>
      </div>
    );
  }
}
