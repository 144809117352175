import FileSaver from 'file-saver';
import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import moment from 'moment';
import 'moment-duration-format';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { DateRangePicker } from 'react-dates';

import './App.css';
import StreamerNav from './StreamerNav';
import firebase from './firebase';
import model from './Model';
import Utilities from './Utilities';

const db = firebase.firestore();

export default class ApiData extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isAdmin: null,
      redirect: false,
      liveIds: [],
      lives: [],
      sessions: null,
      startDate: null,
      endDate: null,
      focusedInput: null,
      averageTime: '',
      domain: '',
      liveUsers: {},
      archiveUsers: {},
      liveArchiveUsers: {},
      previewUsers: {},
      liveClicks: {},
      archiveClicks: {},
      liveArchiveClicks: {},
      previewClicks: {},
      purchaseData: {
        preview: {
          liveNum: {},
          liveSum: {},
          allNum: {},
          allSum: {}
        },
        live: {
          liveNum: {},
          liveSum: {},
          allNum: {},
          allSum: {}
        },
        archive: {
          liveNum: {},
          liveSum: {},
          allNum: {},
          allSum: {}
        },
        liveArchive: {
          liveNum: {},
          allNum: {},
        }
      },
      unitIds: [],
      loading: false,
      results: null,
    };
  }

  componentDidMount() {
    this.uid = firebase.auth().currentUser.uid;

    db.collection('admins').doc(this.uid).get().then((documentSnapshot) => {
      this.setState({
        isAdmin: documentSnapshot.exists,
        startDate: moment().startOf('day'),
        endDate: moment().endOf('day'),
      });
    })
  }

  async checkViews() {
    const {
      startDate,
      endDate,
      domain,
    } = this.state;
    const eventsQuery = await (
      db
        .collection('events')
        .where('name', '==', 'live')
        .where('domain', '==', domain)
        .orderBy('created')
        .startAt(firebase.firestore.Timestamp.fromDate(startDate.toDate()))
        .endAt(firebase.firestore.Timestamp.fromDate(endDate.toDate()))
        .get()
    );
    const liveUsers = {}, archiveUsers = {}, liveArchiveUsers = {}, previewUsers = {};
    eventsQuery.forEach((doc) => {
      const event = doc.data();
      const liveId = event.liveId;
      if (!this.state.liveIds.includes(liveId)) {
        return;
      }
      if (!liveUsers[liveId]) {
        liveUsers[liveId] = {};
      }
      if (!archiveUsers[liveId]) {
        archiveUsers[liveId] = {};
      }
      if (!liveArchiveUsers[liveId]) {
        liveArchiveUsers[liveId] = {};
      }
      if (!previewUsers[liveId]) {
        previewUsers[liveId] = {};
      }
      if (event.type === 'live') {
        liveUsers[liveId][event.uid] = true;
        liveArchiveUsers[liveId][event.uid] = true;
      } else if (event.type === 'archive') {
        archiveUsers[liveId][event.uid] = true;
        liveArchiveUsers[liveId][event.uid] = true;
      } else if (event.type === 'preview') {
        previewUsers[liveId][event.uid] = true;
      }
    });
    const reducer = (accumulator, currentValue) => accumulator + Object.keys(currentValue).length;
    liveUsers['total'] = Object.values(liveUsers).reduce(reducer, 0);
    archiveUsers['total'] = Object.values(archiveUsers).reduce(reducer, 0);
    liveArchiveUsers['total'] = Object.values(liveArchiveUsers).reduce(reducer, 0);
    previewUsers['total'] = Object.values(previewUsers).reduce(reducer, 0);
    this.setState({ liveUsers, archiveUsers, liveArchiveUsers, previewUsers });
  }

  async checkClicks() {
    const {
      liveIds,
      startDate,
      endDate,
    } = this.state;
    const eventsQueries = await Promise.all(liveIds.map((liveId) => (
      db
        .collection('events')
        .where('name', '==', 'item')
        .where('liveId', '==', liveId)
        .orderBy('created')
        .startAt(firebase.firestore.Timestamp.fromDate(startDate.toDate()))
        .endAt(firebase.firestore.Timestamp.fromDate(endDate.toDate()))
        .get()
    )));
    const events = eventsQueries.reduce((previous, current) => [
      ...previous,
      ...current.docs.map((eventDoc) => eventDoc.data()),
    ], []);
    const liveClicks = {}, archiveClicks = {}, liveArchiveClicks = {}, previewClicks = {};
    events.forEach((event) => {
      const liveId = event.liveId;
      if (!liveClicks[liveId]) {
        liveClicks[liveId] = {};
      }
      if (!archiveClicks[liveId]) {
        archiveClicks[liveId] = {};
      }
      if (!liveArchiveClicks[liveId]) {
        liveArchiveClicks[liveId] = {};
      }
      if (!previewClicks[liveId]) {
        previewClicks[liveId] = {};
      }
      if (event.type === 'preview') {
        previewClicks[liveId][event.uid] = true;
      } else if (event.type === 'live') {
        liveClicks[liveId][event.uid] = true;
        liveArchiveClicks[liveId][event.uid] = true;
      } else if (event.type === 'archive') {
        archiveClicks[liveId][event.uid] = true;
        liveArchiveClicks[liveId][event.uid] = true;
      }
    });
    const reducer = (accumulator, currentValue) => accumulator + Object.keys(currentValue).length;
    liveClicks['total'] = Object.values(liveClicks).reduce(reducer, 0);
    archiveClicks['total'] = Object.values(archiveClicks).reduce(reducer, 0);
    liveArchiveClicks['total'] = Object.values(liveArchiveClicks).reduce(reducer, 0);
    previewClicks['total'] = Object.values(previewClicks).reduce(reducer, 0);
    this.setState({ liveClicks, archiveClicks, liveArchiveClicks, previewClicks });
  }

  async queryOrders() {
    const {
      liveIds,
      sessions,
      startDate,
      endDate,
      domain,
    } = this.state;
    const {
      mainLiveUnitIds,
      otherLiveUnitIds,
    } = model.getLiveUnitIds(liveIds, sessions);
    const eventsQuery = await (
      db
        .collection('events')
        .where('name', '==', 'order')
        .where('domain', '==', domain)
        .where('created', '>=', startDate.toDate())
        .where('created', '<=', endDate.toDate())
        .orderBy('created')
        .get()
    );
    const orders = eventsQuery.docs.map((eventDoc) => eventDoc.data());

    const orderIds = [];
    const outputPromises = [];
    orders.forEach((order) => {
      const orderTime = moment(order.created.toDate());
      if (order.uid && Array.isArray(order.units) && !orderIds.includes(order.orderId)) {
        orderIds.push(order.orderId);

        if (order.viewData && model.shouldUseNewCookies(orderTime)) {
          const lastView = model.getLastView(order, mainLiveUnitIds, otherLiveUnitIds);
          if (lastView && liveIds.includes(lastView.liveId)) {
            outputPromises.push(new Promise((resolve, reject) => {
              resolve(model.getOrderData(order, lastView, sessions));
            }));
          }
        } else {
          outputPromises.push(new Promise((resolve) => {
            (
              db
                .collection('events')
                .where('name', '==', 'live')
                .where('domain', '==', domain)
                .where('uid', '==', order.uid)
                .get()
            ).then((viewData) => {
              const result = [];
              if (viewData.size) {
                // Prioritize view type live > archive > preview
                const topViews = {};
                for (let i = 0; i < viewData.size; i++) {
                  const viewEvent = viewData.docs[i].data();
                  const viewTime = moment(viewEvent.created.toDate());
                  const liveId = viewEvent.liveId;
                  if (liveIds.includes(liveId) && orderTime.isAfter(viewTime) &&
                      orderTime.diff(viewTime, 'days') <= 30 &&
                      (!topViews[liveId] || viewEvent.type === 'live' ||
                        topViews[liveId].type === 'preview')) {
                    topViews[liveId] = viewEvent;
                  }
                }
                Object.keys(topViews).forEach((liveId) => {
                  const topViewEvent = topViews[liveId];
                  const price = order.units.reduce((sum, unit) => sum + unit.price, 0);
                  let livePrice = 0;
                  order.units.forEach((unit) => {
                    if (this.state.liveUnitIds[liveId].includes(unit.unitId)) {
                      livePrice += unit.price;
                    }
                  });
                  result.push({
                    orderId: order.orderId,
                    created: orderTime.utcOffset('+0900').format(),
                    liveId,
                    price,
                    livePrice,
                    type: topViewEvent.type,
                    units: JSON.stringify(order.units),
                  });
                });
              }
              resolve(result);
            });
          }));
        }
      }
    });
    Promise.all(outputPromises).then((output) => output.filter((x) => x)).then((resultData) => {
      const data = resultData.flat();
      const results = data.map((order) => `${order.orderId}\t${order.created}\t${order.price}\t${order.livePrice}\t${order.type}\t${order.units}\n`);
      const { purchaseData } = this.state;
      purchaseData['preview'].allNum['total'] = 0;
      purchaseData['preview'].allSum['total'] = 0;
      purchaseData['preview'].liveNum['total'] = 0;
      purchaseData['preview'].liveSum['total'] = 0;
      purchaseData['live'].allNum['total'] = 0;
      purchaseData['live'].allSum['total'] = 0;
      purchaseData['live'].liveNum['total'] = 0;
      purchaseData['live'].liveSum['total'] = 0;
      purchaseData['archive'].allNum['total'] = 0;
      purchaseData['archive'].allSum['total'] = 0;
      purchaseData['archive'].liveNum['total'] = 0;
      purchaseData['archive'].liveSum['total'] = 0;
      purchaseData['liveArchive'].allNum['total'] = 0;
      purchaseData['liveArchive'].liveNum['total'] = 0;
      data.forEach((order) => {
        const { uid, type, liveId, price, livePrice } = order;
        const liveArchive = (type === 'live' || type === 'archive');
        if (!purchaseData[type].allNum[liveId]) {
          purchaseData[type].allNum[liveId] = {};
        }
        if (!purchaseData['liveArchive'].allNum[liveId] && liveArchive) {
          purchaseData['liveArchive'].allNum[liveId] = {};
        }
        if (!purchaseData[type].allSum[liveId]) {
          purchaseData[type].allSum[liveId] = 0;
        }
        if (!purchaseData[type].liveNum[liveId]) {
          purchaseData[type].liveNum[liveId] = {};
        }
        if (!purchaseData['liveArchive'].liveNum[liveId] && liveArchive) {
          purchaseData['liveArchive'].liveNum[liveId] = {};
        }
        if (!purchaseData[type].liveSum[liveId]) {
          purchaseData[type].liveSum[liveId] = 0;
        }
        purchaseData[type].allNum[liveId][uid] = true;
        if (liveArchive) purchaseData['liveArchive'].allNum[liveId][uid] = liveArchive;
        purchaseData[type].allSum[liveId] += price;
        if (livePrice > 0) {
          purchaseData[type].liveNum[liveId][uid] = true;
          if (liveArchive) purchaseData['liveArchive'].liveNum[liveId][uid] = liveArchive;
          purchaseData[type].liveSum[liveId] += livePrice;
        }
        purchaseData[type].allSum['total'] += price;
        if (livePrice > 0) {
          purchaseData[type].liveSum['total'] += livePrice;
        }
      });
      Utilities.countTotalPurchasers(purchaseData);
      this.setState({ purchaseData, loading: false, results });
    });
  }

  onDomainChange = (e) => this.setState({ domain: e.target.value });

  download = () => {
    const blob = new Blob(this.state.results, { type: 'text/plain;charset=utf-8' });
    FileSaver.saveAs(blob, 'orders.tsv');
  };

  onDatesChange = ({ startDate, endDate }) => {
    // Making a copy of startDate and endDate is needed when when they are same value.
    // If not making a copy of them, both will be exactly the same value at result for some reason...
    if (startDate) {
      this.setState({ startDate: startDate.clone().startOf('day') });
    }
    if (endDate) {
      this.setState({ endDate: endDate.clone().endOf('day') });
    }
  };

  updateData = async () => {
    this.setState({
      loading: true,
      results: null,
      liveUsers: {},
      archiveUsers: {},
      liveArchiveUsers: {},
      previewUsers: {},
      liveClicks: {},
      archiveClicks: {},
      liveArchiveClicks: {},
      previewClicks: {},
      purchaseData: {
        preview: {
          liveNum: {},
          liveSum: {},
          allNum: {},
          allSum: {}
        },
        live: {
          liveNum: {},
          liveSum: {},
          allNum: {},
          allSum: {}
        },
        archive: {
          liveNum: {},
          liveSum: {},
          allNum: {},
          allSum: {}
        },
        liveArchive: {
          liveNum: {},
          allNum: {},
        }
      }
    });
    (
      db
        .collection('sessions')
        .where('domain', '==', this.state.domain)
        .where('isTest', '==', false)
        .orderBy('created')
        .get()
    ).then((sessionsQuery) => {
      const sessions = Array.from(sessionsQuery.docs.map((sessionDoc) => sessionDoc.data()).filter((session) => session.broadcastId).reduce((map, session) => (
        map.set(session.liveId, session)
      ), new Map()).values());
      const shopIds = Array.from(sessions.reduce((set, session) => set.add(session.shopId), new Set()));
      const liveIds = Array.from(sessions.reduce((set, session) => set.add(session.liveId), new Set()));
      Promise.all(
        shopIds.map((shopId) => db.collection('shops').doc(shopId).get())
      ).then((shopDocs) => {
        const shops = shopDocs.filter((shopDoc) => shopDoc.exists).map((shopDoc) => shopDoc.data());
        Promise.all(
          liveIds.map((liveId) => db.collection('lives').doc(liveId).get())
        ).then(async (liveDocs) => {
          const lives = liveDocs.filter((liveDoc) => liveDoc.exists).map((liveDoc) => {
            const live = liveDoc.data();
            const shop = shops.find((value) => value.id === live.shopId);
            return {
              ...live,
              shopName: shop ? shop.name : '',
            }
          }).sort((a, b) => b.datetime - a.datetime);
          const liveItemIds = Array.from(lives.reduce((previous, current) => {
            current.items.forEach((item) => previous.add(item.id));
            return previous;
          }, new Set()));
          // To avoid firestore limitations, we should retrieve unit data serially.
          for (let offset = 0; offset < liveItemIds.length; offset += 1000) {
            const chunk = liveItemIds.slice(offset, offset + 1000);
            const liveUnitsQueries = await Promise.all(chunk.map((id) => db.collection('units').where('itemId', '==', id).get()));
            const liveUnits = Array.from(liveUnitsQueries.reduce((previous, current) => {
              current.docs.forEach((doc) => {
                const unit = doc.data();
                if (!liveItemIds.includes(unit.itemId)) {
                  return previous;
                }
                previous.set(`${unit.itemId}:${unit.code}`, {
                  code: unit.code,
                  name: unit.name,
                  image: unit.image,
                  itemId: unit.itemId,
                });
              });
              return previous;
            }, new Map()).values());
            for (const session of sessions) {
              const live = lives.find((v) => v.id === session.liveId);
              if (!live) {
                continue;
              }
              if (!session.items) {
                session.items = [];
              }
              if (!session.units) {
                session.units = [];
              }
              if (live.items) {
                live.items.forEach((item) => {
                  if (session.items.findIndex((v) => v.id === item.id) === -1) {
                    session.items.push(item);
                  }
                  liveUnits.filter((unit) => unit.itemId === item.id).forEach((unit) => {
                    if (session.units.findIndex((v) => v.code === unit.code) === -1) {
                      session.units.push(unit);
                    }
                  });
                });
              }
            };
          }
          this.setState({
            liveIds,
            lives,
            sessions,
          });
          (async () => {
            await this.checkViews();
            await this.checkClicks();
            await this.queryOrders();  
          })();
        });
      });
    });
  };

  enumerateDays = () => {
    const days = [];
    const day = this.state.startDate.clone().startOf('day');
    while (day.isBefore(this.state.endDate)) {
      days.push(day.format('MMDD'));
      day.add(1, 'days');
    }
    return days;
  };

  render() {
    const state = this.state;
    if (state.redirect) {
      return <Redirect to="/streamer/previews" />;
    }
    if (!state.isAdmin) {
      return(
        <div>
          <StreamerNav permission={this.props.permission}/>
          <div className="content">
            <div>閲覧権限がありません</div>
          </div>
        </div>
      )
    }
    return (
      <div>
        <StreamerNav permission={this.props.permission}/>
        <div className="content">
          <h2>レポート</h2>
          <div className="card">
            <p>ドメイン：<input type="text" value={state.domain} onChange={this.onDomainChange} /></p>
            {state.startDate && state.endDate &&
              <div>
                <span>計測期間：</span>
                <DateRangePicker
                  minimumNights={0}
                  startDate={state.startDate} // momentPropTypes.momentObj or null,
                  startDateId="your_unique_start_date_id" // PropTypes.string.isRequired,
                  endDate={state.endDate} // momentPropTypes.momentObj or null,
                  endDateId="your_unique_end_date_id" // PropTypes.string.isRequired,
                  onDatesChange={this.onDatesChange}
                  isOutsideRange={(day) => false}
                  focusedInput={state.focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                  disabled={state.loading}
                  displayFormat="YYYY/M/D"
                  onFocusChange={focusedInput => this.setState({ focusedInput })} />
                <button type="button" className="btn strongBtn" onClick={this.updateData}
                  style={{ marginLeft: 10 }} disabled={state.loading}>
                  レポートを表示する
                </button>
              </div>}
            <h4>{state.results ? 'レポートを出力完了です。' : state.loading ? 'レポートを出力中です......' : ''}</h4>
            {state.results &&
            <div className="reportTableContainer">
              <p>このレポートはLIVE別のパフォーマンスを確認するためのレポートです。</p>
              <div className="apidataOverall">
                <table>
                  <tbody>
                    <tr>
                      <th className="title"></th>
                      <th colSpan="6">ウェイティング</th>
                      <th colSpan="6">ライブ再生</th>
                      <th colSpan="6">アーカイブ再生</th>
                      <th colSpan="6">ライブ・アーカイブ再生</th>
                    </tr>
                    <tr>
                      <th className="title"></th>
                      <th colSpan="1">訪問</th>
                      <th colSpan="1">商品クリック</th>
                      <th colSpan="2">掲載商品購入</th>
                      <th colSpan="2">全商品商品購入</th>
                      <th colSpan="1">動画再生</th>
                      <th colSpan="1">商品クリック</th>
                      <th colSpan="2">掲載商品購入</th>
                      <th colSpan="2">全商品商品購入</th>
                      <th colSpan="1">動画再生</th>
                      <th colSpan="1">商品クリック</th>
                      <th colSpan="2">掲載商品購入</th>
                      <th colSpan="2">全商品商品購入</th>
                      <th colSpan="1">動画再生</th>
                      <th colSpan="1">商品クリック</th>
                      <th colSpan="2">掲載商品購入</th>
                      <th colSpan="2">全商品商品購入</th>
                    </tr>
                    <tr>
                      <th className="title"></th>
                      <th>人数</th>
                      <th>クリック人数</th>
                      <th>購入金額</th>
                      <th>購入者数</th>
                      <th>購入金額</th>
                      <th>購入者数</th>

                      <th>人数</th>
                      <th>クリック人数</th>
                      <th>購入金額</th>
                      <th>購入者数</th>
                      <th>購入金額</th>
                      <th>購入者数</th>

                      <th>人数</th>
                      <th>クリック人数</th>
                      <th>購入金額</th>
                      <th>購入者数</th>
                      <th>購入金額</th>
                      <th>購入者数</th>

                      <th>人数</th>
                      <th>クリック人数</th>
                      <th>購入金額</th>
                      <th>購入者数</th>
                      <th>購入金額</th>
                      <th>購入者数</th>
                    </tr>

                    <tr>
                      <th className="title">Total</th>
                      <td>{state.previewUsers['total']}</td>
                      <td>{state.previewClicks['total']}</td>
                      <td>{state.purchaseData.preview.liveSum['total'] || 0}</td>
                      <td>{state.purchaseData.preview.liveNum['total'] || 0}</td>
                      <td>{state.purchaseData.preview.allSum['total'] || 0}</td>
                      <td>{state.purchaseData.preview.allNum['total'] || 0}</td>

                      <td>{state.liveUsers['total']}</td>
                      <td>{state.liveClicks['total']}</td>
                      <td>{state.purchaseData.live.liveSum['total'] || 0}</td>
                      <td>{state.purchaseData.live.liveNum['total'] || 0}</td>
                      <td>{state.purchaseData.live.allSum['total'] || 0}</td>
                      <td>{state.purchaseData.live.allNum['total'] || 0}</td>

                      <td>{state.archiveUsers['total']}</td>
                      <td>{state.archiveClicks['total']}</td>
                      <td>{state.purchaseData.archive.liveSum['total'] || 0}</td>
                      <td>{state.purchaseData.archive.liveNum['total'] || 0}</td>
                      <td>{state.purchaseData.archive.allSum['total'] || 0}</td>
                      <td>{state.purchaseData.archive.allNum['total'] || 0}</td>

                      <td>{state.liveArchiveUsers['total']}</td>
                      <td>{state.liveArchiveClicks['total']}</td>
                      <td>{(state.purchaseData.archive.liveSum['total'] || 0) + (state.purchaseData.live.liveSum['total'] || 0)}</td>
                      <td>{state.purchaseData.liveArchive.liveNum['total'] || 0}</td>
                      <td>{(state.purchaseData.archive.allSum['total'] || 0) + (state.purchaseData.live.allSum['total'] || 0)}</td>
                      <td>{state.purchaseData.liveArchive.allNum['total'] || 0}</td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <h5>LIVE別レポート</h5>
              <div className="apidataOverall apidataPerLive">
                <table>
                  <tbody>
                    <tr>
                      <th className="title"></th>
                      <th className="title"></th>
                      <th className="title"></th>
                      <th className="title"></th>
                      <th className="title"></th>
                      <th colSpan="6">ウェイティング</th>
                      <th colSpan="6">ライブ再生</th>
                      <th colSpan="6">アーカイブ再生</th>
                      <th colSpan="6">ライブ・アーカイブ再生</th>
                    </tr>
                    <tr>
                      <th className="title"></th>
                      <th className="title"></th>
                      <th className="title"></th>
                      <th className="title"></th>
                      <th className="title"></th>
                      <th colSpan="1">訪問</th>
                      <th colSpan="1">商品クリック</th>
                      <th colSpan="2">掲載商品購入</th>
                      <th colSpan="2">全商品商品購入</th>
                      <th colSpan="1">動画再生</th>
                      <th colSpan="1">商品クリック</th>
                      <th colSpan="2">掲載商品購入</th>
                      <th colSpan="2">全商品商品購入</th>
                      <th colSpan="1">動画再生</th>
                      <th colSpan="1">商品クリック</th>
                      <th colSpan="2">掲載商品購入</th>
                      <th colSpan="2">全商品商品購入</th>
                      <th colSpan="1">動画再生</th>
                      <th colSpan="1">商品クリック</th>
                      <th colSpan="2">掲載商品購入</th>
                      <th colSpan="2">全商品商品購入</th>
                    </tr>
                    <tr>
                      <th className="title">ショップ名</th>
                      <th className="title">ショップID</th>
                      <th className="title">LIVE ID</th>
                      <th className="title">タイトル</th>
                      <th className="title">日付</th>
                      <th>人数</th>
                      <th>クリック人数</th>
                      <th>購入金額</th>
                      <th>購入者数</th>
                      <th>購入金額</th>
                      <th>購入者数</th>

                      <th>人数</th>
                      <th>クリック人数</th>
                      <th>購入金額</th>
                      <th>購入者数</th>
                      <th>購入金額</th>
                      <th>購入者数</th>

                      <th>人数</th>
                      <th>クリック人数</th>
                      <th>購入金額</th>
                      <th>購入者数</th>
                      <th>購入金額</th>
                      <th>購入者数</th>

                      <th>人数</th>
                      <th>クリック人数</th>
                      <th>購入金額</th>
                      <th>購入者数</th>
                      <th>購入金額</th>
                      <th>購入者数</th>
                    </tr>
                    {state.lives.map((live) =>
                      <tr key={live.id}>
                        <th className="title">{live.shopName}</th>
                        <th className="title">{live.shopId}</th>
                        <th className="title">{live.id}</th>
                        <th className="title">{live.name}</th>
                        <th className="title">{moment(live.datetime.toDate()).utcOffset('+0900').format('YYYY年M月D日HH:mm')}</th>
                        <td>{Object.keys(state.previewUsers[live.id] || {}).length}</td>
                        <td>{Object.keys(state.previewClicks[live.id] || {}).length}</td>
                        <td>{state.purchaseData.preview.liveSum[live.id] || 0}</td>
                        <td>{Object.keys(state.purchaseData.preview.liveNum[live.id] || {}).length}</td>
                        <td>{state.purchaseData.preview.allSum[live.id] || 0}</td>
                        <td>{Object.keys(state.purchaseData.preview.allNum[live.id] || {}).length}</td>
                        <td>{Object.keys(state.liveUsers[live.id] || {}).length}</td>
                        <td>{Object.keys(state.liveClicks[live.id] || {}).length}</td>
                        <td>{state.purchaseData.live.liveSum[live.id] || 0}</td>
                        <td>{Object.keys(state.purchaseData.live.liveNum[live.id] || {}).length}</td>
                        <td>{state.purchaseData.live.allSum[live.id] || 0}</td>
                        <td>{Object.keys(state.purchaseData.live.allNum[live.id] || {}).length}</td>
                        <td>{Object.keys(state.archiveUsers[live.id] || {}).length}</td>
                        <td>{Object.keys(state.archiveClicks[live.id] || {}).length}</td>
                        <td>{state.purchaseData.archive.liveSum[live.id] || 0}</td>
                        <td>{Object.keys(state.purchaseData.archive.liveNum[live.id] || {}).length}</td>
                        <td>{state.purchaseData.archive.allSum[live.id] || 0}</td>
                        <td>{Object.keys(state.purchaseData.archive.allNum[live.id] || {}).length}</td>
                        <td>{Object.keys(state.liveArchiveUsers[live.id] || {}).length}</td>
                        <td>{Object.keys(state.liveArchiveClicks[live.id] || {}).length}</td>
                        <td>{(state.purchaseData.archive.liveSum[live.id] || 0) + (state.purchaseData.live.liveSum[live.id] || 0)}</td>
                        <td>{Object.keys(state.purchaseData.liveArchive.liveNum[live.id] || {}).length}</td>
                        <td>{(state.purchaseData.archive.allSum[live.id] || 0) + (state.purchaseData.live.allSum[live.id] || 0)}</td>
                        <td>{Object.keys(state.purchaseData.liveArchive.allNum[live.id] || {}).length}</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>

              <button type="button" className="btn strongBtn apidateDownload"
                onClick={this.download}>注文ログのダウンロード</button>
            </div>}
          </div>
        </div>
      </div>
    );
  }
}
