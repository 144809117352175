import React, { Component } from "react";
import { Nav, NavItem } from "react-bootstrap";
import { Redirect } from "react-router";
import classNames from "classnames";
import firebase from "./firebase";
import model from "./Model";
import {
  NavShopIcon,
  NavItemdataIcon,
  NavThemesIcon,
  NavScenariosIcon,
  NavStreamerIcon,
  NavReceptionIcon,
} from "./Icon";

export default class SellerNav extends Component {
  constructor(props) {
    super(props);
    this.state = {
      toAgreement: false,
    };
  }

  componentDidMount() {
    model
      .collection("shops")
      .where("uid", "==", firebase.auth().currentUser.uid)
      .get()
      .then((data) => {
        if (!data.empty) {
          const shop = data.docs[0].data();
          if (
            !shop.agreementFlag &&
            window.location.pathname !== "/shop" &&
            window.location.pathname !== "/shopdetail"
          ) {
            this.setState({ toAgreement: true });
            return;
          }
        }
      });
  }

  isNavItemSelected = (itemName) => {
    const path = window.location.pathname;
    if (itemName === "shop") {
      return path.includes("/shop");
    }
    if (itemName === "itemdata") {
      return path.includes("/item");
    }
    if (itemName === "themes") {
      return path.includes("/themes");
    }
    if (itemName === "scenarios") {
      return path.includes("/scenarios");
    }
    return false;
  };

  getNavItemClassName = (itemName) =>
    classNames(
      "navItem",
      this.isNavItemSelected(itemName) && "navItem-selected"
    );

  render() {
    if (this.state.toAgreement) {
      return <Redirect to={{ pathname: "/agreement" }} />;
    }

    return (
      <Nav bsStyle="pills" className="navBar">
        {this.props.permission.seller && (
          <NavItem href="/shop" className={this.getNavItemClassName("shop")}>
            <NavShopIcon className="navIcon" />
            <span className="text-center">ショップ情報</span>
          </NavItem>
        )}
        {this.props.permission.seller && (
          <NavItem
            href="/itemdata"
            className={this.getNavItemClassName("itemdata")}
          >
            <NavItemdataIcon className="navIcon" />
            <span className="text-center">商品管理</span>
          </NavItem>
        )}
        {this.props.permission.seller && (
          <NavItem
            href="/themes"
            className={this.getNavItemClassName("themes")}
          >
            <NavThemesIcon className="navIcon" />
            <span className="text-center">テーマ管理</span>
          </NavItem>
        )}
        {this.props.permission.seller && (
          <NavItem
            href="/scenarios"
            className={this.getNavItemClassName("scenarios")}
          >
            <NavScenariosIcon className="navIcon navIcon-fill" />
            <span className="text-center">シナリオ管理</span>
          </NavItem>
        )}
        <NavItem
          href="/streamer"
          className={this.getNavItemClassName("streamer")}
        >
          <NavStreamerIcon className="navIcon" />
          <span className="text-center">
            TAGsAPI for
            <br />
            Streamer
          </span>
        </NavItem>
        {this.props.permission.reception && (
          <NavItem
            href="/receptionist"
            target="_blank"
            className={this.getNavItemClassName("receptionist")}
          >
            <NavReceptionIcon className="navIcon" />
            <span className="text-center">
              TAGsAPI for
              <br />
              Receptionist
            </span>
          </NavItem>
        )}
      </Nav>
    );
  }
}
