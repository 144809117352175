import React, { Component } from "react";
import Dropzone from "react-dropzone";
import {
  Button,
  Checkbox,
  Col,
  ControlLabel,
  FormGroup,
  FormControl,
  Nav,
  NavItem,
  Radio,
} from "react-bootstrap";

import Cookies from "js-cookie";

import "./App.css";
import SellerNav from "./SellerNav";
import firebase from "./firebase";
import model from "./Model";

export default class Shop extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: [],
      success: "",
      section: 1,
      shop: {
        sellerName: "",
        phone: "",
        email: "",
        name: "",
        returnAddress: "",
        thumbnail: null,
        image: null,
        sellerEmail: "",
        domain: "",
        hideLaw: false,
        allyDomains: [],
        sellerPermit: "ON",
        receptionPermit: "OFF",
        gaEventPermit: "OFF",
        autoPlayPermit: "OFF",
        pipModePermit: "OFF",
        surveyPermit: "OFF",
        onlyLivePermit: "OFF",
        adminAuth: false,
        password: "",
        authError: "",
      },
      ftpAccount: {
        username: "",
        password: "",
        enabled: false,
        noQuotes: false,
      },
      streamerEmail: "",
      streamers: [],
      receptionistEmail: "",
      receptionists: [],
    };
  }

  componentDidMount() {
    const uid = firebase.auth().currentUser.uid;
    model
      .collection("shops")
      .where("uid", "==", uid)
      .get()
      .then((data) => {
        if (!data.empty) {
          const shopDoc = data.docs[0];
          this.setState({ shop: shopDoc.data() });
          this.offStreamers = model
            .collection("shops")
            .doc(shopDoc.id)
            .collection("streamers")
            .onSnapshot((data) =>
              this.setState({ streamers: model.listData(data) })
            );
          this.offReceptionists = model
            .collection("shops")
            .doc(shopDoc.id)
            .collection("receptionists")
            .onSnapshot((data) =>
              this.setState({ receptionists: model.listData(data) })
            );
        }
      });
    const passwordCookie = Cookies.get("basic-auth");
    if (passwordCookie) {
      this.setState({ password: passwordCookie }, () => this.auth()); //パスワードをCookieで保持し再認証
    }
    model
      .collection("ftp_accounts")
      .doc(uid)
      .get()
      .then((doc) => {
        if (!doc.exists) {
          return;
        }
        this.setState({ ftpAccount: doc.data() });
      });
  }

  componentWillUnmount() {
    if (this.offStreamers) {
      this.offStreamers();
    }
    if (this.offReceptionists) {
      this.offReceptionists();
    }
  }

  onSelect = (section) => this.setState({ section });

  onShopChange = (e) => {
    const shop = this.state.shop;
    shop[e.target.name] = e.target.value;
    this.setState({ shop });
  };

  onFtpAccountChange = (e) => {
    const ftpAccount = this.state.ftpAccount;
    ftpAccount[e.target.name] = e.target.value === "ON";
    this.setState({ ftpAccount });
  };

  onThumbnailChange = (files) => {
    const name = "thumbnail";
    const file = files[0];
    firebase
      .storage()
      .ref()
      .child(`${firebase.auth().currentUser.uid}/shop/${name}`)
      .put(file)
      .then((snapshot) => {
        snapshot.ref.getDownloadURL().then((url) => {
          const shop = this.state.shop;
          shop[name] = url;
          this.setState({ shop });
        });
      });
  };

  onImageChange = (files) => {
    const name = "image";
    const file = files[0];
    firebase
      .storage()
      .ref()
      .child(`${firebase.auth().currentUser.uid}/shop/${name}`)
      .put(file)
      .then((snapshot) => {
        snapshot.ref.getDownloadURL().then((url) => {
          const shop = this.state.shop;
          shop[name] = url;
          this.setState({ shop });
        });
      });
  };

  onShowLawChange = (e) => {
    const shop = this.state.shop;
    shop.hideLaw = !e.target.checked;
    this.setState({ shop });
  };

  onPermitChange = (e) => {
    const shop = this.state.shop;
    shop[e.target.name] = e.target.value;
    this.setState({ shop });
  };

  onAllyDomainsChange = (e) => {
    const shop = this.state.shop;
    shop.allyDomains = e.target.value.split(",");
    this.setState({ shop });
  };

  updateShop = (e) => {
    e.preventDefault();
    const shop = this.state.shop;
    const ftpAccount = this.state.ftpAccount;
    let errors = [];
    if (!shop.sellerName) {
      errors.push("ショップ運営責任者が未入力です");
    }
    if (!shop.phone) {
      errors.push("ショップの電話番号が未入力です");
    }
    if (!shop.email) {
      errors.push("ショップのメールアドレスが未入力です");
    }
    if (!shop.name) {
      errors.push("ショップ名が未入力です");
    }
    if (!shop.returnAddress) {
      errors.push("返品商品送付先住所が未入力です");
    }
    if (!shop.thumbnail) {
      errors.push("ショップサムネイルが未入力です");
    }
    if (!shop.image) {
      errors.push("ショップバナーが未入力です");
    }
    if (!shop.sellerEmail) {
      errors.push("担当責任者のメールアドレスが未入力です");
    }
    if (!shop.domain) {
      errors.push("TAGsAPIを使うドメインが未入力です");
    }
    if (shop.allyDomains) {
      shop.allyDomains = shop.allyDomains
        .map((e) => e.replace(/\s/g, ""))
        .filter((e) => e);
    }
    if (!errors.length) {
      shop.uid = firebase.auth().currentUser.uid;
      const shopsRef = model.collection("shops");
      if (shop.id) {
        shopsRef.doc(shop.id).update(shop);
      } else {
        const doc = shopsRef.doc();
        shop.id = doc.id;
        shop.admin = firebase.auth().currentUser.uid;
        shop.adminEmail = firebase.auth().currentUser.email;
        shop.sales = 0;
        shop.enabled = false;
        doc.set(shop);
      }
      if (ftpAccount.username) {
        const ftpAccountRef = firebase
          .firestore()
          .collection("ftp_accounts")
          .doc(ftpAccount.username);
        ftpAccountRef.update(ftpAccount);
      }
      this.setState({ errors, success: "ショップ情報を登録しました" });
    } else {
      this.setState({ errors, success: "" });
    }
  };

  onStreamerChange = (e) => this.setState({ streamerEmail: e.target.value });

  addStreamer = () => {
    if (this.state.shop.id) {
      const email = this.state.streamerEmail;
      const streamersRef = firebase
        .firestore()
        .collection("shops")
        .doc(this.state.shop.id)
        .collection("streamers");
      streamersRef
        .where("email", "==", email)
        .get()
        .then((data) => {
          if (data.empty) {
            streamersRef.add({ email });
          }
        });
    }
  };

  deleteStreamer = (email) => {
    if (window.confirm("削除して本当によろしいですか？")) {
      const streamersRef = firebase
        .firestore()
        .collection("shops")
        .doc(this.state.shop.id)
        .collection("streamers");
      streamersRef
        .where("email", "==", email)
        .get()
        .then((data) => {
          if (!data.empty) {
            streamersRef.doc(data.docs[0].id).delete();
          }
        });
    }
  };

  onReceptionistChange = (e) =>
    this.setState({ receptionistEmail: e.target.value });

  addReceptionist = () => {
    if (this.state.shop.id) {
      const email = this.state.receptionistEmail;
      const receptionistsRef = firebase
        .firestore()
        .collection("shops")
        .doc(this.state.shop.id)
        .collection("receptionists");
      receptionistsRef
        .where("email", "==", email)
        .get()
        .then((data) => {
          if (data.empty) {
            receptionistsRef.add({ email });
          }
        });
    }
  };

  deleteReceptionist = (email) => {
    if (window.confirm("削除して本当によろしいですか？")) {
      const receptionistsRef = firebase
        .firestore()
        .collection("shops")
        .doc(this.state.shop.id)
        .collection("receptionists");
      receptionistsRef
        .where("email", "==", email)
        .get()
        .then((data) => {
          if (!data.empty) {
            receptionistsRef.doc(data.docs[0].id).delete();
          }
        });
    }
  };

  setActiveClass = (section) => {
    return this.state.section === section ? "active" : "";
  };

  auth = () => {
    model
      .fetch("basicAuth", "POST", {
        password: this.state.password,
      })
      .then(() => {
        this.setState({ adminAuth: true });
        Cookies.set("basic-auth", this.state.password);
      })
      .catch(() => {
        this.setState({ authError: "認証に失敗しました。" });
        return;
      });
  };

  renderShop() {
    const shop = this.state.shop;
    const ftpAccount = this.state.ftpAccount;
    return (
      <form className="form form-horizontal" onSubmit={this.updateShop}>
        <FormGroup controlId="sellerName">
          <Col componentClass={ControlLabel} sm={4}>
            ショップ運営責任者名
          </Col>
          <Col sm={8}>
            <FormControl
              name="sellerName"
              type="text"
              placeholder="姓名"
              value={shop.sellerName}
              onChange={this.onShopChange}
            />
          </Col>
        </FormGroup>

        <FormGroup controlId="sellerEmail">
          <Col componentClass={ControlLabel} sm={4}>
            ショップ運営責任者のメールアドレス
          </Col>
          <Col sm={8}>
            <FormControl
              name="sellerEmail"
              type="email"
              placeholder="example@example.com"
              value={shop.sellerEmail}
              onChange={this.onShopChange}
            />
          </Col>
        </FormGroup>

        <FormGroup controlId="phone">
          <Col componentClass={ControlLabel} sm={4}>
            ショップの電話番号
          </Col>
          <Col sm={8}>
            <FormControl
              name="phone"
              type="text"
              placeholder="09012345678"
              value={shop.phone}
              onChange={this.onShopChange}
            />
          </Col>
        </FormGroup>

        <FormGroup controlId="email">
          <Col componentClass={ControlLabel} sm={4}>
            ショップのメールアドレス
          </Col>
          <Col sm={8}>
            <FormControl
              name="email"
              type="email"
              placeholder="example@example.com"
              value={shop.email}
              onChange={this.onShopChange}
            />
          </Col>
        </FormGroup>

        <FormGroup controlId="name">
          <Col componentClass={ControlLabel} sm={4}>
            ショップ名
          </Col>
          <Col sm={8}>
            <FormControl
              name="name"
              type="text"
              placeholder="モフリオンライン"
              value={shop.name}
              onChange={this.onShopChange}
            />
          </Col>
        </FormGroup>

        <FormGroup controlId="returnAddress">
          <Col componentClass={ControlLabel} sm={4}>
            返品商品送付先住所
          </Col>
          <Col sm={8}>
            <FormControl
              name="returnAddress"
              type="text"
              placeholder="東京都渋谷区神宮前1-1-1-101"
              value={shop.returnAddress}
              onChange={this.onShopChange}
            />
          </Col>
        </FormGroup>

        <FormGroup controlId="showLaw">
          <Col componentClass={ControlLabel} sm={4}></Col>
          <Col sm={8}>
            <Checkbox
              name="showLaw"
              onChange={this.onShowLawChange}
              checked={!shop.hideLaw}
            >
              特定商取引法に基づく表記
            </Checkbox>
          </Col>
        </FormGroup>

        <FormGroup controlId="thumbnail">
          <Col componentClass={ControlLabel} sm={4}>
            ショップサムネイル (200x200)
          </Col>
          <Col sm={8}>
            <Dropzone
              name="thumbnail"
              onDrop={this.onThumbnailChange}
              accept={{ "image/*": [] }}
            >
              {({ getRootProps, getInputProps }) => (
                <section className="dropzoneContainer">
                  <div className="dropzone" {...getRootProps()}>
                    <input {...getInputProps()} />
                    <p>ファイルアップロード</p>
                    <input
                      type="button"
                      value="ファイルを選択"
                      onChange={this.onFileChange}
                    ></input>
                  </div>
                </section>
              )}
            </Dropzone>
            {shop.thumbnail && (
              <img
                src={shop.thumbnail}
                alt="thumbnail"
                className="profileImage"
              />
            )}
          </Col>
        </FormGroup>

        <FormGroup controlId="image">
          <Col componentClass={ControlLabel} sm={4}>
            ショップバナー (1080x360)
          </Col>
          <Col sm={8}>
            <Dropzone
              name="image"
              onDrop={this.onImageChange}
              accept={{ "image/*": [] }}
            >
              {({ getRootProps, getInputProps }) => (
                <section className="dropzoneContainer">
                  <div className="dropzone" {...getRootProps()}>
                    <input {...getInputProps()} />
                    <p>ファイルアップロード</p>
                    <input
                      type="button"
                      value="ファイルを選択"
                      onChange={this.onFileChange}
                    ></input>
                  </div>
                </section>
              )}
            </Dropzone>
            {shop.image && (
              <img src={shop.image} alt="shop" className="profileImage" />
            )}
          </Col>
        </FormGroup>

        <FormGroup controlId="sellerPermit">
          <Col componentClass={ControlLabel} sm={4}>
            セラー
          </Col>
          <Col sm={8}>
            <Radio
              inline
              name="sellerPermit"
              value="ON"
              checked={shop["sellerPermit"] === "ON"}
              onChange={this.onPermitChange}
            >
              {" "}
              ON{" "}
            </Radio>
            <Radio
              inline
              name="sellerPermit"
              value="OFF"
              checked={shop["sellerPermit"] === "OFF"}
              onChange={this.onPermitChange}
            >
              {" "}
              OFF{" "}
            </Radio>
          </Col>
        </FormGroup>

        <FormGroup controlId="receptionPermit">
          <Col componentClass={ControlLabel} sm={4}>
            レセプショニスト
          </Col>
          <Col sm={8}>
            <Radio
              inline
              name="receptionPermit"
              value="ON"
              checked={shop["receptionPermit"] === "ON"}
              onChange={this.onPermitChange}
            >
              {" "}
              ON{" "}
            </Radio>
            <Radio
              inline
              name="receptionPermit"
              value="OFF"
              checked={shop["receptionPermit"] === "OFF"}
              onChange={this.onPermitChange}
            >
              {" "}
              OFF{" "}
            </Radio>
          </Col>
        </FormGroup>

        <FormGroup controlId="gaEventPermit">
          <Col componentClass={ControlLabel} sm={4}>
            動画再生イベント（GA）
          </Col>
          <Col sm={8}>
            <Radio
              inline
              name="gaEventPermit"
              value="ON"
              checked={shop["gaEventPermit"] === "ON"}
              onChange={this.onPermitChange}
            >
              {" "}
              ON{" "}
            </Radio>
            <Radio
              inline
              name="gaEventPermit"
              value="OFF"
              checked={shop["gaEventPermit"] === "OFF"}
              onChange={this.onPermitChange}
            >
              {" "}
              OFF{" "}
            </Radio>
          </Col>
        </FormGroup>

        <FormGroup controlId="autoPlayPermit">
          <Col componentClass={ControlLabel} sm={4}>
            ライブウインドウ直接表示
          </Col>
          <Col sm={8}>
            <Radio
              inline
              name="autoPlayPermit"
              value="ON"
              checked={shop["autoPlayPermit"] === "ON"}
              onChange={this.onPermitChange}
            >
              {" "}
              ON{" "}
            </Radio>
            <Radio
              inline
              name="autoPlayPermit"
              value="OFF"
              checked={shop["autoPlayPermit"] === "OFF"}
              onChange={this.onPermitChange}
            >
              {" "}
              OFF{" "}
            </Radio>
          </Col>
        </FormGroup>

        <FormGroup controlId="pipModePermit">
          <Col componentClass={ControlLabel} sm={4}>
            PIP 機能
          </Col>
          <Col sm={8}>
            <Radio
              inline
              name="pipModePermit"
              value="ON"
              checked={shop["pipModePermit"] === "ON"}
              onChange={this.onPermitChange}
            >
              {" "}
              ON{" "}
            </Radio>
            <Radio
              inline
              name="pipModePermit"
              value="OFF"
              checked={shop["pipModePermit"] === "OFF"}
              onChange={this.onPermitChange}
            >
              {" "}
              OFF{" "}
            </Radio>
          </Col>
        </FormGroup>

        <FormGroup controlId="surveyPermit">
          <Col componentClass={ControlLabel} sm={4}>
            アンケート 機能
          </Col>
          <Col sm={8}>
            <Radio
              inline
              name="surveyPermit"
              value="ON"
              checked={shop["surveyPermit"] === "ON"}
              onChange={this.onPermitChange}
            >
              {" "}
              ON{" "}
            </Radio>
            <Radio
              inline
              name="surveyPermit"
              value="OFF"
              checked={shop["surveyPermit"] === "OFF"}
              onChange={this.onPermitChange}
            >
              {" "}
              OFF{" "}
            </Radio>
          </Col>
        </FormGroup>

        <FormGroup controlId="onlyLivePermit">
          <Col componentClass={ControlLabel} sm={4}>
            ライブ配信のみ
          </Col>
          <Col sm={8}>
            <Radio
              inline
              name="onlyLivePermit"
              value="ON"
              checked={shop["onlyLivePermit"] === "ON"}
              onChange={this.onPermitChange}
            >
              {" "}
              ON{" "}
            </Radio>
            <Radio
              inline
              name="onlyLivePermit"
              value="OFF"
              checked={shop["onlyLivePermit"] === "OFF"}
              onChange={this.onPermitChange}
            >
              {" "}
              OFF{" "}
            </Radio>
          </Col>
        </FormGroup>

        <FormGroup controlId="domain">
          <Col componentClass={ControlLabel} sm={4}>
            TAGsAPIを使うドメイン
          </Col>
          <Col sm={8}>
            <FormControl
              name="domain"
              type="text"
              placeholder="tagfab.jp"
              value={shop.domain}
              onChange={this.onShopChange}
            />
          </Col>
        </FormGroup>

        <FormGroup controlId="allyDomains">
          <Col componentClass={ControlLabel} sm={4}>
            ほかに表示するドメイン
          </Col>
          <Col sm={8}>
            <FormControl
              name="allyDomains"
              type="text"
              placeholder="example.com,tagfab.jp"
              value={shop.allyDomains}
              onChange={this.onAllyDomainsChange}
            />
          </Col>
        </FormGroup>

        {ftpAccount.username && (
          <div>
            <FormGroup controlId="username">
              <Col componentClass={ControlLabel} sm={4}>
                FTPアカウント：ユーザー名
              </Col>
              <Col sm={8}>
                <p>{ftpAccount.username}</p>
              </Col>
            </FormGroup>

            <FormGroup controlId="password">
              <Col componentClass={ControlLabel} sm={4}>
                FTPアカウント：パスワード
              </Col>
              <Col sm={8}>
                <p>{ftpAccount.password}</p>
              </Col>
            </FormGroup>

            <FormGroup controlId="enabled">
              <Col componentClass={ControlLabel} sm={4}>
                FTPアカウント：自動連携
              </Col>
              <Col sm={8}>
                <Radio
                  inline
                  name="enabled"
                  value="ON"
                  checked={ftpAccount.enabled}
                  onChange={this.onFtpAccountChange}
                >
                  ON
                </Radio>
                <Radio
                  inline
                  name="enabled"
                  value="OFF"
                  checked={!ftpAccount.enabled}
                  onChange={this.onFtpAccountChange}
                >
                  OFF
                </Radio>
              </Col>
            </FormGroup>

            <FormGroup controlId="noQuotes">
              <Col componentClass={ControlLabel} sm={4}>
                FTPアカウント：ダブルクオートをエスケープ
              </Col>
              <Col sm={8}>
                <Radio
                  inline
                  name="noQuotes"
                  value="ON"
                  checked={ftpAccount.noQuotes}
                  onChange={this.onFtpAccountChange}
                >
                  ON
                </Radio>
                <Radio
                  inline
                  name="noQuotes"
                  value="OFF"
                  checked={!ftpAccount.noQuotes}
                  onChange={this.onFtpAccountChange}
                >
                  OFF
                </Radio>
              </Col>
            </FormGroup>
          </div>
        )}

        <FormGroup>
          <Col smOffset={4} sm={8}>
            <Button type="submit" bsStyle="warning">
              登録する
            </Button>
          </Col>
        </FormGroup>

        <FormGroup>
          <Col smOffset={4} sm={8}>
            {this.state.success && (
              <p className="success">{this.state.success}</p>
            )}
            {this.state.errors.map((error, index) => (
              <p key={index} className="error">
                {error}
              </p>
            ))}
          </Col>
        </FormGroup>
      </form>
    );
  }

  renderStreamer = () => (
    <div>
      <p>
        ライブ配信をするストリーマーのメールアドレスを入力し、招待するとストリーマーがあなたのストアのライブ配信ができるようになります。
      </p>
      <FormControl
        name="streamerEmail"
        type="email"
        value={this.state.streamerEmail}
        onChange={this.onStreamerChange}
        className="input"
      />
      <button
        type="button"
        className="btn strongBtn"
        onClick={this.addStreamer}
      >
        ストリーマーを招待する
      </button>
      {this.state.streamers.length > 0 && (
        <div className="space">
          ストリーマー一覧：
          <ul className="tableHalf">
            {this.state.streamers.map((streamer) => (
              <li key={streamer.email}>
                <span className="streamerCell">{streamer.email}</span>
                <span
                  className="listIconClose"
                  onClick={() => this.deleteStreamer(streamer.email)}
                >
                  <img
                    src={require("./img/icon/icon_close.svg")}
                    alt="削除する"
                    className="close"
                  />
                </span>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );

  renderReceptionist = () => (
    <div>
      <p>
        レセプショニストのメールアドレスを入力し、招待するとレセプショニストがあなたのストアのレセプションがきるようになります。
      </p>
      <FormControl
        name="receptionistEmail"
        type="email"
        value={this.state.receptionistEmail}
        onChange={this.onReceptionistChange}
        className="input"
      />
      <button
        type="button"
        className="btn strongBtn"
        onClick={this.addReceptionist}
      >
        レセプショニストを招待する
      </button>
      {this.state.receptionists.length > 0 && (
        <div className="space">
          レセプショニスト一覧：
          <ul className="tableHalf">
            {this.state.receptionists.map((receptionist) => (
              <li key={receptionist.email}>
                <span className="receptionistCell">{receptionist.email}</span>
                <span
                  className="listIconClose"
                  onClick={() => this.deleteReceptionist(receptionist.email)}
                >
                  <img
                    src={require("./img/icon/icon_close.svg")}
                    alt="削除する"
                    className="close"
                  />
                </span>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );

  render() {
    const passwordCookie = Cookies.get("basic-auth");
    if (!passwordCookie && !this.state.adminAuth) {
      return (
        //ログイン画面
        <div className="loginContainer">
          <div className="AppTitleContainer">
            <h1 className="App-title">管理者パスワード</h1>
          </div>
          <div className="cardMini">
            <div className="cardMiniContainer">
              <form>
                <div className="formUplabel login_passwordArea">
                  <label
                    htmlFor="formHorizontalPassword"
                    className="control-label label-up"
                  >
                    パスワード
                  </label>
                  <input
                    type="password"
                    name="password"
                    placeholder="パスワード"
                    id="formHorizontalPassword"
                    className="form-control"
                    onChange={(e) =>
                      this.setState({ password: e.target.value })
                    }
                  />
                </div>
                <button
                  type="button"
                  onClick={this.auth}
                  className="strongBtn largeBtn"
                >
                  送信
                </button>
                <p className="error mt10">{this.state.authError}</p>
              </form>
            </div>
          </div>
        </div>
      );
    }
    let section;
    switch (this.state.section) {
      case 1: {
        section = this.renderShop();
        break;
      }
      case 2: {
        section = this.renderStreamer();
        break;
      }
      case 3: {
        section = this.renderReceptionist();
        break;
      }
      default: {
        break;
      }
    }
    return (
      <div>
        <SellerNav permission={this.props.permission} />
        <div className="content">
          <h2>ショップ情報</h2>
          <Nav bsStyle="pills" className="detail-nav nav-m-bk">
            <NavItem
              eventKey={1}
              href="#"
              onSelect={this.onSelect}
              className={this.setActiveClass(1)}
            >
              ショップ情報
            </NavItem>
            <NavItem
              eventKey={2}
              href="#"
              onSelect={this.onSelect}
              className={this.setActiveClass(2)}
            >
              ストリーマー管理
            </NavItem>
            <NavItem
              eventKey={3}
              href="#"
              onSelect={this.onSelect}
              className={this.setActiveClass(3)}
            >
              レセプショニスト管理
            </NavItem>
          </Nav>
          <div className="card">{section}</div>
        </div>
      </div>
    );
  }
}
