import React, { Component } from 'react';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, BarElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register(CategoryScale, LinearScale, BarElement, PointElement, LineElement, Title, Tooltip, Legend);

export const options = {
  animation: false,
  responsive: true,
  plugins: {
    legend: {
      display: true
    },
    showAllTooltips: true,
    title: {
      display: true,
      text: '時間（分）',
      position: 'bottom',
      font: { weight: 'normal' }
    },
    tooltip: {
      callbacks: {
        title: (context) => context[0].label + '分'
      }
    }
  },
  scales: {
    y: {
      type: 'linear',
      display: true,
      position: 'left',
      grid: {
        drawOnChartArea: false
      },
      title: {
        display: true,
        text: '商品クリック数'
      },
      min: 0,
      ticks: {
        stepSize: 1
      }
    }
  }
};

export default class LiveReportGraph2 extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return <Line options={options} data={this.props.data} />;
  }
}
