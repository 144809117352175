import React, { Component } from 'react';
import {
  Col,
  Row,
} from 'react-bootstrap';
import Cookies from 'js-cookie';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell } from '@fortawesome/free-regular-svg-icons';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

import moment from 'moment';

import './App.css';
import firebase from './firebase';

import logo from './img/logo.jpg';
import logoBlack from './img/logo-black.jpg';
import helpIcon from './img/icon/icon_help.svg';

const auth = firebase.auth();
const db = firebase.firestore();

export default class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      informationModal: false,
      informations: [],
    };
  }

  componentDidMount() {
    (
      db
        .collection('informations')
        .where('datetime', '<=', moment().endOf('day').toDate())
        .orderBy('datetime', 'desc')
        .get()
    ).then((query) => {
      const informations = query.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
      this.setState({
        informations,
      });
    });
  }

  logout = () => {
    const time = Number(Cookies.get('tagfabPub'));
    if (Number.isInteger(time) && (new Date().getTime() - time) < 10000) {
      window.alert('ライブ配信中はログアウトできません');
      return;
    }
    auth.signOut().catch((error) => console.error(error));
  }

  label = () => {
    const match = window.location.pathname.match(/^\/(admin|receptionist|streamer)/);
    return match ? (match[1][0].toUpperCase() + match[1].slice(1)) : 'Seller';
  }

  prefix = () => {
    const match = window.location.pathname.match(/^\/(receptionist|streamer)/);
    return match && match[1];
  }

  openInformationsModal = () => {
    this.setState({ informationModal: true });
  }

  closeInformationsModal = () => {
    this.setState({ informationModal: false });
  }

  render() {
    const {
      informationModal,
      informations,
    } = this.state;
    const lastInformation = informations[0];
    const isNew = (information) => information && information.datetime.toDate() >= moment().subtract(7, 'days');
    return (
      <React.Fragment>
        {isNew(lastInformation) && (
          <div className="information-bar">
            <a className="information-bar-link" onClick={this.openInformationsModal} href="#/">
              <div className="information-bar-text">
                新しいお知らせがあります
              </div>
            </a>
          </div>
        )}
        <div className="App-header">
          <div className="header">
            <div className="header_appLogoArea">
              <img src={this.prefix() ? logoBlack : logo} className="App-logo" alt="logo" />
              <h1 className="App-title">TAGsAPI for {this.label()}</h1>
            </div>
            {auth.currentUser &&
              <div className="header_loggedInUser">
                <div className="header_userEmail">
                  {auth.currentUser.email}
                </div>
                <a className="header_button" onClick={this.logout} href="#/">ログアウト</a>
              </div>}
            {!this.state.loading && !auth.currentUser &&
              <div>
                <a className="header_button" href={`${this.prefix()}/login`}>ログイン</a>
              </div>}
            <a className="header_help" href="https://tagsapi.info/" target="_blank" rel="noopener noreferrer">
              <img src={helpIcon} alt="help_icon"/>
              Help
            </a>
            {lastInformation && (
              <a className="information-icon" href="#/" onClick={this.openInformationsModal}>
                <FontAwesomeIcon
                  icon={faBell}
                />
                {isNew(lastInformation) && (
                  <span className="information-icon-new">new</span>
                )}
              </a>
            )}
          </div>
        </div>
        {informationModal && (
          <div className="informations-modal-overlay">
            <div className="informations-modal-content">
              <Row className="informations-modal-head">
                <Col xs={6}>
                  <h4><FontAwesomeIcon icon={faBell} /> お知らせ</h4>
                </Col>
                <Col xs={6} style={{ textAlign: 'right' }}>
                  <a className="informations-modal-close" onClick={this.closeInformationsModal} href="#/">
                    <FontAwesomeIcon icon={faTimes} />
                  </a>
                </Col>
              </Row>
              <ul>
                {informations.map((information) => (
                  <li key={information.id} className="informations-modal-content-data">
                    {information.url ? (
                      <a href={information.url} rel="noopener noreferrer" target="_blank">
                        <span className="informations-modal-content-data-date">
                          {moment(information.datetime.toDate()).format('yyyy.MM.DD')}
                        </span>
                        <span className="informations-modal-content-data-text">
                          {information.title} <FontAwesomeIcon icon={faExternalLinkAlt} />
                        </span>
                        {isNew(information) && (
                          <span className="information-label-new">new</span>
                        )}
                      </a>
                    ) : (
                      <React.Fragment>
                        <span className="informations-modal-content-data-date">
                          {moment(information.datetime.toDate()).format('yyyy.MM.DD')}
                        </span>
                        <span className="informations-modal-content-data-text">
                          {information.title}
                        </span>
                        {isNew(information) && (
                          <span className="information-label-new">new</span>
                        )}
                      </React.Fragment>
                    )}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }
}
