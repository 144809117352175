import React, { Component } from 'react';
import { DropdownButton, MenuItem } from 'react-bootstrap';
import { CSVLink } from "react-csv";

import './App.css';
import SellerNav from './SellerNav';
import ItemSearch from './ItemSearch';
import firebase from './firebase';
import model from './Model';

export default class Items extends Component {
  constructor(props) {
    super(props);
    this.state = {
      shopIndex: 0,
      shops: [],
      items: [],
      tsv: [],
    };
  }

  componentDidMount() {
    const uid = firebase.auth().currentUser.uid;
    model.collection('shops').where('admin', '==', uid).get().then((data) => {
      if (!data.empty) {
        this.setState({ shops: model.listData(data) });
        this.selectShop(0);
      } else {
        model.collection('shops').where('uid', '==', uid).get().then((data) => {
          if (!data.empty) {
            const shopId = data.docs[0].id;
            this.getItemData(shopId);
            model.collection('items').where('shopId', '==', shopId).get()
              .then((itemData) => this.setState({ items: model.listData(itemData) }));
          }
        });
      }
    });
  }

  selectShop = (shopIndex) => {
    const shopId = this.state.shops[shopIndex].id;
    this.setState({ shopIndex });
    this.getItemData(shopId);
    model.collection('items').where('shopId', '==', shopId).get()
      .then((itemData) =>
        this.setState({ items: model.listData(itemData).filter((item) => !item.deleted) })
    )};

  getItemData =  async (shopId) => {
    let tsv = [];
    const unitsData = await model.collection('units').where('shopId', '==', shopId).get();
    const units = model.listData(unitsData);
    const salesPriceExists = units.find((unit) => (unit['salesPrice'] || unit['salePriceEffectiveDate']));
    units.forEach((unit) => {
      const data = [
        unit['shopId'],
        unit['itemCode'],
        unit['code'],
        unit['name'].replace(/"/g, '""'),
        unit['info'].replace(/"/g, '""'),
        Number(unit['price']),
        unit['brand'].replace(/"/g, '""'),
        0,
        unit['url'],
        unit['subtitle'].replace(/"/g, '""'),
        unit['image'],
      ];
      if (salesPriceExists) {
        data.push(
          Number(unit['salePrice']),
          unit['salePriceEffectiveDate'],
        );
      }
      tsv.push(data);
    })
    this.setState({ tsv });
  }

  render() {
    const state = this.state;
    return (
      <div>
        <SellerNav permission={this.props.permission}/>
        <div className="content items">
          <h2>商品管理</h2>
          <ul className="detail-nav nav-m-bk nav nav-pills">
            <li role="presentation"><a href="/itemdata">一括登録</a></li>
            <li role="presentation" className="active"><a href="/items">商品一覧</a></li>
            <li role="presentation"><a href="/itemfeed">自動連携</a></li>
          </ul>
          <div className="card">
            {state.shops.length > 0 &&
              <DropdownButton title={state.shops[state.shopIndex].name} id="shop">
                {state.shops.map((shop, i) => (
                  <MenuItem key={i} eventKey={i} active={i === state.shopIndex}
                    onSelect={this.selectShop}>
                    {shop.name}
                  </MenuItem>
                ))}
              </DropdownButton>}
            <div className="absoluteRight">
              <CSVLink data={this.state.tsv} filename={"TSV_all_products.tsv"} separator={"\t"} uFEFF={false} className="normalBtn inlineBlock mt10 mb20">
                TSVデータをダウンロード
              </CSVLink>
            </div>
            {state.shops.length > 0 && state.items.length > 0 &&
              <ItemSearch shopId={state.shops[state.shopIndex].id} />}
          </div>
        </div>
      </div>
    );
  }
}
