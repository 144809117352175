import React, { Component } from 'react';
import Dropzone from 'react-dropzone';
import {
  Button,
  Col,
  ControlLabel,
  FormGroup,
  FormControl,
  Radio,
} from 'react-bootstrap';

import './App.css';
import SellerNav from './SellerNav';
import firebase from './firebase';
import model from './Model';
import moment from 'moment';
import closeIcon from './img/icon/icon_close.svg';
export default class Shop extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: [],
      success: '',
      section: 1,
      seller: {
        name: '',
        address: '',
        phone: '',
        ownerName: '',
        url: '',
        liveUrl: '',
        pipMode: 'OFF',
      },
      shop: {
        name: '',
        banner: null,
        spBanner: null,
      },
    };
  }

  componentDidMount() {
    const user = firebase.auth().currentUser;
    model.collection('sellers').doc(user.uid).get().then((doc) => {
      if (doc.exists) {
        const seller = doc.data();
        if (!seller.pipMode) seller.pipMode = 'OFF';
        this.setState({ seller });
      }
    });
    model.collection('shops').where('uid', '==', user.uid).get().then((results) => {
      if (results.empty) {
        return;
      }
      this.setState({ shop: results.docs[0].data() });
    });
  }

  onSelect = (section) => this.setState({ section });

  onSellerChange = (e) => {
    const seller = this.state.seller;
    seller[e.target.name] = e.target.value;
    this.setState({ seller });
  };

  onShopChange = (e) => {
    if (!e && !e.target && !e.target.name) {
      return;
    }
    const r = e.target.name.match(/^shop\.(.*)$/);
    if (!r && !r[1]) {
      return;
    }
    const shop = this.state.shop;
    shop[r[1]] = e.target.value;
    this.setState({ shop });
  };

  updateSeller = (e) => {
    e.preventDefault();
    const seller = this.state.seller;
    const shop = this.state.shop;
    let errors = [];
    if (!seller.name) {
      errors.push('事業者名が未入力です');
    }
    if (!shop.name) {
      errors.push('ショップ名が未入力です');
    }
    if (!seller.address) {
      errors.push('事業者住所が未入力です');
    }
    if (!seller.phone) {
      errors.push('事業者のご連絡先が未入力です');
    }
    if (!seller.ownerName) {
      errors.push('事業者の代表者名が未入力です');
    }
    if (!seller.url) {
      errors.push('事業者のURLが未入力です');
    }
    if (!errors.length) {
      const user = firebase.auth().currentUser;
      firebase.firestore().collection('sellers').doc(user.uid).set(seller);
      firebase.firestore().collection('shops').doc(shop.id).update(shop);
      const streamersRef =
        firebase.firestore().collection('shops').doc(shop.id).collection('streamers');
      streamersRef.where('email', '==', user.email).get().then((data) => {
        if (data.empty) {
          streamersRef.add({ email: user.email });
        }
      });
      const receptionistsRef =
        firebase.firestore().collection('shops').doc(shop.id).collection('receptionists');
      receptionistsRef.where('email', '==', user.email).get().then((data) => {
        if (data.empty) {
          receptionistsRef.add({ email: user.email });
        }
      });
      this.setState({ errors, success: '事業者情報を登録しました' });
    } else {
      this.setState({ errors, success: '' });
    }
  };

  onBannerChange = (files) => {
    const file = files[0];
    if (file) {
      const size = file.size / 1024
      if (size > 200) {
        alert('200KB以下の画像を選択してください。');
        return;
      }
      const name = `banner${moment().format('YYYYMMDDHHmmss')}`;
      firebase
        .storage()
        .ref()
        .child(`${firebase.auth().currentUser.uid}/${name}`)
        .put(file)
        .then((snapshot) =>
          snapshot.ref
            .getDownloadURL()
            .then((url) =>
              this.setState((prev) => ({ shop: { ...prev.shop, banner: url } }))
            )
        );
    } else {
      this.setState((prev) => ({ shop: { ...prev.shop, banner: null } }));
    }
  };
  onSpBannerChange = (files) => {
    const file = files[0];
    if (file) {
      const size = file.size / 1024
      if (size > 200) {
        alert('200KB以下の画像を選択してください。');
        return;
      }
      const name = `banner${moment().format('YYYYMMDDHHmmss')}`;
      firebase
        .storage()
        .ref()
        .child(`${firebase.auth().currentUser.uid}/${name}`)
        .put(file)
        .then((snapshot) =>
          snapshot.ref.getDownloadURL().then((url) =>
            this.setState((prev) => ({
              shop: { ...prev.shop, spBanner: url },
            }))
          )
        );
    } else {
      this.setState((prev) => ({ shop: { ...prev.shop, spBanner: null } }));
    }
  };

  removeBanner = () => this.setState((prev) => ({ shop: { ...prev.shop, banner: null } }));

  removeSpBanner = () => this.setState((prev) => ({ shop: { ...prev.shop, spBanner: null } }));

  setActiveClass = (section) => {
    return this.state.section === section ? "active" : "";
  };

  renderSeller() {
    const seller = this.state.seller;
    const shop = this.state.shop;
    return (
      <form className="form form-horizontal" onSubmit={this.updateSeller}>
        <p>こちらの情報はユーザーに公開されません。</p>
        <FormGroup controlId="name">
          <Col componentClass={ControlLabel} sm={4}>
            事業者名<div className="tagmust">必須</div>
          </Col>
          <Col sm={8}>
            <FormControl name="name" type="text" placeholder="株式会社" value={seller.name}
              onChange={this.onSellerChange} />
            <div className="formcaption">
              <p>
                貴社の会社名を入力します。
              </p>
            </div>
          </Col>
        </FormGroup>

        <FormGroup controlId="shop.name">
          <Col componentClass={ControlLabel} sm={4}>
            ショップ名<div className="tagmust">必須</div>
          </Col>
          <Col sm={8}>
            <FormControl name="shop.name" type="text" placeholder="ショップ名" value={shop.name}
              onChange={this.onShopChange} />
            <div className="formcaption">
              <p>
                貴社ショップを判別するために使用する名称です。<br />
                ライブ動画閲覧者には公開されませんが、貴社のショップと判別できる名称をご入力ください。
              </p>
            </div>
          </Col>
        </FormGroup>
        <FormGroup controlId="image">
          <Col componentClass={ControlLabel} className="displayBlock" sm={4}>
            バナー画像（PC）
            <div className="file-suggest-size">（200KB以下）</div>
          </Col>
          <Col sm={8} className="hiddenOverflow">
            <Dropzone onDrop={this.onBannerChange} accept={{ "image/*" : [] }}>
              {({ getRootProps, getInputProps }) => (
                <section className="dropzoneContainer">
                  <div className="dropzone" {...getRootProps()}>
                    <input {...getInputProps()} />
                    <p>ファイルアップロード</p>
                    <input
                      type="button"
                      value="ファイルを選択"
                      onChange={this.onBannerChange}
                    ></input>
                  </div>
                </section>
              )}
            </Dropzone>
            {shop.banner && (
              <ul>
                <li className="liveBanner">
                  <img src={shop.banner} alt="banner" width="200" />
                  <a href="#/" onClick={() => this.removeBanner()}>
                    <img src={closeIcon} alt="削除する" className="close" />
                  </a>
                </li>
              </ul>
            )}
          </Col>
        </FormGroup>
        <FormGroup controlId="image">
          <Col componentClass={ControlLabel} className="displayBlock" sm={4}>
            バナー画像（モバイル）
            <div className="file-suggest-size">（200KB以下）</div>
          </Col>
          <Col sm={8} className="hiddenOverflow">
            <Dropzone onDrop={this.onSpBannerChange} accept={{ "image/*" : [] }}>
              {({ getRootProps, getInputProps }) => (
                <section className="dropzoneContainer">
                  <div className="dropzone" {...getRootProps()}>
                    <input {...getInputProps()} />
                    <p>ファイルアップロード</p>
                    <input
                      type="button"
                      value="ファイルを選択"
                      onChange={this.onSpBannerChange}
                    ></input>
                  </div>
                </section>
              )}
            </Dropzone>
            {shop.spBanner && (
              <ul>
                <li className="liveBanner">
                  <img src={shop.spBanner} alt="banner" width="200" />
                  <a href="#/" onClick={() => this.removeSpBanner()}>
                    <img src={closeIcon} alt="削除する" className="close" />
                  </a>
                </li>
              </ul>
            )}
          </Col>
        </FormGroup>
        <FormGroup controlId="address">
          <Col componentClass={ControlLabel} sm={4}>
            事業者住所<div className="tagmust">必須</div>
          </Col>
          <Col sm={8}>
            <FormControl name="address" type="text" placeholder="住所"
              value={seller.address} onChange={this.onSellerChange} />
            <div className="formcaption">
              <p>
                貴社の住所を入力します。
              </p>
            </div>
          </Col>
        </FormGroup>

        <FormGroup controlId="phone">
          <Col componentClass={ControlLabel} sm={4}>
            事業者のご連絡先<div className="tagmust">必須</div>
          </Col>
          <Col sm={8}>
            <FormControl name="phone" type="text" placeholder="Tel" value={seller.phone}
              onChange={this.onSellerChange} />
            <div className="formcaption">
              <p>
                貴社の電話番号を入力します。
              </p>
            </div>
          </Col>
        </FormGroup>

        <FormGroup controlId="ownerName">
          <Col componentClass={ControlLabel} sm={4}>
            事業者の代表者名<div className="tagmust">必須</div>
          </Col>
          <Col sm={8}>
            <FormControl name="ownerName" type="text" placeholder="代表者名" value={seller.ownerName}
              onChange={this.onSellerChange} />
            <div className="formcaption"><p>貴社の代表者の氏名を入力します。</p></div>
          </Col>
        </FormGroup>

        <FormGroup controlId="url">
          <Col componentClass={ControlLabel} sm={4}>
            事業者のURL<div className="tagmust">必須</div>
          </Col>
          <Col sm={8}>
            <FormControl name="url" type="text" placeholder="URL"
              value={seller.url} onChange={this.onSellerChange} />
            <div className="formcaption"><p>貴社のウェブサイトのURLを入力します。</p></div>
          </Col>
        </FormGroup>

        <FormGroup controlId="liveUrl">
          <Col componentClass={ControlLabel} sm={4}>
          ライブ閲覧ページURL
          </Col>
          <Col sm={8}>
            <FormControl name="liveUrl" type="text" placeholder="ライブURL"
              value={seller.liveUrl} onChange={this.onSellerChange} />
            <div className="formcaption">
              <p>
                ライブ動画一覧表示機能の各動画がクリックされた場合のリンク先となるURLです。<br/>
                https から入力ください。httpsを省略すると正しく機能しません。<br/>
                こちらが空欄の場合は、ライブ動画一覧表示機能の各動画がクリックされた場合、同じURL内でLIVE IDのみ入れ替わり動画が再生されます。
              </p>
            </div>
          </Col>
        </FormGroup>

        {this.state.shop.pipModePermit === 'ON' && (
          <FormGroup controlId="pipMode">
            <Col componentClass={ControlLabel} sm={4}>
              PIP 機能<div className="tagmust">必須</div>
            </Col>
            <Col sm={8}>
              <Radio inline name="pipMode" value="ON" checked={seller.pipMode === 'ON'} onChange={this.onSellerChange}>ON</Radio>
              <Radio inline name="pipMode" value="OFF" checked={seller.pipMode === 'OFF'} onChange={this.onSellerChange}>OFF</Radio>
              <div className="formcaption">
                <p>
                  ライブ動画をポップアップウィンドウで再生する機能です。<br/>
                  他のページに遷移してもライブ動画の再生が継続されます。<br/>
                  同じページで別ドメインのページに遷移した場合、遷移先のページによっては正常に表示できない場合がございますので、その場合はこの機能を OFF にしてください。
                </p>
              </div>
            </Col>
          </FormGroup>
        )}

        <FormGroup>
          <Col smOffset={4} sm={8}>
            <Button type="submit" className="strongBtn mt10">登録する</Button>
          </Col>
        </FormGroup>

        <FormGroup>
          <Col smOffset={4} sm={8}>
            {this.state.success && <p className="success">{this.state.success}</p>}
            {this.state.errors.map((error, index) => <p key={index} className="error">{error}</p>)}
          </Col>
        </FormGroup>
      </form>
    );
  }


  render() {
    let section;
    if (this.state.section === 1) {
      section = this.renderSeller();
    }
    return (
      <div>
        <SellerNav permission={this.props.permission}/>
        <div className="content shop">
          <h2>ショップ情報</h2>
          <div className="card">
            {section}
          </div>
        </div>
      </div>
    );
  }
}
