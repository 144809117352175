import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';

import './App.css';
import firebase from './firebase';

export default class Live extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      user: null,
      error: '',
    };
  }

  componentDidMount() {
    this.unsub = firebase.auth().onAuthStateChanged((user) => this.setState({ user }));
  }

  componentWillUnmount() {
    this.unsub();
  }

  onChange = (e) => this.setState({ [e.target.name]: e.target.value });

  login = (e) => {
    e.preventDefault();
    firebase.auth().signInWithEmailAndPassword(this.state.email, this.state.password)
      .catch((error) => this.setState({ error: error.message }));
  };

  render() {
    const match = this.props.location.pathname.match(/^\/(receptionist|streamer)/);
    const label = match ? (match[1][0].toUpperCase() + match[1].slice(1)) : 'Seller';
    const root = match ? `/${match[1]}` : '/';
    if (this.state.user) {
      const { from } = this.props.location.state || { from: { pathname: root } };
      return <Redirect to={from} />;
    }
    return (
      <div className="loginContainer">
        <div className="AppTitleContainer">
          <h1 className="App-title">
            <svg width="25" height="21" xmlns="http://www.w3.org/2000/svg" className="App-logo">
              <path d="M24.432 4.864L21.798.615a1.296 1.296 0 0 0-1.1-.615H4.3c-.446 0-.864.234-1.1.615L.566 4.865c-1.36 2.194-.154 5.245 2.387 5.594a3.99 3.99 0 0 0 3.552-1.32A3.997 3.997 0 0 0 9.5 10.495a3.99 3.99 0 0 0 2.995-1.358 3.997 3.997 0 0 0 2.996 1.358 3.99 3.99 0 0 0 2.995-1.358 4.003 4.003 0 0 0 3.552 1.321c2.549-.345 3.758-3.396 2.394-5.595zm-2.942 6.949a5.1 5.1 0 0 1-1.198-.156v4.093H4.706v-4.093c-.39.09-.791.155-1.197.155a5.42 5.42 0 0 1-.73-.049 5.059 5.059 0 0 1-.667-.147v8.072c0 .725.58 1.312 1.3 1.312h18.183c.719 0 1.3-.587 1.3-1.313v-8.071c-.22.065-.44.119-.667.147-.247.033-.49.05-.738.05z" fill="#F8BA11" fillRule="nonzero"></path>
            </svg>
            TAGsAPI for {label}
          </h1>
        </div>
        <div className="cardMini">
          <div className="cardMiniContainer">
            <form onSubmit={this.login}>
              <div className="mb20 formUplabel">
                <label htmlFor="formHorizontalEmail" className="control-label label-up">メールアドレス</label>
                <input type="email" name="email" placeholder="example@example.com" id="formHorizontalEmail" className="form-control" onChange={this.onChange} />
              </div>
              <div className="formUplabel login_passwordArea">
                <label htmlFor="formHorizontalPassword" className="control-label label-up">パスワード</label>
                <input type="password" name="password" placeholder="パスワード" id="formHorizontalPassword" className="form-control" onChange={this.onChange} />
              </div>
              <button type="submit" className="strongBtn largeBtn">ログイン</button>
              <p className="error mt10">{this.state.error}</p>
            </form>
          </div>
        </div>
      </div>
    );
  }
}
