class Config {
  static CLOUD = 'https://us-central1-tag-fab.cloudfunctions.net/';
  static PRIVATE_BUCKET = 'gs://tagfab-shop';

  static FIREBASE_API_KEY = 'AIzaSyBCtudn16LPViN1QeY706MA_Q_qP_2xJ14';
  static FIREBASE_AUTH_DOMAIN = 'tag-fab.firebaseapp.com';
  static FIREBASE_DATABASE_URL = 'https://tag-fab.firebaseio.com';
  static FIREBASE_PROJECT_ID = 'tag-fab';
  static FIREBASE_STORAGE_BUCKET = 'tagfab';
  static FIREBASE_MESSAGING_SENDER_ID = '603472315266';

  static ALGOLIA_APP_ID = 'FE9D46L9K7';
  static ALGOLIA_API_KEY = '009a399da458e58ac598e7f9a89621ef';
  static ALGOLIA_INDEX_PREFIX = '';

  static SCENARIO_RECEPTIONIST = false;
};

export default Config;
