import React, { Component } from 'react';
import { Button, Nav, NavItem } from 'react-bootstrap';

import './App.css';
import SellerNav from './SellerNav';
import firebase from './firebase';
import model from './Model';

const db = firebase.firestore();

export default class Publish extends Component {
  constructor(props) {
    super(props);
    this.state = {
      shops: [],
    };
  }

  componentDidMount() {
    const uid = firebase.auth().currentUser.uid;
    this.offAdminShops = db.collection('shops').where('admin', '==', uid).onSnapshot((data) => {
      if (!data.empty) {
        this.setState({ shops: model.listData(data) });
      } else {
        this.offShops = db.collection('shops').where('uid', '==', uid).onSnapshot(
          (data) => this.setState({ shops: model.listData(data) }));
      }
    });
  }

  componentWillUnmount() {
    this.offAdminShops();
    if (this.offShops) {
      this.offShops();
    }
  }

  updateStatus = (shop) => db.collection('shops').doc(shop.id).update({ enabled: !shop.enabled });

  render = () => (
    <div>
      <SellerNav permission={this.props.permission}/>
      <div className="content">
        <h2>ショップを公開する</h2>
        <Nav bsStyle="pills">
          <NavItem href="/itemdata">一括登録</NavItem>
          <NavItem href="/items">商品一覧</NavItem>
        </Nav>

        {this.state.shops.length > 0 &&
        <div className="card">
          <p>ショップ情報登録と商品一覧を確認してから、ショップを公開にしてください。</p>
          <ul>
            <li>
              <b className="nameCell">ショップ名</b>
              <b className="nameCell">ID</b>
              <b className="cell">状態</b>
            </li>
            {this.state.shops.map((shop) => (
            <li key={shop.id}>
              <span className="nameCell">{shop.name}</span>
              <span className="nameCell">{shop.id}</span>
              <span className="nameCell">{shop.enabled ? '公開' : '非公開'}</span>
              <Button type="button" bsStyle={shop.enabled ? 'danger' : 'warning'}
                onClick={() => this.updateStatus(shop)}>
                {shop.enabled ? '非公開にする' : '公開にする'}
              </Button>
            </li>))}
          </ul>
        </div>}
      </div>
    </div>
  );
}
