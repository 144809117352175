import React from 'react';
import { Nav, NavItem } from 'react-bootstrap';

const AdminNav = () => (
  <Nav bsStyle="pills" className="navBar">
    <NavItem href="/admin/whitelist" className="navItem">
      <svg width="30" height="30" className="navIcon" xmlns="http://www.w3.org/2000/svg">
        <path d="M0,0h24v24H0V0z" fill="none"/>
        <path d="M12,7V3H2v18h20V7H12z M6,19H4v-2h2V19z M6,15H4v-2h2V15z M6,11H4V9h2V11z M6,7H4V5h2V7z M10,19H8v-2h2V19z M10,15H8v-2h2 V15z M10,11H8V9h2V11z M10,7H8V5h2V7z M20,19h-8v-2h2v-2h-2v-2h2v-2h-2V9h8V19z M18,11h-2v2h2V11z M18,15h-2v2h2V15z"/>
      </svg>
      Domain Whitelist
    </NavItem>
    <NavItem href="/" className="navItem">
      <svg width="36" height="30" className="navIcon" xmlns="http://www.w3.org/2000/svg">
        <path d="M35.183 6.95L31.389.878A1.87 1.87 0 0 0 29.805 0H6.192a1.87 1.87 0 0 0-1.584.879L.815 6.949c-1.958 3.135-.222 7.494 3.437 7.992.263.036.532.053.8.053 1.73 0 3.262-.762 4.314-1.94a5.765 5.765 0 0 0 8.627 0 5.765 5.765 0 0 0 8.627 0 5.784 5.784 0 0 0 5.114 1.887c3.67-.492 5.413-4.851 3.449-7.992zm-4.238 9.925a7.399 7.399 0 0 1-1.724-.223V22.5H6.777v-5.848c-.561.13-1.14.223-1.724.223-.351 0-.708-.023-1.053-.07a7.335 7.335 0 0 1-.958-.211v11.531A1.87 1.87 0 0 0 4.912 30h26.185a1.87 1.87 0 0 0 1.87-1.875V16.594c-.315.093-.63.17-.958.21-.357.048-.707.071-1.064.071z" fillRule="nonzero"/>
      </svg>
      TAGsAPI for Sellerへ
    </NavItem>
  </Nav>
);

export default AdminNav;
