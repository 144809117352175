import React, { Component } from 'react';
import {
  Col,
  Nav,
  NavItem,
  Row,
  Checkbox,
  DropdownButton, 
  MenuItem,
} from 'react-bootstrap';

import './App.css';
import SellerNav from './SellerNav';
import firebase from './firebase';
import model from './Model';

export const SCENARIO_MAX = 40;
const db = firebase.firestore();

export default class Scenarios extends Component {
  constructor(props) {
    super(props);
    this.state = {
      scenarios: [],
      shop: null,
      checkedItems: []
    };
  }

  componentDidMount = async () => {
    const uid = firebase.auth().currentUser.uid;
    db.collection('scenarios').where('uid', '==', uid).get().then(async(querySnapshot) => {
      const scenarios_ = querySnapshot.docs.map((documentSnapshot) => documentSnapshot.data());
      const scenarios = scenarios_.filter(scenario => !scenario.deletedAt).sort((a, b) => a.to.seconds - b.to.seconds);
      const shopsQuery = await db.collection('shops').where('uid', '==', uid).get();
      const shop = shopsQuery.empty ? null : shopsQuery.docs[0].data();
      this.setState({ scenarios, shop });
    });;
  };

  updateChecked = async (e, option) => {
    let label = null;
    switch (option) {
      case 'active': {
        label = '有効';
        break;
      }
      case 'inactive': {
        label = '無効';
        break;
      }
      default: {
        break;
      }
    }
    if (label) {
      if (!window.confirm(`選択されたライブを「${label}」に変更します。よろしいですか？`)) {
        return;
      }
    }
    let activeOption = {};
    if (option === 'active') {
      activeOption = {
        active: true
      };
    } else {
      activeOption = {
        active: false
      };
    }
    function updateScenarios(scenarios, scenarioId, activeOption) {
      return scenarios.map(scenario => {
        if (scenario.id === scenarioId) {
          return {...scenario, ...activeOption};
        }
        return scenario;
      });
    }
    for (var scenarioId of this.state.checkedItems) {
      await db.collection('scenarios').doc(scenarioId).update(activeOption);
      await model.fetch('updateScenarioCache', 'POST', { shopId: this.state.shop.id });
      const scenarios = updateScenarios(this.state.scenarios, scenarioId, activeOption)
      this.setState({ scenarios, checkedItems: [] });
    }
  }

  cloneChecked = (e) => {
    if (this.state.scenarios.length + this.state.checkedItems.length > SCENARIO_MAX) {
      window.alert('シナリオの登録上限は40シナリオです。コピーするシナリオの数をへらすか、既存のシナリオを削除してから新シナリオの作成・コピーを行って下さい')
      return
    }
    if (window.confirm('選択されたシナリオを複製します。よろしいですか？')) {
      this.state.checkedItems.map(async scenarioId => {
        const scenario = await db.collection('scenarios').doc(scenarioId).get();
        const scenarios = this.state.scenarios
        if (scenario.exists) {
          const doc = db.collection('scenarios').doc()
          const cloneScenario = {
            ...scenario.data(),
            id : doc.id,
            created: firebase.firestore.FieldValue.serverTimestamp(),
            updated: firebase.firestore.FieldValue.serverTimestamp(),
          }
          // コピーしたシナリオの名前はコピーをつける
          cloneScenario.name = cloneScenario.name + " " + "コピー"
          // コピーしたシナリオは有効にしない
          cloneScenario.active = false
          await doc.set(cloneScenario)
          scenarios.push(cloneScenario)
          scenarios.sort((a, b) => a.to.seconds - b.to.seconds) //新しく作ったシナリオも期間順に並ぶように並べ直す
        }
      this.setState({ scenarios, checkedItems: [] });
      await model.fetch('updateScenarioCache', 'POST', { shopId: this.state.shop.id });
      });
    }
  }

  removeChecked = (e) => {
    if (window.confirm('選択されたシナリオIDを削除します。よろしいですか？')) {
      this.state.checkedItems.map(async scenarioId => {
        await db.collection('scenarios').doc(scenarioId).update({
          deletedAt: firebase.firestore.FieldValue.serverTimestamp(),
          active: false,
        });
        await model.fetch('updateScenarioCache', 'POST', { shopId: this.state.shop.id });
        const scenarios = this.state.scenarios.filter(scenario => !this.state.checkedItems.includes(scenario.id));
        this.setState({ scenarios, checkedItems: [] });
      });
    }
  }

  onChangeCheckItem = (e) => {
    const val = e.target.value;
    let checkedItems = [...this.state.checkedItems];
    if (val === 'all') {
      if (e.target.checked) {
        checkedItems = this.state.scenarios.map(scenario => scenario.id);
      } else {
        checkedItems = [];
      }
    } else {
      if (checkedItems.includes(val)) {
        checkedItems.splice(checkedItems.indexOf(val), 1);
      } else {
        checkedItems.push(val);
      }
    }
    this.setState({ checkedItems });
  }

  newScenarioClick = (event) => {
    if (this.state.scenarios.length >= SCENARIO_MAX) {
      event.preventDefault()
      alert('シナリオの登録上限は40シナリオです。既存のシナリオを削除してから新シナリオの作成を行って下さい')
    }
  }

  render = () => (
    <div>
      <SellerNav permission={this.props.permission}/>
      <div className="content">
        <h2>シナリオ管理</h2>
        <Nav bsStyle="pills" className="detail-nav nav-m-bk">
          <NavItem href="/scenarios" active>
            <strong>シナリオ一覧</strong></NavItem>
          <NavItem href="/scenarios/new" onClick={this.newScenarioClick}>
            新しいシナリオ
          </NavItem>
          <NavItem href="/scenarios/trash">削除済みシナリオ</NavItem>
        </Nav>
        <div className="card">
          <Row className='scenario-item dtTablelist scenarios_listRow'>
            <Col xs={12} sm={1}>
              <span className="checkboxCell">
                <Checkbox className='delete nomargin' name="checkItem" value="all" onChange={this.onChangeCheckItem} checked={this.state.checkedItems.length === this.state.scenarios.length} />
              </span>
            </Col>
            <Col xs={12} sm={3} className="scenario-item-col scenarios_listItem scenario-head">
              <div>シナリオID｜シナリオ名｜期間</div>
            </Col>
            <Col xs={12} sm={3} className="scenario-head">ショップID｜ショップ名</Col>
            <Col xs={12} sm={3} className="scenario-head">実行するアクション</Col>
            <Col xs={12} sm={2}>
              <DropdownButton title="操作" disabled={!this.state.checkedItems.length} id="dropdown-button">
                <MenuItem onSelect={(e) => this.updateChecked(e, 'active')}>有効</MenuItem>
                <MenuItem onSelect={(e) => this.updateChecked(e, 'inactive')}>無効</MenuItem>
                <MenuItem onSelect={(e) => this.cloneChecked(e)}>コピー</MenuItem>
                <MenuItem onSelect={(e) => this.removeChecked(e)}>削除</MenuItem>
              </DropdownButton>
            </Col>
          </Row>
          {this.state.scenarios.map((s) => (
            <Row key={s.id} className="scenarios_listRow">
              <Col xs={12} sm={1}>
              <Checkbox className='delete' name="checkItem" value={s.id} onChange={this.onChangeCheckItem} checked={this.state.checkedItems.includes(s.id)}/>
              </Col>
              <Col xs={12} sm={3} className="scenarios_listItem">
                <div className="mb5">
                  {s.id}
                </div>
                <div className="mb5">
                  {s.name || 'シナリオ名を登録してください'}
                </div>
                <div className="mb5">
                {new Date(s.from.seconds * 1000).toLocaleDateString()}~{new Date(s.to.seconds * 1000).toLocaleDateString()}
                </div>
                <div className="mb20">
                  {s.active && (
                    <span className="tagmust">有効</span>
                  )}
                </div>
              </Col>
              <Col xs={12} sm={3} className="scenarios_listItem">
                <div className="mb5">
                  {s.shopId}
                </div>
                <div>
                  {s.shopName}
                </div>
              </Col>
              <Col xs={12} sm={3} className="scenarios_listItem">
                {s.action === 'live' && (
                  <React.Fragment>
                    <div className="mb5">
                      {s.liveType}
                    </div>
                    <div>
                      {s.liveName}
                    </div>
                  </React.Fragment>
                )}
                {s.action === '1on1' && (
                  <React.Fragment>
                    <div className="mb5">
                      レセプション
                    </div>
                  </React.Fragment>
                )}
              </Col>
              <Col xs={12} sm={2}>
                <a className="normalBtn inlineBtn" href={`/scenarios/edit/${s.id}`}>編集する</a>
              </Col>
            </Row>
          ))}
        </div>
      </div>
    </div>
  );
}
