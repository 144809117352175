import React, { Component } from 'react';
import { Button, Col, ControlLabel, FormGroup, FormControl } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faEye,
  faEyeSlash
} from '@fortawesome/free-solid-svg-icons';

import './App.css';
import firebase from './firebase';

const valdiatePassword = (password) => {
  const passwordRegExp = /^(?=.*?[a-z])(?=.*?\d)[a-z\d]{8,24}$/i
  return passwordRegExp.test(password)
}
export default class PasswordChange extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPassword: '',
      password: '',
      passwordConfirm: '',
      user: null,
      error: '',
      showPassword: false,
      showConfirmedPassword: false,
      showCurrentPassword: false
    };
  }

  componentDidMount() {
    this.unsub = firebase.auth().onAuthStateChanged((user) => this.setState({ user }));
  }

  componentWillUnmount() {
    this.unsub();
  }

  onChange = (e) => this.setState({ [e.target.name]: e.target.value });
  onChangeShowPassword = (name) => {
    this.setState({ [name]: !this.state[name] });
  };
  changePassword = async (event) => {
    event.preventDefault();
    const user = firebase.auth().currentUser;
    const credential = firebase.auth.EmailAuthProvider.credential(user.email, this.state.currentPassword);
    const { error } = await user.reauthenticateWithCredential(credential).catch(() => {
      this.setState({ error: '現在のパスワードが正しくありません' });
      return { error : '現在のパスワードが正しくありません' };
    });

    if (error) return;

    if (this.state.password !== this.state.passwordConfirm) {
      this.setState({ error: '新しいパスワードが一致しません' });
      return;
    }

    if (!valdiatePassword(this.state.password) || !valdiatePassword(this.state.passwordConfirm)) {
      this.setState({ error: 'パスワードは英数字混在、8文字以上24文字以内でご入力ください' });
      return;
    }
    await user.updatePassword(this.state.password)
      .then(() => {
        window.confirm('パスワードを変更しました。');
        this.setState({ error: ''});
        this.props.history.push("/");
      })
      .catch((e) => this.setState({ error: e.message }));
  };

  render() {
    return (
      <div className="content register">
        <div className="card">
        <form className="form form-horizontal" onSubmit={this.changePassword}>
        <FormGroup controlId="formHorizontalPassword">
            <Col componentClass={ControlLabel} sm={4}>現在のパスワード</Col>
            <Col sm={8} style={{ verticalAlign: 'mi'}}>
              <FormControl
                name="currentPassword"
                type={this.state.showCurrentPassword ? "plaintext" : "password"}
                placeholder="現在のパスワード"
                onChange={this.onChange}
                autoComplete="on"
              />
              <FormControl.Feedback>
                <span
                  name="showCurrentPassword"
                  onClick={() => this.onChangeShowPassword("showCurrentPassword")}
                  style={{ pointerEvents: 'initial', marginTop: '5px' }}
                >
                  <FontAwesomeIcon
                    icon={this.state.showCurrentPassword ? faEye : faEyeSlash}
                    style={{
                      fontSize: 20,
                    }}
                    name="showCurrentPassword"
                  />
                </span>
              </FormControl.Feedback>
            </Col>
          </FormGroup>

          <FormGroup controlId="formHorizontalPassword">
            <Col componentClass={ControlLabel} sm={4}>新しいパスワード</Col>
            <Col sm={8} style={{ verticalAlign: 'mi'}}>
              <FormControl
                name="password"
                type={this.state.showPassword ? "plaintext" : "password"}
                placeholder="新しいパスワード"
                onChange={this.onChange}
                autoComplete={"new-password"}
              />
              <FormControl.Feedback>
                <span
                  name="showPassword"
                  onClick={() => this.onChangeShowPassword("showPassword")}
                  style={{ pointerEvents: 'initial', marginTop: '5px' }}
                >
                  <FontAwesomeIcon
                    icon={this.state.showPassword ? faEye : faEyeSlash}
                    style={{
                      fontSize: 20,
                    }}
                    name="showPassword"
                  />
                </span>
              </FormControl.Feedback>
            </Col>
          </FormGroup>

          <FormGroup controlId="passwordConfirm">
            <Col componentClass={ControlLabel} sm={4}>新しいパスワード（確認）</Col>
            <Col sm={8}>
              <FormControl
                name="passwordConfirm"
                type={this.state.showConfirmedPassword ? "plaintext" : "password"}
                placeholder="新しいパスワード確認"
                onChange={this.onChange}
                autoComplete={"new-password"}
              />
              <FormControl.Feedback>
                <span
                  name="showConfirmedPassword"
                  onClick={() => this.onChangeShowPassword("showConfirmedPassword")}
                  style={{ pointerEvents: 'initial', marginTop: '5px' }}
                >
                  <FontAwesomeIcon
                    icon={this.state.showConfirmedPassword ? faEye : faEyeSlash}
                    style={{
                      fontSize: 20,
                    }}
                  />
                </span>
              </FormControl.Feedback>
            </Col>
          </FormGroup>

          <FormGroup>
            <Col smOffset={4} sm={8}>
              <Button type="submit" bsStyle="warning">登録する</Button>
            </Col>
          </FormGroup>

          <FormGroup>
            <Col smOffset={4} sm={8}>
              <p className="error">{this.state.error}</p>
            </Col>
          </FormGroup>
        </form>
        </div>
      </div>
    );
  }
}
