import React, { Component } from 'react';

import firebase from './firebase';

const db = firebase.firestore();

class TelopLayer extends Component {
  constructor(props) {
    super(props);
    this.nTelops = 100;
    this.state = {
      allTelopEvents: [],
      telopEvent: null,
    };
  }

  componentWillUnmount = () => {
    if (this.offTelopEvents) {
      this.offTelopEvents();
    }
  };

  fetchTelopEvents = () => {
    const query = db.doc(`lives/${this.props.live.id}`).collection('telop-events').orderBy('created', 'desc').limit(this.nTelops);
    if (this.props.session.live) {
      this.offTelopEvents = query.onSnapshot((data) => {
        return this.onTelopEvents(data.docs.reverse())
      });
    } else {
      query.get().then((data) => this.onTelopEvents(data.docs.reverse())).catch((error) => console.error(error));
    }
  };

  onTelopEvents = (docs) => {
    const started = (this.props.session.originalCreated || this.props.session.created).toMillis();
    const allTelopEvents = docs.map((doc) => {
      return doc.data();
    })
    .filter((telopEvent) => {
      const created = telopEvent.created.toMillis();
      return started <= created;
    });
    this.setState({ allTelopEvents });
  };

  onTimeUpdate = (currentTime) => {
    if (this.props.session.live ? !this.offTelopEvents : !this.state.allTelopEvents.length) {
      this.fetchTelopEvents();
    }
    const telopEvents = [...this.state.allTelopEvents].filter((telopEvent) => (
      telopEvent.created.toMillis() <= currentTime
    )).reverse();
    const telopEvent = telopEvents.length && telopEvents[0].display ? telopEvents[0] : null;
    if (!telopEvent) {
      this.setState({ telopEvent: null });
      return;
    }
    telopEvent.telop = this.props.live.telops.find((i) => i.id === telopEvent.telopId);
    if (!telopEvent.telop) {
      this.setState({ telopEvent: null });
      return;
    }
    this.setState({ telopEvent });
  };

  render = () => {
    const { telopEvent } = this.state;
    const styles = {
      telopLayer: {
        position: 'absolute',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
        width: '95.5%',
        height: this.props.showFullFlag ? '100%' : 'auto',
        top: 10,
      },
      telopImage: {
        borderRadius: 2,
        marginTop: 8,
        marginRight: 8,
        width: '42%',
        height: '42%', // for IE11 layout problem
      },
      telopFullImage: {
        width: this.props.width,
        height: this.props.height,
      },
    };
    return (
      <div style={styles.telopLayer}>
        {telopEvent && (
          <img
            alt="item"
            src={telopEvent.telop.image}
            style={this.props.showFullFlag ? styles.telopFullImage : styles.telopImage}
          />
        )}
      </div>
    );
  };
};

export default TelopLayer;
