import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import {
  Button,
  Checkbox,
  Col,
  Row,
  Form,
  FormGroup,
  FormControl,
} from "react-bootstrap";

import Cookies from "js-cookie";

import "./App.css";
import StreamerNav from "./StreamerNav";
import firebase from "./firebase";
import model from "./Model";
import moment from "moment";

const OT = window.OT;
const db = firebase.firestore();

const ENABLE_LIVE_STABILIZING_COUNTDOWN = false;
const ENABLE_SWITCH_CAMERAS_AND_MICROPHONES = false;
const ENABLE_TELOP_ITEM_ASYNCHRONOUS = true;
const ENABLE_EXIT_BUTTON = false;

const MAX_LIVE_STREAMING_SECONDS = 7200;
const MAX_LIVE_STABILIZING_SECONDS = ENABLE_LIVE_STABILIZING_COUNTDOWN
  ? 20
  : MAX_LIVE_STREAMING_SECONDS;
const MAX_CTA_ITEMS = 4;

export default class Live extends Component {
  constructor(props) {
    super(props);
    this.nComments = 20;
    this.tagfabPubTimer = null;
    this.state = {
      isOffline: null,
      isLoading: false,
      isPublishing: false,
      isUnpublishing: false,
      isLive: false,
      hasLive: false,
      isTest: false,
      name: "",
      datetime: null,
      rotated: true,
      archiveId: null,
      previewStreamerName: "",
      previewStreamerText: "",
      streamerComments: [],
      viewerComments: [],
      shopIndex: 0,
      shops: [],
      items: [],
      liveTelops: [],
      liveItems: [],
      hardwareSetup: null,
      audioDeviceId: null,
      videoDeviceId: null,
      views: 0,
      likes: 0,
      liveId: null,
      showTelop: false,
      showFullTelop: false,
      ctaButton: false,
      enabled: false,
      redirect: false,
      resolution: "1920x1080",
      intervalId: null,
      manualStabilization: true, // Set to true for the time being
      stabilizationStartAt: null,
      stabilizationEndAt: null,
      stabilizing: MAX_LIVE_STABILIZING_SECONDS,
      remaining: MAX_LIVE_STREAMING_SECONDS,
      awaitedStabilizingTime: false,
      isCancelled: false,
      isToggling: false,
      otherBrowserInLive: false, //他のブラウザ・端末で配信中フラグ
      inLiveSessionCreated: firebase.firestore.Timestamp.fromDate(new Date()),
      surveyMode: false,
      surveyQuestions: [],
      surveyTitles: [],
      surveyOptions: [],
      showResult: [],
      showSurvey1: false,
      showSurvey2: false,
      showSurvey3: false,
      showSurveyNumber: null,
      showResultNumber1: null,
      showResultNumber2: null,
      showResultNumber3: null,
      isShowSurvey: null,
      selected: null,
      isMinimized: true,
      optionWidths: [],
      optionPercentages: [], // New state to hold percentages
      redirect: false,
    };
  }

  componentDidMount() {
    this._isMounted = true;

    OT.getDevices((error, devices) => {
      if (error) {
        console.error(error);
      } else {
        this.setState({
          audioInputDevices: devices.filter(
            (device) => device.kind === "audioInput"
          ),
          videoInputDevices: devices.filter(
            (device) => device.kind === "videoInput"
          ),
        });
      }
    });
    this.setupHardwareDevice();
    this.startCheckConnectionState();

    const uid = firebase.auth().currentUser.uid;
    const liveId = this.props.match.params.id;
    if (liveId) {
      this.subscribeToSurveyResults(liveId);
      if (ENABLE_TELOP_ITEM_ASYNCHRONOUS) {
        model
          .collection("lives")
          .doc(liveId)
          .onSnapshot(async (liveSnapshot) => {
            if (!liveSnapshot.exists) {
              return;
            }
            const live = liveSnapshot.data();
            this.setState({ surveyMode: live.surveyMode }); // surveyModeをstateに格納

            let liveItems = await this.setItemCode(live.items || []);
            liveItems = liveItems.map((liveItem) => {
              const item = this.state.liveItems.find(
                (v) => v.id === liveItem.id
              );
              return item
                ? {
                    ...liveItem,
                    cta: item.cta,
                    toggledAt: item.toggledAt,
                  }
                : {
                    ...liveItem,
                    cta: false,
                    toggledAt: null,
                  };
            });

            //live中に商品、テロップが削除された場合、そのテロップ・CTAをオフにする
            if (this.state.isLive && !this.state.otherBrowserInLive) {
              this.state.liveItems.forEach((item) => {
                const ctaOffTarget = liveItems.find((v) => v.id === item.id);
                if (!ctaOffTarget && item.cta) {
                  db.doc(`lives/${this.state.liveId}`)
                    .collection("cta-events")
                    .add({
                      created: firebase.firestore.FieldValue.serverTimestamp(),
                      display: false,
                      itemId: item.id,
                      uid: firebase.auth().currentUser.uid,
                    });
                }
              });
            }
            let liveTelops = live.telops || [];
            liveTelops = liveTelops.map((telop) => {
              const liveTelop = this.state.liveTelops.find(
                (v) => v.id === telop.id
              );
              return liveTelop
                ? {
                    ...telop,
                    display: liveTelop.display,
                  }
                : {
                    ...telop,
                    display: false,
                  };
            });
            this.setState({
              liveItems,
              liveTelops,
            });
          });
      }

      model
        .collection("live_statuses")
        .doc(liveId)
        .onSnapshot((doc) => {
          if (!doc.exists) {
            return;
          }
          const data = doc.data();
          this.setState({ views: data.views });
          if (model.ENABLE_LIKE) {
            this.setState({ likes: data.likes });
          }
        });

      model
        .collection("sessions")
        .where("liveId", "==", liveId)
        .orderBy("created", "desc")
        .limit(1)
        .onSnapshot(async (liveSnapshot) => {
          if (liveSnapshot.empty) {
            return;
          }
          const data = liveSnapshot.docs[0].data();
          this.setState({ hasLive: data.live });

          // 新しいセッションに紐づくデータを監視する
          const sessionCreated = data.created;
          const telopEventsQuery = model
            .collection(`lives/${liveId}/telop-events`)
            .where("created", ">", sessionCreated)
            .orderBy("created", "desc")
            .limit(1);
          if (this.offTelopEvents) this.offTelopEvents();
          this.offTelopEvents = telopEventsQuery.onSnapshot((querySnapshot) => {
            const event = querySnapshot.empty
              ? null
              : querySnapshot.docs[0].data();
            const liveTelops = this.state.liveTelops.map((telop) =>
              event
                ? {
                    ...telop,
                    display: telop.id === event.telopId && event.display,
                  }
                : { ...telop, display: false }
            );
            this.setState({ liveTelops });
          });
          const ctaEventsQuery = model
            .collection(`lives/${liveId}/cta-events`)
            .where("created", ">", sessionCreated)
            .orderBy("created", "desc")
            .limit(100);
          if (this.offCtaEventEvents) this.offCtaEventEvents();
          this.offCtaEvents = ctaEventsQuery.onSnapshot((querySnapshot) => {
            const events = Array.from(
              querySnapshot.docs
                .map((doc) => doc.data())
                .reverse()
                .filter((event) => event.created > sessionCreated)
                .reduce(
                  (previous, current) => previous.set(current.itemId, current),
                  new Map()
                )
                .values()
            );
            const liveItems = this.state.liveItems.map((item) => {
              const event = events.find((v) => v.itemId === item.id);
              return event
                ? {
                    ...item,
                    cta: event.display,
                    toggledAt: event.created,
                  }
                : {
                    ...item,
                    cta: false,
                    toggledAt: sessionCreated,
                  };
            });
            this.setState({ liveItems });
          });
          const streamerCommentsQuery = model
            .collection(`lives/${liveId}/streamer-comment-events`)
            .where("created", ">", sessionCreated)
            .orderBy("created", "desc")
            .limit(this.nComments);
          if (this.offStreamerComments) this.offStreamerComments();
          this.offStreamerComments = streamerCommentsQuery.onSnapshot(
            (querySnapshot) => {
              const comments = querySnapshot.docs.map(
                (queryDocumentSnapshot) => {
                  return {
                    ...queryDocumentSnapshot.data(),
                    isStreamerComment: true,
                  };
                }
              );
              this.setState({ streamerComments: comments });
            }
          );
          const viewerCommentsQuery = model
            .collection(`lives/${liveId}/viewer-comment-events`)
            .where("created", ">", sessionCreated)
            .orderBy("created", "desc")
            .limit(this.nComments);
          if (this.offViewerComments) this.offViewerComments();
          this.offViewerComments = viewerCommentsQuery.onSnapshot(
            (querySnapshot) => {
              const comments = querySnapshot.docs.map(
                (queryDocumentSnapshot) => {
                  return {
                    ...queryDocumentSnapshot.data(),
                    isStreamerComment: false,
                  };
                }
              );
              this.setState({ viewerComments: comments });
            }
          );

          // 最新のセッションのliveがtrue（配信中） かつ　最新のセッションのsessionIdが表示しているブラウザのsessionIdでないときに、他のブラウザで配信中である。
          if (data.live && data.sessionId !== this.sessionId) {
            this.setState({
              otherBrowserInLive: true,
              inLiveSessionCreated: data.created,
            });
          }
        });

      model
        .collection("lives")
        .doc(liveId)
        .get()
        .then(async (doc) => {
          if (doc.exists) {
            const preview = doc.data();
            if (preview.uid === uid) {
              this.setState({
                liveId,
                name: preview.name,
                datetime: preview.datetime,
                previewStreamerName: preview.previewStreamerName,
                previewStreamerText: preview.previewStreamerText,
                showTelop: preview.showTelop,
                showFullTelop: preview.showFullTelop,
                ctaButton: preview.ctaButton,
                enabled: preview.enabled,
                isTest: preview.isTest,
                redirect: false,
                surveyTitle1: preview.surveyTitle1 || "",
                surveyQuestion1: preview.surveyQuestion1 || "",
                surveyOption1_1: preview.surveyOption1_1 || "",
                surveyOption1_2: preview.surveyOption1_2 || "",
                surveyOption1_3: preview.surveyOption1_3 || "",
                surveyOption1_4: preview.surveyOption1_4 || "",
                surveyTitle2: preview.surveyTitle2 || "",
                surveyQuestion2: preview.surveyQuestion2 || "",
                surveyOption2_1: preview.surveyOption2_1 || "",
                surveyOption2_2: preview.surveyOption2_2 || "",
                surveyOption2_3: preview.surveyOption2_3 || "",
                surveyOption2_4: preview.surveyOption2_4 || "",
                surveyTitle3: preview.surveyTitle3 || "",
                surveyQuestion3: preview.surveyQuestion3 || "",
                surveyOption3_1: preview.surveyOption3_1 || "",
                surveyOption3_2: preview.surveyOption3_2 || "",
                surveyOption3_3: preview.surveyOption3_3 || "",
                surveyOption3_4: preview.surveyOption3_4 || "",
                showSurveyNumber: preview.showSurveyNumber,
                showResultNumber1: preview.showResultNumber1,
                showResultNumber2: preview.showResultNumber2,
                showResultNumber3: preview.showResultNumber3,
              });
              if (!ENABLE_TELOP_ITEM_ASYNCHRONOUS) {
                const liveItems = await this.setItemCode(preview.items || []);
                this.setState({
                  liveTelops: preview.telops || [],
                  liveItems,
                });
              }
              if (preview.shopId) {
                model
                  .collection("shops")
                  .doc(preview.shopId)
                  .get()
                  .then((doc) =>
                    model
                      .collection("items")
                      .where("shopId", "==", preview.shopId)
                      .get()
                      .then((itemData) =>
                        this.setState({
                          shops: [doc.data()],
                          items: model.listData(itemData),
                        })
                      )
                  );
              }
            } else {
              window.alert("ライブ予約をしてからご利用ください。");
              this.setState({ redirect: true });
            }
          } else {
            window.alert("ライブ予約をしてからご利用ください。");
            this.setState({ redirect: true });
          }
        });
    }

    window.addEventListener("beforeunload", this.confirmUnload);
    window.addEventListener("unload", this.unpublish);
  }

  componentWillUnmount() {
    this._isMounted = false;
    this.stopCheckConnectionState();

    window.removeEventListener("beforeunload", this.confirmUnload);
    window.removeEventListener("unload", this.unpublish);
  }

  setItemCode(liveItems) {
    return new Promise(async (resolve) => {
      const itemDocs = await Promise.all(
        liveItems.map((item) =>
          db
            .collection("items")
            .doc(item.id)
            .get()
        )
      );
      itemDocs.forEach((doc, index) => {
        liveItems[index].code = doc.exists ? doc.data().code : null;
      });
      resolve([...liveItems.filter((item) => item.code)]);
    });
  }

  setupHardwareDevice() {
    if (this.state.hardwareSetup) {
      return;
    }
    const element = document.querySelector("#hardware-setup");
    const hardwareSetup = window.createOpentokHardwareSetupComponent(
      element,
      {
        insertMode: "append",
      },
      (error) => {
        if (error) {
          console.error("Error: ", error);
          document.querySelector("#hardware-setup").innerHTML =
            "<strong>エラー：デバイス選択できません</strong>: " + error.message;
        }
      }
    );
    this.setState({ hardwareSetup });
  }

  startCheckConnectionState() {
    this.stopCheckConnectionState();
    this.checkConnectionStateId = setInterval(
      () => this.checkConnectionState(),
      1000
    );
  }

  stopCheckConnectionState() {
    if (this.checkConnectionStateId) {
      clearInterval(this.checkConnectionStateId);
    }
  }

  checkConnectionState() {
    firebase
      .database()
      .ref(".info/connected")
      .on("value", (snap) => {
        if (this.state.isOffline === null) {
          this.setState({ isOffline: false });
        } else {
          this.setState({ isOffline: !snap.val() });
        }
      });
  }

  startCountdown() {
    this.stopCountdown();
    this.setState({
      intervalId: setInterval(() => this.tickCountdown(), 1000),
    });
  }

  stopCountdown() {
    const { intervalId } = this.state;
    if (!intervalId) {
      return;
    }

    clearInterval(intervalId);
    this.setState({
      intervalId: null,
      stabilizing: MAX_LIVE_STABILIZING_SECONDS,
      remaining: MAX_LIVE_STREAMING_SECONDS,
    });
  }

  tickCountdown() {
    let { manualStabilization, stabilizing, remaining } = this.state;
    if (0 < stabilizing && !manualStabilization) {
      --stabilizing;
    }
    if (0 < remaining && 0 === --remaining) {
      this.stopCountdown();
      this.unpublish();
      window.alert("ライブ配信は終了しました");
      this.setupHardwareDevice();
    } else {
      const stabilizationEndAt =
        this.state.stabilizationEndAt ||
        (0 < stabilizing ? null : new Date().getTime());
      if (!!stabilizationEndAt && !this.state.stabilizationEndAt) {
        this.sessionRef.update({
          stabilized: firebase.firestore.FieldValue.serverTimestamp(),
        });
      }
      this.setState({
        stabilizationEndAt,
        stabilizing,
        remaining,
      });
    }
  }

  getCountdownText() {
    const { remaining } = this.state;
    const h = Math.floor(remaining / 3600);
    const m = Math.floor((remaining % 3600) / 60);
    const s = Math.floor(remaining % 60);
    return (
      (remaining >= 3600 ? `${h}時間` : "") +
      (remaining >= 60 ? `${m}分` : "") +
      `${s}秒`
    );
  }

  getElapsedTimeText() {
    const { remaining } = this.state; 
    if(!this.state.stabilizationEndAt){
      return null
    }
    const stabilizationTime =
        (this.state.stabilizationEndAt - this.state.stabilizationStartAt) / 1000.0;
    const elapsed = 7200 - remaining - stabilizationTime
    const h = Math.floor(elapsed / 3600);
    const m = Math.floor((elapsed % 3600) / 60);
    const s = Math.floor(elapsed % 60);
    
    const formattedH = h > 0 ? `${String(h).padStart(2, '0')}:` : "";
    const formattedM = `${String(m).padStart(2, '0')}`;
    const formattedS = `${String(s).padStart(2, '0')}`;

    return `${formattedH}${formattedM}:${formattedS}`;
  }

  isRemainingTimeShort() {
    const { remaining } = this.state;
    return remaining < 60;
  }

  getVideoContentHint() {
    const queryStrings = window.location.search.split("=");
    if (queryStrings[0].includes("hint")) {
      return queryStrings[1];
    } else {
      return "";
    }
  }

  publish() {
    const state = this.state;
    if (!state.name || state.shops.length === 0) {
      alert("まずライブ情報を設定してください。");
      return;
    }
    this.setState({ isLoading: true, awaitedStabilizingTime: false });
    const _this = this;
    model
      .fetch("publishSession")
      .then((resp) => resp.json())
      .then((data) => {
        _this.sessionId = data.sessionId;
        const props = { connectionEventsSuppressed: true };
        _this.session = OT.initSession(data.apiKey, _this.sessionId, props);

        const audioSource = _this.state.hardwareSetup.audioSource();
        const videoSource = _this.state.hardwareSetup.videoSource();

        // Create a publisher
        _this.publisher = OT.initPublisher(
          "publisher",
          {
            insertMode: "append",
            height: state.rotated ? 405 : 720,
            width: state.rotated ? 720 : 405,
            resolution: state.resolution,
            frameRate: 30,
            audioFallbackEnabled: false,
            publishAudio: true,
            publishVideo: true,
            mirror: false,
            audioSource,
            videoSource,
            style: { buttonDisplayMode: state.rotated ? "off" : "auto" },
            videoContentHint: this.getVideoContentHint(),
          },
          (error) => {
            if (error) {
              console.error(error);
            } else {
              _this.publisher.on("streamDestroyed", (event) => {
                console.error(event);
                if (event.reason === "networkDisconnected") {
                  this.unpublish();
                  window.alert(
                    "ネットワークの切断によりライブ配信が終了しました"
                  );
                  this.setupHardwareDevice();
                }
              });
            }
          }
        );
        _this.state.hardwareSetup.destroy();
        _this.setState({
          hardwareSetup: null,
          audioDeviceId: audioSource.deviceId,
          videoDeviceId: videoSource.deviceId,
        });

        // Connect to the session
        _this.session.connect(data.token, (error) => {
          // If the connection is successful, publish to the session
          if (error) {
            console.error(error);
          } else {
            _this.session.publish(_this.publisher, (error) => {
              if (error) {
                console.error(error);
              } else {
                _this.setState({ isPublishing: true, isLoading: false });
              }
            });
          }
        });
      });
  }

  confirmUnload = (event) => {
    if (this.state.isLive) {
      event.preventDefault();
      event.returnValue = "ライブ配信が終了します。よろしいですか？";
    }
  };

  unpublish = async () => {
    if (this.session && this.publisher && this.sessionId) {
      if (this.state.isLive) {
        if (this.tagfabPubTimer) {
          clearInterval(this.tagfabPubTimer);
          this.tagfabPubTimer = null;
        }
        Cookies.remove("tagfabPub");
        await model
          .collection("sessions")
          .doc(this.sessionId)
          .update({ live: false });
        await new Promise((resolve, reject) => {
          // NOTE: `GET /archiveStop` returns 500 when called multiply after initial success.
          //       This potentially cause infinite loops without handling.
          model
            .fetch(`archiveStop?archiveId=${this.state.archiveId}`)
            .then(() => resolve())
            .catch((e) => (e.response.status === 500 ? resolve() : reject(e)));
        });
        await model.fetch("broadcastStop", "POST", {
          sessionId: this.sessionId,
        });
        if (this.offTelopEvents) {
          this.offTelopEvents();
          this.offTelopEvents = null;
        }
        if (this.offCtaEventEvents) {
          this.offCtaEventEvents();
          this.offCtaEventEvents = null;
        }
        if (this.offStreamerComments) {
          this.offStreamerComments();
          this.offStreamerComments = null;
        }
        if (this.offViewerComments) {
          this.offViewerComments();
          this.offViewerComments = null;
        }
        if (this.offLiveStatus) {
          this.offLiveStatus();
          this.offLiveStatus = null;
        }
      }
      this.session.unpublish(this.publisher);
      this.session.disconnect();
      this.stopCountdown();
      this.setState({ isPublishing: false, isLive: false });
    }
  };

  unpublishWithErrorHandling = () => {
    this.setState({ isUnpublishing: true });
    return this.unpublish()
      .then(() => {
        this.setState({ isUnpublishing: false });
        return true;
      })
      .catch(() => {
        if (
          window.confirm("ライブ配信を終了できませんでした。再度終了しますか？")
        ) {
          return this.unpublishWithErrorHandling();
        } else {
          this.setState({ isUnpublishing: false });
          return false;
        }
      });
  };

  onClickButton = () => {
    if (this.state.isPublishing) {
      if (this.state.manualStabilization && 0 < this.state.stabilizing) {
        const stabilizationStartAt = this.state.stabilizationStartAt;
        const stabilizationEndAt = new Date().getTime();
        const stabilizationTime =
          (stabilizationEndAt - stabilizationStartAt) / 1000.0 + 1;
        this.setState({
          stabilizationEndAt,
          stabilizing: 0,
        });
        this.sessionRef.update({
          stabilizationTime,
          stabilized: firebase.firestore.FieldValue.serverTimestamp(),
        });
      } else {
        if (window.confirm("ライブ配信が終了します。よろしいですか？")) {
          this.unpublishWithErrorHandling().then((succeeded) => {
            if (succeeded) {
              window.alert("ライブ配信を終了しました。");
              this.setupHardwareDevice();
            }
          });
        }
      }
    } else {
      this.publish();
    }
  };

  onClickVideoInputDevice = (device) => {
    const publishers = OT.publishers.map((publisher) => publisher);
    publishers[0].setVideoSource(device.deviceId);
    this.setState({ videoDeviceId: device.deviceId });
  };

  onClickAudioInputDevice = (device) => {
    const publishers = OT.publishers.map((publisher) => publisher);
    publishers[0].setAudioSource(device.deviceId);
    this.setState({ audioDeviceId: device.deviceId });
  };

  onClickStartPreview = () => {
    if (this.state.isOffline) {
      window.alert("ネットワークの切断によりプレビューできません。");
      return;
    }
    this.publish();
  };

  onClickEndPreview = () => {
    this.unpublish();
    this.setupHardwareDevice();
  };

  onClickStart = async () => {
    const query = await db
      .collection("sessions")
      .where("liveId", "==", this.state.liveId)
      .where("stabilized", "!=", null)
      .orderBy("stabilized", "desc")
      .orderBy("created", "desc")
      .limit(1)
      .get();
    if (!query.empty) {
      const session = query.docs[0].data();
      const message = session.broadcastId
        ? "配信済みのライブIDを選択中ですが、再配信を行いますか？"
        : "アップロード済みのライブIDを選択中ですが、再配信を行いますか？";
      if (!window.confirm(message)) {
        return;
      }
    }
    if (this.state.isOffline) {
      window.alert("ネットワークの切断により準備を開始できません。");
      return;
    }
    const videoElement = document.getElementsByClassName("OT_video-element")[0];
    if (
      videoElement &&
      videoElement.videoWidth / videoElement.videoHeight !== 16 / 9
    ) {
      window.alert(
        "お使いのPCまたはカメラの設定の影響により規定のサイズでの動画配信が開始できません。カメラを利用しているTAGsAPI以外のアプリの停止、ブラウザの再起動または、PCの再起動をお試しいただき、再度配信を開始してください。"
      );
      this.onClickEndPreview();
      return;
    }
    const baseDate = moment(this.state.datetime.toDate()).subtract(1, "hours");
    if (moment().isSameOrAfter(baseDate, "minute")) {
      if (!this.tagfabPubTimer) {
        this.tagfabPubTimer = setInterval(
          () => Cookies.set("tagfabPub", new Date().getTime()),
          1000
        );
      }
      this.startBroadcast();
    } else {
      if (
        window.confirm("配信予定日は本日ではありません。配信画面へ進みますか？")
      )
        this.startBroadcast();
    }
  };

  onClickCancel = async () => {
    this.setState({
      isLoading: true,
      isCancelled: true,
    });
    await this.unpublish();
    this.setState({
      isLoading: false,
      isCancelled: false,
      archiveId: null,
      stabilizationTime: null,
      stabilizationStartAt: null,
      stabilizationEndAt: null,
    });
    this.setupHardwareDevice();
  };

  startBroadcast = () => {
    this.setState({
      isLoading: true,
      streamerComments: [],
      viewerComments: [],
    });
    // We need to wait 20 seconds to stabilize image quality.
    setTimeout(() => this.setState({ awaitedStabilizingTime: true }), 20000);
    this.resetTelop();
    this.resetItemCTA();
    const state = this.state;
    const enabled =
      Boolean(state.enabled) && Boolean(state.shops[state.shopIndex].enabled);
    if (model.ENABLE_LIKE)
      model
        .collection("lives")
        .doc(state.liveId)
        .update({ likes: 0 });
    model
      .fetch("archiveStart", "POST", {
        sessionId: this.sessionId,
        name: state.name,
      })
      .then((resp) => resp.json())
      .then((data) => {
        this.sessionRef = model.collection("sessions").doc(this.sessionId);
        this.liveRef = model.collection("lives").doc(state.liveId);
        this.liveStatusRef = model
          .collection("live_statuses")
          .doc(state.liveId);
        this.liveStatusRef.set({
          likes: 0,
          views: 0,
        });
        Promise.all(
          state.liveItems.map((item) =>
            db
              .collection("units")
              .where("itemId", "==", item.id)
              .get()
          )
        ).then((unitsQueries) => {
          const session = {
            uid: firebase.auth().currentUser.uid,
            sessionId: this.sessionId,
            archiveId: data.id,
            name: state.name,
            rotated: state.rotated,
            resolution: state.resolution,
            manualStabilization: state.manualStabilization,
            stabilizationTime: MAX_LIVE_STABILIZING_SECONDS,
            live: true,
            created: firebase.firestore.FieldValue.serverTimestamp(),
            shopId: state.shops[state.shopIndex].id,
            shopName: state.shops[state.shopIndex].name,
            domain: state.shops[state.shopIndex].domain || null,
            liveId: state.liveId,
            isTest: state.isTest,
            enabled: enabled,
            telops: state.liveTelops,
            items: state.liveItems,
            units: unitsQueries.reduce(
              (previous, current) => [
                ...previous,
                ...current.docs.map((doc) => {
                  const unit = doc.data();
                  return {
                    code: unit.code,
                    name: unit.name,
                    image: unit.image,
                    itemId: unit.itemId,
                  };
                }),
              ],
              []
            ),
          };
          this.sessionRef.set(session).then(() => {
            model.fetch("broadcastStart", "POST", {
              sessionId: this.sessionId,
              resolution: state.resolution,
            });
          });
          this.setState({
            isLive: true,
            archiveId: data.id,
            isLoading: false,
            stabilizationTime: this.state.manualStabilization
              ? null
              : MAX_LIVE_STABILIZING_SECONDS,
            stabilizationStartAt: new Date().getTime(),
            stabilizationEndAt: null,
          });
          this.startCountdown();
        });
      });
  };

  onClickStartLive = () => {
    if (this.state.manualStabilization && 0 < this.state.stabilizing) {
      const stabilizationStartAt = this.state.stabilizationStartAt;
      const stabilizationEndAt = new Date().getTime();
      const stabilizationTime =
        (stabilizationEndAt - stabilizationStartAt) / 1000.0 + 1;

      this.setState({
        stabilizationEndAt,
        stabilizing: 0,
      });
      if (this.state.previewStreamerName && this.state.previewStreamerText) {
        const streamerCommentEventRef = this.liveRef
          .collection("streamer-comment-events")
          .doc();
        streamerCommentEventRef.set({
          id: streamerCommentEventRef.id,
          uid: firebase.auth().currentUser.uid,
          name: this.state.previewStreamerName,
          text: this.state.previewStreamerText,
          created: firebase.firestore.FieldValue.serverTimestamp(),
        });
      }
      this.sessionRef.update({
        stabilizationTime,
        stabilized: firebase.firestore.FieldValue.serverTimestamp(),
      });
    }
  };

  onClickEndLive = () => {
    if (window.confirm("ライブ配信が終了します。よろしいですか？")) {
      this.unpublish();
      this.setupHardwareDevice();
    }
  };

  onChange = (e) => this.setState({ [e.target.name]: e.target.value });

  onChangeRotated = (e) => this.setState({ rotated: e.target.checked });

  onChangeManualStabilization = (e) =>
    this.setState({ manualStabilization: e.target.checked });

  toggleTelop = async (telop) => {
    if (this.state.isToggling) return;
    this.setState({ isToggling: true });

    const { liveTelops } = this.state;
    const target = liveTelops.find((v) => v.id === telop.id);
    if (target) {
      target.display = !telop.display;
      if (target.display) {
        liveTelops.forEach((telop) => (telop.display = target.id === telop.id));
      }
      this.setState({ liveTelops });
    }
    await db
      .doc(`lives/${this.state.liveId}`)
      .collection("telop-events")
      .add({
        created: firebase.firestore.FieldValue.serverTimestamp(),
        display: telop.display,
        telopId: telop.id,
        uid: firebase.auth().currentUser.uid,
      });

    this.setState({ isToggling: false });
  };

  resetTelop = () => {
    const { liveTelops } = this.state;
    liveTelops.forEach((i) => (i.display = false));
    this.setState({ liveTelops });
  };

  toggleItemCTA = async (item) => {
    if (this.state.isToggling) return;
    this.setState({ isToggling: true });

    const _liveItems = this.state.liveItems;
    const _ctaOnLiveItems = _liveItems.filter((liveItem) => liveItem.cta);
    if (!item.cta && MAX_CTA_ITEMS <= _ctaOnLiveItems.length) {
      const ctaOffTarget = _ctaOnLiveItems
        .sort((a, b) => {
          return a.toggledAt > b.toggledAt ? 1 : -1;
        })
        .shift();
      await db
        .doc(`lives/${this.state.liveId}`)
        .collection("cta-events")
        .add({
          created: firebase.firestore.FieldValue.serverTimestamp(),
          display: false,
          itemId: ctaOffTarget.id,
          uid: firebase.auth().currentUser.uid,
        });
    }

    await db
      .doc(`lives/${this.state.liveId}`)
      .collection("cta-events")
      .add({
        created: firebase.firestore.FieldValue.serverTimestamp(),
        display: !item.cta,
        itemId: item.id,
        uid: firebase.auth().currentUser.uid,
      });

    this.setState({ isToggling: false });
  };

  resetItemCTA = () => {
    const { liveItems } = this.state;
    liveItems.forEach((i) => {
      i.cta = false;
      i.toggledAt = null;
    });
    this.setState({ liveItems });
  };

  getSurveyInfo = async () => {
    try {
      this.liveDocSnapshot = db
        .doc(`lives/${this.props.live.id}`)
        .onSnapshot((doc) => {
          if (doc.exists) {
            const data = doc.data();
            if (this._isMounted) {
              this.setState(
                {
                  surveyTitles: [
                    data.surveyTitle1,
                    data.surveyTitle2,
                    data.surveyTitle3,
                  ],
                  surveyQuestions: [
                    data.surveyQuestion1,
                    data.surveyQuestion2,
                    data.surveyQuestion3,
                  ],
                  surveyOptions: [
                    [
                      data.surveyOption1_1,
                      data.surveyOption1_2,
                      data.surveyOption1_3,
                      data.surveyOption1_4,
                    ],
                    [
                      data.surveyOption2_1,
                      data.surveyOption2_2,
                      data.surveyOption2_3,
                      data.surveyOption2_4,
                    ],
                    [
                      data.surveyOption3_1,
                      data.surveyOption3_2,
                      data.surveyOption3_3,
                      data.surveyOption3_4,
                    ],
                  ],
                  showSurveyNumber: data.showSurveyNumber,
                  showResultNumber1: data.showResultNumber1,
                  showResultNumber2: data.showResultNumber2,
                  showResultNumber3: data.showResultNumber3,
                },
                this.calculateOptionWidthsAndPercentages
              );
            }
          } else {
            console.log("指定されたライブIDのドキュメントが存在しません。");
          }
        });
    } catch (error) {
      console.error("アンケートデータの取得中にエラーが発生しました:", error);
    }
  };

  subscribeToSurveyResults = (liveId) => {
    console.log("subscribeToSurveyResults");
    db.doc(`survey/${liveId}`).onSnapshot((doc) => {
      if (doc.exists) {
        const surveyData = doc.data();
        console.log(surveyData);
        if (surveyData) {
          this.setState(
            {
              CountOption1_1: surveyData.CountOption1_1 || 0,
              CountOption1_2: surveyData.CountOption1_2 || 0,
              CountOption1_3: surveyData.CountOption1_3 || 0,
              CountOption1_4: surveyData.CountOption1_4 || 0,
              CountOption2_1: surveyData.CountOption2_1 || 0,
              CountOption2_2: surveyData.CountOption2_2 || 0,
              CountOption2_3: surveyData.CountOption2_3 || 0,
              CountOption2_4: surveyData.CountOption2_4 || 0,
              CountOption3_1: surveyData.CountOption3_1 || 0,
              CountOption3_2: surveyData.CountOption3_2 || 0,
              CountOption3_3: surveyData.CountOption3_3 || 0,
              CountOption3_4: surveyData.CountOption3_4 || 0,
            },
            this.calculateOptionWidthsAndPercentages
          );
        }
      } else {
        console.log("指定されたライブIDのアンケートデータが存在しません。");
        console.log(this.state.liveId);
      }
    });
  };

  calculateOptionWidthsAndPercentages = () => {
    const { showSurveyNumber } = this.state;
    if (showSurveyNumber) {
      const optionCounts = [
        this.state[`CountOption${showSurveyNumber}_1`] || 0,
        this.state[`CountOption${showSurveyNumber}_2`] || 0,
        this.state[`CountOption${showSurveyNumber}_3`] || 0,
        this.state[`CountOption${showSurveyNumber}_4`] || 0,
      ];
      const totalVotes = optionCounts.reduce(
        (total, count) => total + count,
        0
      );
      const optionWidths = optionCounts.map((count) =>
        totalVotes ? `${(count / totalVotes) * 100}%` : "0%"
      );
      const optionPercentages = optionCounts.map((count) =>
        totalVotes ? `${Math.round((count / totalVotes) * 100)}%` : "0%"
      );
      this.setState({ optionWidths, optionPercentages });
      console.log(this.state.optionWidths);
    }
  };

  handleOptionClick = async (showNumber, option) => {
    if (this.state.selected !== `${showNumber}_${option}`) {
      const previousSelection = this.state.selected;
      this.setState({ selected: `${showNumber}_${option}` });
      try {
        if (previousSelection) {
          await db.doc(`survey/${this.state.liveId}`).update({
            [`CountOption${previousSelection}`]: firebase.firestore.FieldValue.increment(
              -1
            ),
          });
        }
        await db.doc(`survey/${this.state.liveId}`).update({
          [`CountOption${showNumber}_${option}`]: firebase.firestore.FieldValue.increment(
            1
          ),
        });
        console.log("選択肢が更新されました。");
      } catch (error) {
        console.error("選択肢の更新中にエラーが発生しました:", error);
      }
    }
  };

  handleMinimizeClick = () => {
    this.setState({ isMinimized: !this.state.isMinimized });
  };

  saveSurvey = async (surveyNumber) => {
    const {
      liveId,
      surveyTitle1,
      surveyTitle2,
      surveyTitle3,
      surveyQuestion1,
      surveyQuestion2,
      surveyQuestion3,
      surveyOption1_1,
      surveyOption1_2,
      surveyOption1_3,
      surveyOption1_4,
      surveyOption2_1,
      surveyOption2_2,
      surveyOption2_3,
      surveyOption2_4,
      surveyOption3_1,
      surveyOption3_2,
      surveyOption3_3,
      surveyOption3_4,
    } = this.state;
    if (!liveId) {
      console.error("ライブIDが存在しません。");
      return;
    }
    try {
      const surveyDoc = await db.doc(`survey/${liveId}`).get();
      if (!surveyDoc.exists) {
        await db.doc(`survey/${liveId}`).set({});
      }
      if (surveyNumber === 1) {
        await db.doc(`lives/${liveId}`).set(
          {
            surveyTitle1,
            surveyQuestion1,
            surveyOption1_1: surveyOption1_1 || "",
            surveyOption1_2: surveyOption1_2 || "",
            surveyOption1_3: surveyOption1_3 || "",
            surveyOption1_4: surveyOption1_4 || "",
          },
          { merge: true }
        );
      } else if (surveyNumber === 2) {
        await db.doc(`lives/${liveId}`).set(
          {
            surveyTitle2,
            surveyQuestion2,
            surveyOption2_1: surveyOption2_1 || "",
            surveyOption2_2: surveyOption2_2 || "",
            surveyOption2_3: surveyOption2_3 || "",
            surveyOption2_4: surveyOption2_4 || "",
          },
          { merge: true }
        );
      } else if (surveyNumber === 3) {
        await db.doc(`lives/${liveId}`).set(
          {
            surveyTitle3,
            surveyQuestion3,
            surveyOption3_1: surveyOption3_1 || "",
            surveyOption3_2: surveyOption3_2 || "",
            surveyOption3_3: surveyOption3_3 || "",
            surveyOption3_4: surveyOption3_4 || "",
          },
          { merge: true }
        );
      }
      console.log("アンケートの設定が保存されました。");
    } catch (error) {
      console.error("アンケートの保存中にエラーが発生しました: ", error);
    }
  };

  showSurvey = async (showNumber) => {
    if (!this.state.liveId) {
      console.error("ライブIDが存在しません。");
      return;
    }

    let newShowNumber = showNumber;
    if (this.state.showSurveyNumber === showNumber) {
      console.log("同じ番号");
      newShowNumber = -1; // 同じ番号の場合は-1に設定
    }

    try {
      if(newShowNumber !== -1){
        await this.saveSurvey(showNumber)
      }
      
      await db.doc(`lives/${this.state.liveId}`).set(
        {
          showSurveyNumber: newShowNumber,
        },
        { merge: true }
      );

      if(this.getElapsedTimeText()){
        const isOpen = newShowNumber !== -1
        await db.doc(`survey/${this.state.liveId}`).update({
          [`survey_status.${showNumber}.${this.getElapsedTimeText()}`]: isOpen
        });
        
        const currentShowNumber = this.state.showSurveyNumber
        if(currentShowNumber && currentShowNumber !== -1 && isOpen){
          await db.doc(`survey/${this.state.liveId}`).update({
            [`survey_status.${currentShowNumber}.${this.getElapsedTimeText()}`]: false
          });
        }
      }


      this.setState({ showSurveyNumber: newShowNumber });
      console.log("アンケートの表示設定が更新されました。");
    } catch (error) {
      console.error(
        "アンケートの表示設定更新中にエラーが発生しました: ",
        error
      );
    }
  };

  showResult = async (showNumber) => {
    if (!this.state.liveId) {
      console.error("ライブIDが存在しません。");
      return;
    }

    let newShowResult = !this.state[`showResultNumber${showNumber}`];
    try {
      await db.doc(`lives/${this.state.liveId}`).set(
        {
          [`showResultNumber${showNumber}`]: newShowResult,
        },
        { merge: true }
      );
      this.setState({ [`showResultNumber${showNumber}`]: newShowResult });
      console.log("アンケートの表示設定が更新されました。");
    } catch (error) {
      console.error(
        "アンケートの表示設定更新中にエラーが発生しました: ",
        error
      );
    }
  };

  render() {
    const styles = {
      otherBrowserInLive: {
        margin: "auto",
        width: "405px",
        height: "720px",
        background: "#000000",
        marginTop: "20px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      },
      otherBrowserInLiveText: {
        color: "#ffffff",
      },
    };
    const state = this.state;
    if (state.redirect) {
      return <Redirect to="/streamer/previews" />;
    }
    const switcher = ENABLE_SWITCH_CAMERAS_AND_MICROPHONES ? (
      <div>
        <p>
          <span className="titleLabel">カメラ</span>
          {state.videoInputDevices &&
            state.videoInputDevices.map((device) => (
              <div>
                <Button
                  key={device.deviceId}
                  onClick={() => this.onClickVideoInputDevice(device)}
                >
                  {device.label}
                  {device.deviceId === state.videoDeviceId && (
                    <span className="broadcastingTagInner broadcastingStreaming ml5">
                      選択中
                    </span>
                  )}
                </Button>
              </div>
            ))}
        </p>
        <p>
          <span className="titleLabel">マイク</span>
          {state.audioInputDevices &&
            state.audioInputDevices.map((device) => (
              <div>
                <Button
                  key={device.deviceId}
                  onClick={() => this.onClickAudioInputDevice(device)}
                >
                  {device.label}
                  {device.deviceId === state.audioDeviceId && (
                    <span className="broadcastingTagInner broadcastingStreaming ml5">
                      選択中
                    </span>
                  )}
                </Button>
              </div>
            ))}
        </p>
      </div>
    ) : null;
    const comments = [...state.streamerComments, ...state.viewerComments]
      .sort((a, b) => a.created - b.created)
      .slice(-this.nComments)
      .reverse();
    const stabilizing = state.stabilizing - 1;
    const isInLive = state.isLive || state.hasLive;

    const options = [
      "CountOption1_1",
      "CountOption1_2",
      "CountOption1_3",
      "CountOption1_4",
      "CountOption2_1",
      "CountOption2_2",
      "CountOption2_3",
      "CountOption2_4",
      "CountOption3_1",
      "CountOption3_2",
      "CountOption3_3",
      "CountOption3_4",
    ];

    const totalVotes = [
      options
        .slice(0, 4)
        .map((option) => this.state[option] || 0)
        .reduce((a, b) => a + b, 0),
      options
        .slice(4, 8)
        .map((option) => this.state[option] || 0)
        .reduce((a, b) => a + b, 0),
      options
        .slice(8, 12)
        .map((option) => this.state[option] || 0)
        .reduce((a, b) => a + b, 0),
    ];

    const optionRates = options.map(
      (option, index) =>
        `${
          totalVotes[Math.floor(index / 4)] !== 0
            ? `${Math.floor(
                ((this.state[option] || 0) /
                  totalVotes[Math.floor(index / 4)]) *
                  100
              )}%`
            : "0%"
        }`
    );

    return (
      <div>
        <StreamerNav permission={this.props.permission} />
        <div className="content live">
          <div className="broadcastingTitle">
            <h2>ライブ配信</h2>
            {!state.otherBrowserInLive && (
              <div className="broadcastingTag">
                {state.isOffline && (
                  <span className="broadcastingTagInner broadcastingOffline">
                    オフライン
                  </span>
                )}
                {state.isTest && !(state.isPublishing && state.isLive) && (
                  <span className="broadcastingTagInner testTag ml5">
                    テスト
                  </span>
                )}
                {!state.isPublishing && (
                  <span className="broadcastingTagInner broadcastingBefore ml5">
                    配信前
                  </span>
                )}
                {state.isPublishing && !state.isLive && (
                  <span className="broadcastingTagInner broadcastingPreview ml5">
                    プレビュー
                  </span>
                )}
                {state.isPublishing && state.isLive && (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-end",
                    }}
                  >
                    <span
                      className={`mr5 label label-${
                        this.isRemainingTimeShort() ? "danger" : "default"
                      }`}
                    >
                      残り時間
                    </span>
                    <span
                      className={`mr5 ${
                        this.isRemainingTimeShort() ? "text-danger" : ""
                      }`}
                    >
                      {this.getCountdownText()}
                    </span>
                    {state.isTest && (
                      <span className="broadcastingTagInner testTag ml5">
                        テスト
                      </span>
                    )}
                    {state.manualStabilization && 0 < stabilizing ? (
                      <span className="broadcastingTagInner broadcastingPrivate ml5">
                        非公開
                      </span>
                    ) : (
                      <span className="broadcastingTagInner broadcastingStreaming ml5">
                        配信中
                      </span>
                    )}
                  </div>
                )}
              </div>
            )}
          </div>
          <div className="live_left">
            <Form>
              <div className="broadcastingMainInfo live_initialInfo">
                <p>
                  <span className="live_titleLabel">
                    {state.liveId && "ID / "}タイトル
                  </span>
                  {state.liveId && (
                    <span className="live_liveId">{state.liveId}</span>
                  )}
                  <span className="live_liveName">{state.name}</span>
                </p>
                <Checkbox
                  name="rotated"
                  onChange={this.onChangeRotated}
                  checked={state.rotated}
                  disabled={state.isLoading || state.isPublishing || isInLive}
                >
                  カメラを時計回りに90度回転させて撮影
                </Checkbox>
                {/*
                <Checkbox
                  name="manualStabilization"
                  onChange={this.onChangeManualStabilization}
                  checked={state.manualStabilization}
                  disabled={
                    state.isLoading
                    || state.isPublishing
                    || isInLive
                  }
                >
                  ライブの公開タイミングを手動で調整<br/>
                  （公開前にライブの画質を目で確認）
                </Checkbox>
                */}
                {!state.hardwareSetup && switcher}
              </div>
              {this.state.previewStreamerName &&
                this.state.previewStreamerText && (
                  <div className="broadcastingPreviewComment">
                    <span className="live_titleLabel">
                      ライブ配信開始時の固定コメント
                    </span>
                    <span className="live_commentName">
                      {state.previewStreamerName}
                    </span>
                    <span className="live_commentText">
                      : {state.previewStreamerText}
                    </span>
                  </div>
                )}
              {isInLive && (
                <React.Fragment>
                  <div className="broadcastingMainInfo">
                    <p>
                      <span className="live_titleLabel">LIVE 再生数</span>
                      <span className="emphasizeNumber displayBlock">
                        {state.views}
                      </span>
                    </p>
                    {model.ENABLE_LIKE && (
                      <p>
                        <span className="titleLabel mr10">ライクカウント</span>
                        <span className="emphasizeNumber">{state.likes}</span>
                      </p>
                    )}
                  </div>
                  <div className="broadcastingCommentList">
                    <p className="live_commentTitleArea">
                      <span className="live_titleLabel">コメント</span>
                      <a
                        className="btn normalBtn live_commentBtn"
                        href={`/streamer/messages/${state.liveId}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        コメント管理へ
                      </a>
                    </p>
                    <ul className="broadcastingCommentListInner">
                      {comments.map((comment) => (
                        <li key={comment.id}>
                          <span className="live_commentName">
                            {`${comment.name ||
                              (comment.isStreamerComment
                                ? "配信者"
                                : "ゲスト")}`}
                          </span>
                          <span className="live_commentText">
                            :{" "}
                            {comment.isStreamerComment
                              ? model.replaceLink(comment.text)
                              : comment.text}
                          </span>
                        </li>
                      ))}
                    </ul>
                  </div>

                  {(this.state.showTelop || this.state.showFullTelop) && (
                    <div className="broadcastingItemList">
                      <p>
                        <span className="live_titleLabel">
                          ライブに表示するテロップ
                        </span>
                      </p>
                      <ul>
                        {this.state.liveTelops.map((telop) => (
                          <li key={telop.id} className="liveTelop">
                            <img src={telop.image} alt="telop" />
                            <a
                              className={
                                telop.display
                                  ? "btn blackBtn stockBtn"
                                  : "btn grayBtn stockBtn"
                              }
                              disabled={state.isToggling}
                              onClick={() => this.toggleTelop(telop)}
                              href="#/"
                            >
                              {telop.display ? "表示中" : "非表示"}
                            </a>
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                </React.Fragment>
              )}
              <FormGroup
                controlId="broadcastingItemList"
                className="broadcastingItemList"
              >
                <p>
                  <span className="live_titleLabel">ライブに表示する商品</span>
                  {isInLive && this.state.ctaButton && (
                    <small className="live_ctaLabel">
                      （ライブ画面左上にCTA表示中の商品{" "}
                      {this.state.liveItems.filter((i) => !!i.cta).length}/
                      {MAX_CTA_ITEMS} 商品）
                    </small>
                  )}
                </p>
                <ul className="live_liveItemList">
                  {this.state.liveItems.map((item) => (
                    <li key={item.id} className="liveItem">
                      <img
                        src={item.image}
                        alt="item"
                        className="live_liveItemImage"
                      />
                      <span>
                        {item.name}
                        <br />
                        {item.code}
                      </span>
                      {isInLive && state.ctaButton && (
                        <a
                          className={
                            item.cta
                              ? "btn blackBtn stockBtn"
                              : "btn grayBtn stockBtn"
                          }
                          disabled={state.isToggling}
                          onClick={() => this.toggleItemCTA(item)}
                          href="#/"
                        >
                          {item.cta ? "CTA ON" : "CTA OFF"}
                        </a>
                      )}
                    </li>
                  ))}
                </ul>
              </FormGroup>
              {this.state.surveyMode && (
                <FormGroup controlId="surveySection" className="surveySection">
                  <p>
                    <span className="live_titleLabel">
                      ライブアンケート設定
                    </span>
                  </p>
                  <div>
                    {Array(3)
                      .fill()
                      .map((_, i) => (
                        <div>
                          <span className="live_titleLabel_survey">
                            アンケート {i + 1}
                            <button
                              type="button"
                              onClick={() =>
                                this.setState({
                                  [`showSurvey${i + 1}`]: !this.state[
                                    `showSurvey${i + 1}`
                                  ],
                                })
                              }
                              className="btn triangleBtn"
                            >
                              {this.state[`showSurvey${i + 1}`] ? "▲" : "▼"}
                            </button>
                          </span>
                          {this.state[`showSurvey${i + 1}`] && (
                            <div className="surveyComponent" key={i}>
                              アンケート名
                              <Row className="surveyQuestion">
                                <Col sm={12}>
                                  <FormControl
                                    type="text"
                                    placeholder="アンケートタイトルを入力してください"
                                    value={this.state[`surveyTitle${i + 1}`]}
                                    onChange={(e) =>
                                      this.setState({
                                        [`surveyTitle${i + 1}`]: e.target.value,
                                      })
                                    }
                                  />
                                </Col>
                              </Row>
                              質問文
                              <Row className="surveyQuestion">
                                <Col sm={12}>
                                  <FormControl
                                    type="text"
                                    placeholder="質問文を入力してください"
                                    value={this.state[`surveyQuestion${i + 1}`]}
                                    onChange={(e) =>
                                      this.setState({
                                        [`surveyQuestion${i + 1}`]: e.target
                                          .value,
                                      })
                                    }
                                  />
                                </Col>
                              </Row>
                              {Array(4)
                                .fill()
                                .map((_, j) => (
                                  <Row className="surveyOption" key={j}>
                                    <Col sm={10}>
                                      <FormControl
                                        type="text"
                                        placeholder={`選択肢${j +
                                          1}を入力してください`}
                                        value={
                                          this.state[
                                            `surveyOption${i + 1}_${j + 1}`
                                          ]
                                        }
                                        onChange={(e) =>
                                          this.setState({
                                            [`surveyOption${i + 1}_${j + 1}`]: e
                                              .target.value,
                                          })
                                        }
                                      />
                                    </Col>
                                    <Col sm={1} className="surveyOptionPercent">
                                      <p>{optionRates[i * 4 + j]}</p>
                                    </Col>
                                  </Row>
                                ))}
                              <Button
                                type="button"
                                onClick={() => this.saveSurvey(i + 1)}
                                className="btn normalBtn"
                              >
                                変更を保存
                              </Button>
                              <Button
                                type="button"
                                onClick={() => this.showSurvey(i + 1)}
                                className={
                                  this.state.showSurveyNumber === i + 1
                                    ? "btn normalBtn redBtn"
                                    : "btn normalBtn greenBtn"
                                }
                              >
                                {this.state.showSurveyNumber === i + 1
                                  ? "候補表示中"
                                  : "候補を表示"}
                              </Button>
                              <Button
                                type="button"
                                onClick={() => this.showResult(i + 1)}
                                className={
                                  this.state[`showResultNumber${i + 1}`]
                                    ? "btn normalBtn redBtn"
                                    : "btn normalBtn greenBtn"
                                }
                              >
                                {this.state[`showResultNumber${i + 1}`]
                                  ? "結果表示中"
                                  : "結果を表示"}
                              </Button>
                            </div>
                          )}
                        </div>
                      ))}
                  </div>
                </FormGroup>
              )}
            </Form>
          </div>

          <div className="live_right">
            <div className="positionRightupper">
              <div className="positionRightupperInner">
                <Form>
                  {!state.isPublishing && (
                    <div className="live_rightButtonsArea">
                      {state.isLoading && (
                        <span className="mr5 mt10 live_isLoadingLabel">
                          読み込み中...
                        </span>
                      )}
                      {!state.isLoading && state.manualStabilization && (
                        <a
                          href={`/streamer/monitor/${state.liveId}`}
                          target="_blank"
                          className="btn normalBtn"
                          rel="noopener noreferrer"
                        >
                          ライブモニターを開く
                        </a>
                      )}
                      {!state.otherBrowserInLive && (
                        <Button
                          onClick={this.onClickStartPreview}
                          disabled={state.isLoading}
                          className="normalBtn-strongColor ml10"
                        >
                          プレビューを見る
                        </Button>
                      )}
                    </div>
                  )}
                  {!state.otherBrowserInLive &&
                    state.isPublishing &&
                    !state.isLive && (
                      <div className="live_rightButtonsArea">
                        {state.isLoading && (
                          <span className="mr5 mt10 live_isLoadingLabel">
                            読み込み中...
                          </span>
                        )}
                        {!state.isLoading && (
                          <Button
                            onClick={this.onClickEndPreview}
                            className="btn normalBtn"
                          >
                            プレビューを閉じる
                          </Button>
                        )}
                        <Button
                          onClick={this.onClickStart}
                          disabled={state.isLoading}
                          className="btn normalBtn ml10"
                        >
                          準備を始める
                        </Button>
                      </div>
                    )}
                  {state.isPublishing && state.isLive && (
                    <div className="live_rightButtonsArea">
                      {state.manualStabilization && (
                        <a
                          href={`/streamer/monitor/${state.liveId}`}
                          target="_blank"
                          className="btn normalBtn"
                          rel="noopener noreferrer"
                        >
                          ライブモニターを開く
                        </a>
                      )}
                      {!state.otherBrowserInLive &&
                      state.manualStabilization &&
                      0 < stabilizing &&
                      !state.isCancelled ? (
                        <div>
                          <Button
                            onClick={this.onClickStartLive}
                            disabled={
                              state.isLoading || !state.awaitedStabilizingTime
                            }
                            className="btn normalBtn-strongColor ml10"
                          >
                            配信を始める
                          </Button>

                          {ENABLE_EXIT_BUTTON && (
                            <Button
                              onClick={this.onClickEndLive}
                              disabled={state.isLoading}
                              className="btn normalBtn mt10"
                            >
                              配信を終了する
                            </Button>
                          )}
                        </div>
                      ) : state.isUnpublishing || state.isCancelled ? (
                        <div className="live_isLoadingLabel">
                          終了処理を実行しています...
                        </div>
                      ) : (
                        <Button
                          onClick={this.onClickButton}
                          disabled={state.isLoading}
                          className="btn normalBtn ml10"
                        >
                          配信を終了する
                        </Button>
                      )}
                    </div>
                  )}
                </Form>
              </div>
            </div>
            {!state.otherBrowserInLive && <div id="hardware-setup" />}
            {state.otherBrowserInLive && (
              <div style={styles.otherBrowserInLive}>
                <span style={styles.otherBrowserInLiveText}>
                  他のブラウザ・端末で配信中です
                </span>
              </div>
            )}
            {!state.otherBrowserInLive && (
              <div className="publisherArea">
                <div
                  id="publisher"
                  className={`publisher${state.rotated ? "Rotated" : ""}`}
                ></div>
                {state.isPublishing &&
                  state.isLive &&
                  !state.manualStabilization &&
                  0 < stabilizing && (
                    <div className="stabilizing-overlay">
                      {stabilizing <= 10 ? (
                        <span className="stabilizing-countdown">
                          {stabilizing}
                        </span>
                      ) : (
                        <span className="stabilizing-message">
                          画質を調整しています…
                          <br />
                          <br />
                          {MAX_LIVE_STABILIZING_SECONDS - 10}
                          秒後にカウントダウン
                          <br />
                          を開始しますので、
                          <br />
                          そのままお待ちください
                          <br />
                        </span>
                      )}
                    </div>
                  )}
                {state.isPublishing &&
                  state.isLive &&
                  state.manualStabilization &&
                  0 < stabilizing && (
                    <div className="stabilizing-overlay">
                      <span className="stabilizing-message">
                        ライブモニターで配信画質を
                        <br />
                        確認してください
                        <br />
                        <br />
                        画質を調整しています…
                        <br />
                        <br />
                        20秒程お待ち頂きましたあと、
                        <br />
                        「配信を始める」ボタンより
                        <br />
                        配信いただけます
                        <br />
                      </span>
                      <button
                        className="stabilizing-cancel"
                        disabled={state.isLoading}
                        onClick={this.onClickCancel}
                      >
                        最初に戻る
                      </button>
                    </div>
                  )}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}
