import React, { Component } from 'react';
import { DropdownButton, MenuItem, Nav, NavItem } from 'react-bootstrap';
import moment from 'moment';

import './App.css';
import SellerNav from './SellerNav';
import firebase from './firebase';
import model from './Model';

export default class Orders extends Component {
  constructor(props) {
    super(props);
    this.state = {
      shopIndex: 0,
      shops: [],
      orders: [],
    };
  }

  componentDidMount() {
    const uid = firebase.auth().currentUser.uid;
    model.collection('shops').where('admin', '==', uid).get().then((data) => {
      if (!data.empty) {
        this.setState({ shops: model.listData(data) });
        this.selectShop(0);
      } else {
        model.collection('shops').where('uid', '==', uid).get().then((data) => {
          if (!data.empty) {
            this.ordersRef = model.collection('orders').where('shopId', '==', data.docs[0].id)
              .orderBy('created', 'desc');
            this.ordersRef.get().then((data) => this.setState({ orders: model.listData(data) }));
          }
        });
      }
    });

  }

  selectShop = (shopIndex) => {
    this.setState({ shopIndex });
    this.ordersRef = model.collection('orders')
      .where('shopId', '==', this.state.shops[shopIndex].id).orderBy('created', 'desc');
    this.ordersRef.get().then((data) => this.setState({ orders: model.listData(data) }));
  };

  selectSection = (section) => {
    if (this.ordersRef) {
      switch (section) {
        case 1:
          this.ordersRef.get().then((data) => this.setState({ orders: model.listData(data) }));
          break;
        case 2:
          this.ordersRef.where('status', '==', 'paid').get().then(
            (data) => this.setState({ orders: model.listData(data) }));
          break;
        case 3:
          this.ordersRef.where('status', '==', 'returned').get().then(
            (data) => this.setState({ orders: model.listData(data) }));
          break;
        default:
          break;
      }
    }
  };

  renderOrders = () => (
    <ul className="card">
      {this.state.orders.map((order) => (
        <li key={order.id}>
         <span className="cell">{model.orderStatus(order.status)}</span>
         <span className="cell">{moment(order.created.toDate()).format('YYYY年M月D日')}</span>
         <span className="nameCell">{order.name}</span>
         <span className="cell">¥{order.price}</span>
         <a className="cell" href={`/orders/${order.id}`} target="_blank" rel="noopener noreferrer">詳しく見る</a>
        </li>
      ))}
    </ul>
  );

  render() {
    const state = this.state;
    return (
      <div>
      <SellerNav permission={this.props.permission}/>
      <div className="content">
        <h2>注文管理</h2>
        {state.shops.length > 0 &&
        <DropdownButton title={state.shops[state.shopIndex].name} id="shop">
          {state.shops.map((shop, i) => (
            <MenuItem key={i} eventKey={i} active={i === state.shopIndex}
              onSelect={this.selectShop}>
              {shop.name}
            </MenuItem>
          ))}
        </DropdownButton>}
        <Nav bsStyle="pills">
          <NavItem eventKey={1} href="#" onSelect={this.selectSection}>注文一覧</NavItem>
          <NavItem eventKey={2} href="#" onSelect={this.selectSection}>未発送注文</NavItem>
          <NavItem eventKey={3} href="#" onSelect={this.selectSection}>返品申請</NavItem>
        </Nav>
        {state.orders.length > 0 && this.renderOrders()}
      </div>
      </div>
    );
  }
}
