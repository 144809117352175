import React, { Component } from 'react';
import { Button, ButtonToolbar, FormControl } from 'react-bootstrap';

import './App.css';
import SellerNav from './SellerNav';
import model from './Model';

export default class Order extends Component {
  constructor(props) {
    super(props);
    this.state = {
      order: null,
      shipper: '',
      tracking: '',
      error: '',
    };
  }

  componentDidMount() {
    model.collection('orders').doc(this.props.match.params.id).onSnapshot((doc) => {
      if (doc.exists) {
        const order = doc.data();
        this.setState({
          order: order,
          tracking: order.tracking || '',
          shipper: order.shipper || '',
        });
      }
    });
  }

  onTrackingChange = (e) => this.setState({[e.target.name]: e.target.value});

  updateStatus = (status) => {
    this.setState({ error: '' });
    if (status === 'fullfilled' && !(this.state.shipper && this.state.tracking)) {
      this.setState({ error: '配送業者と追跡番号を入力してください' });
      return;
    }
    model.collection('orders').doc(this.state.order.id).update({
      status: status,
      shipper: this.state.shipper,
      tracking: this.state.tracking,
    });
  };

  render() {
    const order = this.state.order;
    return (
      <div>
        <SellerNav permission={this.props.permission}/>
        <div className="content">
          <h2>注文管理 - 注文詳細</h2>
          {order && (
            <div className="card">
              {order.status === 'returnDenied' && (
                <p className="error">返品希望商品をお客様に返送してください</p>
              )}
              <p>注文ID: {order.id} {model.orderStatus(order.status)}</p>
              {order.returns && (
                <div>
                  <h4>返品内容</h4>
                  <ul>
                    {Object.keys(order.returns).map((unitId) => {
                      const unit = order.returns[unitId];
                      return (
                        <li key={unitId} className="unit">
                          <img src={unit.image} alt={unit.name} />
                          <span className="cell">{unit.code}</span>
                          <span className="nameCell">{unit.name}</span>
                          <span className="nameCell">{unit.color} {unit.size}</span>
                          <span className="cell">¥{unit.price}</span>
                          <span className="cell">{unit.quantity}個</span>
                          <span className="nameCell">理由：{unit.reason}</span>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              )}
              <h4>お届け先</h4>
              <p>{order.name} 様</p>
              <p>{order.address}</p>
              <h4>注文内容</h4>
              <p>合計金額：¥{order.price}</p>
              <p>送料：¥{order.shipping}</p>
              <ul>
                {order.units.map((unit) => (
                  <li key={unit.id} className="unit">
                    <img src={unit.image} alt={unit.name} />
                    <span className="cell">{unit.code}</span>
                    <span className="nameCell">{unit.name}</span>
                    <span className="nameCell">{unit.color} {unit.size}</span>
                    <span className="cell">¥{unit.price}</span>
                    <span className="cell">{unit.quantity}個</span>
                  </li>
                ))}
              </ul>
              {order.status === 'paid' && (
                <div>
                  <label>配送業者：</label>
                  <FormControl name="shipper" type="text" placeholder="配送業者"
                    value={this.state.shipper} onChange={this.onTrackingChange} className="input" />
                  <label>追跡番号：</label>
                  <FormControl name="tracking" type="text" placeholder="追跡番号"
                    value={this.state.tracking} onChange={this.onTrackingChange}
                    className="input" />
                </div>
              )}
              {order.status !== 'paid' && (
                <div>
                  <p>配送業者：{order.shipper}</p>
                  <p>追跡番号：{order.tracking}</p>
                </div>
              )}
              {order.status === 'paid' && (
                <Button type="button" bsStyle="warning"
                  onClick={() => this.updateStatus('fullfilled')}>
                  発送済みにする
                </Button>
              )}
              {order.status === 'returned' && (
                <ButtonToolbar>
                  <Button type="button" bsStyle="warning"
                    onClick={() => this.updateStatus('returnSuccess')}>
                    返品完了にする
                  </Button>
                  <Button type="button" bsStyle="danger"
                    onClick={() => this.updateStatus('returnDenied')}>
                    返品を拒否する
                  </Button>
                </ButtonToolbar>
              )}
              <p className="error">{this.state.error}</p>
            </div>
          )}
        </div>
      </div>
    );
  }
}
