import React, { Component } from 'react';
import { Button } from 'react-bootstrap';
import algoliasearch from 'algoliasearch/lite';
import { Configure, CurrentRefinements, Hits, InstantSearch, SearchBox } from 'react-instantsearch-dom';
import { connectMenu } from 'react-instantsearch/connectors';
import Pagination from './Pagination';
import moment from 'moment';

import Config from './Config';

const searchClient = algoliasearch(Config.ALGOLIA_APP_ID, Config.ALGOLIA_API_KEY);
const VirtualMenu = connectMenu(() => null);

export default class ItemSearch extends Component {
  pathname = () => {
    return document.location.pathname;
  }

  fromItems = () => {
    return this.pathname() === '/items';
  }

  search = () => (
    <div className="contain itemSearch">
      <SearchBox className="searchBox"/>
      <Configure hitsPerPage={30} />
      <Hits hitComponent={this.item} />
      <Pagination />
    </div>
  );

  item = ({ hit }) => {
    if (hit.salePrice && hit.salePriceEffectiveDate) {
      const salePriceEffectiveDate = hit.salePriceEffectiveDate.split('/').map((e) => new Date(e).getTime()).sort();
      const currentDateTime = new Date().getTime();
      if (salePriceEffectiveDate[0] <= currentDateTime && currentDateTime <= salePriceEffectiveDate[1]) {
        hit.salePriceFlag = true;
        hit.salePriceEffectiveDateEnd = salePriceEffectiveDate[1];
      }
    }
    return (
      <div className="itemSearch">
        <div className="itemSearch_unit head">
          <div style={{ position: 'relative' }}>
            {hit.salePriceFlag && (
              <p className="delivered ml5" style={{ position: 'absolute' }} >SALE</p>
            )}
            <img src={hit.image} className="ddImageCell" alt="item" />
          </div>
          <span className="goodsNameCell ddGoodsTitleCell">{hit.name}</span>
        </div>
        <div className="itemSearch_unit">
          <span className="goodsNameCell ddBrandNumberCell">{hit.code}</span>
          {(hit.salePriceFlag) ?
            <div>
              <span className="goodsNameCell ddPriceCell">¥{Number(hit.salePrice).toLocaleString()} {'('}通常¥{Number(hit.price).toLocaleString()}{')'}</span>
              <span className="goodsNameCell ddPriceCell">セール {moment(hit.salePriceEffectiveDateEnd).format('YYYY年M月D日')}まで</span>
            </div> :
            <span className="goodsNameCell ddPriceCell">¥{Number(hit.price).toLocaleString()}</span>
          }
          <span className="goodsNameCell ddLastUpDateCell">最終更新日 {moment(hit.updatedAt).format('YYYY年M月D日')}</span>
        </div>
        { this.fromItems() ?
          <a className="cell normalBtn ml20 goodsNameBtn" href={`/items/${hit.id}`}>詳細</a> :
          !this.props.liveItems.map(a => a.id).includes(hit.id) ? 
          <Button className="cell normalBtn ml20 goodsNameBtn" onClick={() => this.props.onAddItem(hit)}>追加</Button> :
          <Button className="cell normalBtn ml20 goodsNameBtn" disabled={true}>追加済み</Button>
        }
      </div>
    );
  }

  render = () => (
    <InstantSearch
      searchClient={searchClient}
      indexName={`${Config.ALGOLIA_INDEX_PREFIX}items`}>
      <CurrentRefinements
        transformItems={items => items.filter(item => item.currentRefinement !== this.props.shopId)}
      />
      <VirtualMenu attribute="shopId" defaultRefinement={this.props.shopId} />
      <this.search />
    </InstantSearch>
  );
}
