import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import moment from 'moment';

import './App.css';
import SellerNav from './SellerNav';
import firebase from './firebase';
import model from './Model';

const db = firebase.firestore();

export default class Item extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect: false,
      item: null,
      units: [],
      message: ''
    };
  }

  componentDidMount() {
    const uid = firebase.auth().currentUser.uid;
    const shopIds = [];
    Promise.all([
      db.collection('shops').where('uid', '==', uid).get(),
      db.collection('shops').where('admin', '==', uid).get(),
    ]).then((querySnapshots) => {
      querySnapshots.forEach((querySnapshot) => {
        querySnapshot.docs.forEach((queryDocumentSnapshot) => {
          shopIds.push(queryDocumentSnapshot.data().id);
        })
      });
      const itemId = this.props.match.params.id;
      db.collection('items').doc(itemId).get().then((doc) => {
        if (doc.exists) {
          const item = doc.data();
          if (shopIds.includes(item.shopId)) {
            this.setState({ item });
          } else {
            this.setState({ redirect: true });
          }
        }
      });
      db.collection('units').where('itemId', '==', itemId).get()
        .then(model.listData).then((units) => this.setState({ units }));
    });
  }

  render() {
    if (this.state.redirect) {
      return <Redirect to="/items" />;
    }
    const item = this.state.item;
    return (
      <div>
        <SellerNav permission={this.props.permission}/>
        <div className="content itemDetail">
          <h2>商品管理</h2>
          <ul className="detail-nav nav-m-bk nav nav-pills">
            <li role="presentation"><a href="/itemdata">一括登録</a></li>
            <li role="presentation" className="active"><a href="/items">商品一覧</a></li>
            <li role="presentation" className="itemDetail_detailTab">商品詳細</li>
          </ul>
          {item && (
            <div className="card">
              <div className="itemDetailArea">
                {item.image && <div className="imgDetail"><img src={item.image} alt="item" /></div>}

                <div className="textDetail">
                  <div className="itemDetail_name">
                    <span className="titleLabel itemDetail_nameLabel">商品名</span>
                    <span className="titleContent itemDetail_nameContent">{item.name}</span>
                  </div>
                  <div>
                    <span className="titleLabel">品番</span>
                    <span className="titleContent">{item.code}</span>
                  </div>
                  <div className="itemDetail_price">
                    <span className="titleLabel">価格</span>
                    <span className="titleContent">¥{Number(item.price).toLocaleString()}</span>
                  </div>
                  <div className="itemDetail_skuIdList">
                    <span className="titleLabel">SKU ID</span>
                    <span className="titleContent">
                      {this.state.units.map((unit, i, {length}) => (
                        <span key={i}>
                          {unit.image ? (
                            <a target="_blank" href={unit.image} rel="noopener noreferrer">{unit.code}</a>
                          ) : (
                            <span>{unit.code}</span>
                          )}
                          { i+1 !== length ? ', ' : '' }
                        </span>
                      ))}
                    </span>
                  </div>
                  {(item.salePrice || item.salePriceEffectiveDate) && (
                    <div>
                      <div className="itemDetail_salePrice">
                        <span className="titleLabel">セール価格</span>
                        <span className="titleContent">¥{Number(item.salePrice).toLocaleString()}</span>
                      </div>
                      <div className="itemDetail_salePriceEffectiveDate">
                        <span className="titleLabel">セール期間</span>
                        <span className="titleContent">{item.salePriceEffectiveDate}</span>
                      </div>
                    </div>
                  )}
                  <div>
                    <span className="titleLabel">商品説明</span>
                    <p className="titleContent caption"
                      dangerouslySetInnerHTML={{ __html: item.info }} />
                  </div>
                  <div>
                    <span className="titleLabel">最終更新日</span>
                    <span className="titleContent">{moment(item.updatedAt).format('YYYY年M月D日')}</span>
                  </div>
                </div>
              </div>

              {item.photos && (
                <div className="itemImagesArea">
                  <span className="titleLabel">登録画像</span>
                  <ul className="itemImagesList">
                  {Object.keys(item.photos).sort().map((priority) => (
                    <li>
                      <a key={priority} href={item.photos[priority]} className="marginRight"
                        target="_blank" rel="noopener noreferrer">
                        <img src={item.photos[priority]} width="90px" alt="item" />
                      </a>
                    </li>
                  ))}
                  </ul>
                </div>)}
              <p className="green">{this.state.message}</p>
            </div>
          )}

          <div className="itemDetail_backButton">
            <button className="warningBtn" onClick={() => window.history.back()}>もどる</button>
          </div>
        </div>
      </div>
    );
  }
}
