import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChalkboardTeacher } from '@fortawesome/free-solid-svg-icons';
import { faClipboard } from '@fortawesome/free-regular-svg-icons';

// TODO: Replace with using FontAwesomeIcon
// import calendarIcon from './img/icon/calendar-alt-regular.svg';
// import chalkboardTeacherIcon from './img/icon/chalkboard-teacher-solid.svg';
// import clipboardIcon from './img/icon/clipboard-regular.svg';
// import paletteIcon from './img/icon/palette-solid.svg';
// import playIcon from './img/icon/play-circle-regular.svg';
// import shoppingCartIcon from './img/icon/shopping-cart-solid.svg';
// import sitemapIcon from './img/icon/sitemap-solid.svg';
// import storeIcon from './img/icon/store-solid.svg';

export const NavShopIcon = (props) => (
  <svg width="40" height="36" viewBox="0 0 40 36" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M23.2952 5.9111L23.5471 10.5704" stroke="#222222" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M30.0952 5.9111L30.9767 10.5704" stroke="#222222" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M19.8952 19.763C17.7545 19.763 16.3693 18 16.1175 15.4815C15.8656 17.8741 14.3545 19.763 12.3397 19.763C10.3249 19.763 8.81376 18.1259 8.56191 15.4815C8.31006 18.1259 6.79895 19.763 4.78413 19.763C2.64339 19.637 0.880427 17.7481 1.00635 15.4815L4.53228 1H35.2582L38.7841 15.6074C38.9101 17.7481 37.1471 19.637 35.0064 19.8889C32.8656 19.8889 31.4804 18.1259 31.2286 15.6074C30.9767 18 29.4656 19.8889 27.4508 19.8889C25.436 19.8889 23.9249 18 23.673 15.6074C23.4212 18 22.036 19.763 19.8952 19.763Z" stroke="#222222" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M16.4953 5.9111L16.2434 10.5704" stroke="#222222" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M9.69525 5.9111L8.81377 10.5704" stroke="#222222" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M4.40637 25.0518V35H15.4879V31.2222C15.4879 28.2 17.3767 26.0593 19.8953 26.0593C22.4138 26.0593 24.1767 28.0741 24.1767 31.0963V35H35.3841V19.8889" stroke="#222222" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
);

export const NavItemdataIcon = (props) => (
  <svg width="32" height="35" viewBox="0 0 32 35" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#clip0_5_10)">
      <path d="M0.761902 0.760864L4.57143 4.56521V31.9565H31.2381" stroke="#222222" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M9.90476 34.2391V31.9565" stroke="#222222" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M26.6667 34.2391V31.9565" stroke="#222222" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M12.419 16.7391H28.7238C29.7143 16.7391 30.4762 17.5 30.4762 18.4891V24.8804C30.4762 25.8696 29.7143 26.6304 28.7238 26.6304H12.419C11.4286 26.6304 10.6667 25.8696 10.6667 24.8804V18.4891C10.6667 17.5 11.4286 16.7391 12.419 16.7391Z" stroke="#222222" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M13.7143 16.7391C13.2571 16.7391 12.9524 16.4348 12.9524 15.9783V10.6522C12.9524 10.1956 13.2571 9.8913 13.7143 9.8913H27.4286C27.8857 9.8913 28.1905 10.1956 28.1905 10.6522V12.9348" stroke="#222222" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M16.7619 9.13044V4.56522C16.7619 4.18479 16.9905 3.88044 17.3714 3.80435H23.6952C24.1524 3.88044 24.381 4.18479 24.381 4.56522V6.08696" stroke="#222222" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </g>
    <defs>
      <clipPath id="clip0_5_10">
        <rect width="32" height="35" fill="white"/>
      </clipPath>
    </defs>
  </svg>
);

export const NavPreviewsIcon = (props) => (
  <svg width="38" height="36" viewBox="0 0 38 36" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M11.3889 4.77777H2.88889C1.85 4.77777 1 5.62777 1 6.66666V33.1111C1 34.15 1.85 35 2.88889 35H35C36.0389 35 36.8889 34.15 36.8889 33.1111V6.66666C36.8889 5.62777 36.0389 4.77777 35 4.77777H32.1667" stroke="#222222" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M11.3887 1V8.55556" stroke="#222222" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M26.5 1V8.55556" stroke="#222222" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M17.0557 4.77777H26.5001" stroke="#222222" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M29.3335 14.2222H31.2224" stroke="#222222" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M6.6665 14.2222H8.55539" stroke="#222222" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M14.2222 14.2222H16.1111" stroke="#222222" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M21.7778 14.2222H23.6667" stroke="#222222" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M29.3335 19.8889H31.2224" stroke="#222222" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M6.6665 19.8889H8.55539" stroke="#222222" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M14.2222 19.8889H16.1111" stroke="#222222" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M21.7778 19.8889H23.6667" stroke="#222222" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M6.6665 25.5556H8.55539" stroke="#222222" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M14.2222 25.5556H16.1111" stroke="#222222" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M21.7778 25.5556H23.6667" stroke="#222222" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
);

export const NavScenariosIcon = (props) => (
  <svg width="30" height="30" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512" fill="#222222" {...props}>
    <path d="M128 352H32c-17.67 0-32 14.33-32 32v96c0 17.67 14.33 32 32 32h96c17.67 0 32-14.33 32-32v-96c0-17.67-14.33-32-32-32zm-24-80h192v48h48v-48h192v48h48v-57.59c0-21.17-17.23-38.41-38.41-38.41H344v-64h40c17.67 0 32-14.33 32-32V32c0-17.67-14.33-32-32-32H256c-17.67 0-32 14.33-32 32v96c0 17.67 14.33 32 32 32h40v64H94.41C73.23 224 56 241.23 56 262.41V320h48v-48zm264 80h-96c-17.67 0-32 14.33-32 32v96c0 17.67 14.33 32 32 32h96c17.67 0 32-14.33 32-32v-96c0-17.67-14.33-32-32-32zm240 0h-96c-17.67 0-32 14.33-32 32v96c0 17.67 14.33 32 32 32h96c17.67 0 32-14.33 32-32v-96c0-17.67-14.33-32-32-32z"></path>
  </svg>
);

export const NavSellerIcon = (props) => (
  <svg width="36" height="30" xmlns="http://www.w3.org/2000/svg" fill="#222222" {...props}>
    <path d="M35.183 6.95L31.389.878A1.87 1.87 0 0 0 29.805 0H6.192a1.87 1.87 0 0 0-1.584.879L.815 6.949c-1.958 3.135-.222 7.494 3.437 7.992.263.036.532.053.8.053 1.73 0 3.262-.762 4.314-1.94a5.765 5.765 0 0 0 8.627 0 5.765 5.765 0 0 0 8.627 0 5.784 5.784 0 0 0 5.114 1.887c3.67-.492 5.413-4.851 3.449-7.992zm-4.238 9.925a7.399 7.399 0 0 1-1.724-.223V22.5H6.777v-5.848c-.561.13-1.14.223-1.724.223-.351 0-.708-.023-1.053-.07a7.335 7.335 0 0 1-.958-.211v11.531A1.87 1.87 0 0 0 4.912 30h26.185a1.87 1.87 0 0 0 1.87-1.875V16.594c-.315.093-.63.17-.958.21-.357.048-.707.071-1.064.071z" fillRule="nonzero"/>
  </svg>
);

export const NavStreamerIcon = (props) => (
  <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M19.7 24.7L25 21L15 15V27" stroke="#222222" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M20 40C30.4934 40 39 31.4934 39 21C39 10.5066 30.4934 2 20 2C9.50659 2 1 10.5066 1 21C1 31.4934 9.50659 40 20 40Z" stroke="#222222" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
);

export const NavReceptionistIcon = (props) => (
  <FontAwesomeIcon
    className="navIcon"
    icon={faClipboard}
    style={{
      fontSize: 40,
    }}
  />
);

export const NavReceptionIcon = (props) => (
  <FontAwesomeIcon
    className="navIcon"
    icon={faChalkboardTeacher}
    style={{
      fontSize: 40,
    }}
  />
);

export const NavThemesIcon = (props) => (
  <svg width="31" height="33" viewBox="0 0 31 33" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#clip0_6_12)">
      <path d="M16.4926 19.3005C15.0419 20.6628 13.5911 21.8739 12.2167 22.9335" stroke="#222222" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M12.2167 22.9335L7.86453 18.6193C9.84975 16.1216 11.9877 13.7752 14.2783 11.5803C21.532 4.31422 28.633 -0.378443 30.0074 0.983942C31.3818 2.34633 26.6478 9.30963 19.3177 16.5757C18.3251 17.5596 17.4089 18.4679 16.4926 19.3005" stroke="#222222" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M7.86453 18.6193L12.2167 22.9335" stroke="#222222" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M12.2168 22.8578C12.2168 22.8578 12.8276 26.8693 9.08622 27.8532C5.34484 28.8372 4.27587 28.9885 3.35962 32.1674C3.35962 32.1674 -1.67979 24.75 2.13794 20.9656C4.04681 19.0734 4.96307 18.9977 7.86454 18.4679L12.2168 22.8578Z" stroke="#222222" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M16.4926 19.3005L11.5295 14.3807" stroke="#222222" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </g>
    <defs>
      <clipPath id="clip0_6_12">
        <rect width="31" height="33" fill="white"/>
      </clipPath>
    </defs>
  </svg>
);

export const TrashIcon = (props) => (
  <svg width="15" height="20" viewBox="0 0 15 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M13.3669 6.56488L12.8173 17.5573C12.7486 18.3817 12.1302 19 11.3058 19H3.74857C2.92414 19 2.30582 18.3817 2.23712 17.5573L1.6875 6.56488" stroke="#ED2424" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M5.67188 8.28244V14.9466" stroke="#ED2424" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M9.38184 8.28244V14.9466" stroke="#ED2424" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M10.4807 4.36641H4.57227L5.6715 1H9.38143L10.4807 4.36641Z" stroke="#ED2424" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M1 4.43511H14.0534" stroke="#ED2424" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
);
