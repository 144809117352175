import React, { Component } from 'react';
import { Nav, NavItem } from 'react-bootstrap';
import moment from 'moment';

import './App.css';
import StreamerNav from './StreamerNav';
import firebase from './firebase';
import arrowLeftIcon from './img/icon/icon_arrow_left.svg';
import arrowRightIcon from './img/icon/icon_arrow_right.svg';

const db = firebase.firestore();
const LIMIT = 10;

export default class PreviewTrash extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lives: [],
      firstId: null,
      start: null,
      end: null,
    };
  }

  componentDidMount() {
    const uid = firebase.auth().currentUser.uid;
    this.livesRef = db.collection('lives').where('uid', '==', uid).where('isTest', '==', false).where('deletedAt', '!=', null);
    this.livesRef.orderBy('deletedAt', 'desc').limit(LIMIT).get().then((data) => {
      if (!data.empty) {
        this.setState({ firstId: data.docs[0].id });
        this.setData(data, false);
      }
    });
  }

  setData = (data, reverse) => {
    if (!data.empty) {
      const docs = reverse ? data.docs.reverse() : data.docs;
      const start = docs[0];
      let end = null;
      if (data.size === LIMIT) {
        end = docs[data.size - 1];
      }
      const lives = docs.map((doc) => doc.data());
      this.setState({ lives, start, end });
    }
  };

  prev = (e) => {
    e.preventDefault();
    this.livesRef.orderBy('deletedAt').startAfter(this.state.start).limit(LIMIT).get()
      .then((data) => this.setData(data, true));
  };

  next = (e) => {
    e.preventDefault();
    this.livesRef.orderBy('deletedAt', 'desc').startAfter(this.state.end).limit(LIMIT).get()
      .then((data) => this.setData(data, false));
  };

  restore = (e, liveId) => {
    if (window.confirm('選択されたライブを復元します。よろしいですか？')) {
      db.collection('lives').doc(liveId).update({ deletedAt: null });
      const lives = this.state.lives.filter((live) => live.id !== liveId);
      this.setState({ lives });
    }
  }

  render = () => (
    <div>
      <StreamerNav permission={this.props.permission}/>
      <div className="content previewTrash">
        <h2>ライブ予約</h2>
        <Nav bsStyle="pills" className="detail-nav nav-m-bk">
          <NavItem href="/streamer/previews">ライブ予約一覧</NavItem>
          <NavItem href="/streamer/previews/new">新しいライブ予約</NavItem>
          <NavItem href="/streamer/previews/trash" active={true}>削除済みライブ</NavItem>
        </Nav>
        <div className="card">
          <ul className="tableList">
            <li className="dtTablelist">
              <span className="dtIdCell">ID / タイトル</span>
              <span className="dtDateTimeCell">削除日時</span>
              <span className="dtDateTimeCell">配信日時</span>  
              <span className="previewTrash_dtButtonsCell" />
            </li>
            {this.state.lives.map((live) => (
              <li className="ddTablelist" key={live.id}>
                <span className="ddIdCell">
                  {live.id}
                  <span className="previews_liveName">{live.name}</span>
                </span>
                <span className="dateTimeCell">
                  {moment(live.deletedAt.toDate()).format('YYYY年M月D日')}
                  {moment(live.deletedAt.toDate()).format('HH:mm')}
                </span>
                <span className="dateTimeCell">
                  {moment(live.datetime.toDate()).format('YYYY年M月D日')}
                  {moment(live.datetime.toDate()).format('HH:mm')}
                </span>
                <span className="previewTrash_ddButtonsCell">
                  <a
                    className="normalBtn-strongColor inlineBtn"
                    onClick={(e) => this.restore(e, live.id)}
                    href="#/"
                  >
                    復元する
                  </a>
                </span>
              </li>
            ))}
          </ul>
          <div className="pager">
            <ul className="pagination">
              {this.state.start && this.state.start.id !== this.state.firstId &&
                <li className="prev">
                  <a href="#/" onClick={this.prev}>
                    <span>
                      <img src={arrowLeftIcon} className="mr10" alt="前へ" />
                      前へ
                    </span>
                  </a>
                </li>
              }
              {this.state.end &&
                <li className ="next">
                  <a href="#/" onClick={this.next}>
                    <span>
                      次へ
                      <img src={arrowRightIcon} className="ml10" alt="次へ" />
                    </span>
                  </a>
                </li>
              }
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
