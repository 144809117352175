import React, { Component } from 'react';

import firebase from './firebase';

const db = firebase.firestore();

class CtaItemLayer extends Component {
  constructor(props) {
    super(props);
    this.nCtaItems = 100;
    this.state = {
      allCtaEvents: [],
      ctaEvents: [],
    };
  }

  componentWillUnmount = () => {
    if (this.offCtaEvents) {
      this.offCtaEvents();
    }
  };

  fetchCtaEvents = () => {
    const query = db.doc(`${this.props.collection}/${this.props.id}`).collection('cta-events').orderBy('created', 'desc').limit(this.nCtaItems);
    if (this.props.session.live) {
      this.offCtaEvents = query.onSnapshot((data) => this.onCtaEvents(data.docs.reverse()));
    } else {
      query.get().then((data) => this.onCtaEvents(data.docs.reverse())).catch((error) => console.error(error));
    }
  };

  onCtaEvents = (docs) => {
    const started = (this.props.session.originalCreated || this.props.session.created).toMillis();
    const allCtaEvents = docs.map((doc) => {
      return doc.data();
    }).filter((ctaEvent) => {
      return started <= ctaEvent.created.toMillis();
    });
    this.setState({ allCtaEvents });
  };

  onTimeUpdate = (currentTime) => {
    if (this.props.session.live ? !this.offCtaEvents : !this.state.allCtaEvents.length) {
      this.fetchCtaEvents();
    }
    const ctaEvents = [...this.state.allCtaEvents].filter((ctaEvent) => (
      ctaEvent.created.toMillis() <= currentTime
    )).reduce((events, event) => {
      const index = events.findIndex((e) => e.itemId === event.itemId);
      if (index < 0) {
        events.push(Object.assign(event, {
          item: this.props.items.find((i) => i.id === event.itemId),
        }));
      } else {
        events.splice(index, 1);
      }
      return events;
    }, []).filter((ctaEvent) => ctaEvent.display && ctaEvent.item);
    ctaEvents.forEach((ctaEvent) => { //セール価格を表示するかの判定
      if (ctaEvent.item.salePrice && ctaEvent.item.salePriceEffectiveDate) {
        const salePriceEffectiveDate = ctaEvent.item.salePriceEffectiveDate.split('/').map((e) => new Date(e).getTime()).sort();
        const currentDateTime = new Date().getTime();
        if (salePriceEffectiveDate[0] <= currentDateTime && currentDateTime <= salePriceEffectiveDate[1]) {
          ctaEvent.item.salePriceFlag = true;
        }
      }
    });
    this.setState({ ctaEvents });
  };

  render = () => {
    const { ctaEvents } = this.state;
    const itemWidthRatio = 0.2;
    const ctaItemImageWidth = this.props.width * itemWidthRatio;
    const styles = {
      ctaItemLayer: {
        position: 'absolute',
        display: 'flex',
        flexDirection: 'column',
        margin: '43px 0 0 -3px',
        width: '20%',
        zIndex: 1,
        pointerEvents: 'none',
        top: 0
      },
      ctaItemBox: {
        minHeight: '0%', // for IE11 layout problem
      },
      ctaItemImage: {
        borderRadius: 2,
        width: '100%',
        height: this.props.itemImgRatio === '1x1' ? ctaItemImageWidth : ctaItemImageWidth * 1.2,
        margin: 0,
        objectFit: 'cover',
      },
      ctaItemLink: {
        border: 'none',
        textDecoration: 'none',
        pointerEvents: 'initial',
        lineHeight: 0,
        outline: 'none',
        borderRadius: 3,
        position: 'relative',
        margin: '0 0 -20% 0',
        overflow: 'hidden',
        display: 'block',
        transform: 'scale(0.7)',
      },
      priceSpan: {
        position: 'absolute',
        left: 0,
        bottom: 3,
        width: 'calc(100% - 6px)',
        background: 'rgba(100, 100, 100, 0.8)',
        display: 'block',
        lineHeight: 1,
        color: '#fff',
        fontSize: 8,
        textAlign: 'center',
        padding: '2px 3px 2px 1px,',
        boxSizing: 'border-box',
        borderRadius: 2,
        right: 0,
        margin: '0 auto',
      },
      priceI: {
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: 8,
      },
    };
    return (
      <div style={styles.ctaItemLayer}>
        {ctaEvents && ctaEvents.map((ctaEvent, index) => (
          <div
            key={index}
            style={styles.ctaItemBox}
          >
            <a
              href={ctaEvent.item.url}
              rel="noopener noreferrer"
              target="_blank"
              style={styles.ctaItemLink}
            >
              <span style={styles.priceSpan}>
                <i style={styles.priceI}>￥{Number(ctaEvent.item.salePriceFlag ? ctaEvent.item.salePrice : ctaEvent.item.price).toLocaleString()}</i>
              </span>
              <img
                alt="item"
                src={ctaEvent.item.image}
                style={styles.ctaItemImage}
              />
            </a>
          </div>
        ))}
      </div>
    );
  };
};

export default CtaItemLayer;
