import React, { Component } from 'react';
import { Nav, NavItem } from 'react-bootstrap';
import { Redirect } from 'react-router';
import classNames from 'classnames'; 
import firebase from './firebase';
import model from './Model';
import {
  NavStreamerIcon,
  NavPreviewsIcon,
  NavSellerIcon,
  NavReceptionIcon,
 } from './Icon';

export default class StreamerNav extends Component {
  constructor(props) {
    super(props);
    this.state = {
      toAgreement: false,
    };
  }

  componentDidMount() {
    model.collection('shops').where('uid', '==', firebase.auth().currentUser.uid).get().then((data) => {
      if (!data.empty) {
        const shop = data.docs[0].data();
        if (!shop.agreementFlag && window.location.pathname !== '/streamer') {
          this.setState({ toAgreement: true });
          return;
        }
      }
    });
  }

  isNavItemSelected = (itemName) => {
    const path = window.location.pathname;
    if (itemName === "streamer") {
      return path === "/streamer";
    }
    if (itemName === "previews") {
      return (
        path.includes("/streamer/previews")
        || path.includes("streamer/live")
      );
    }
    return false;
  }

  getNavItemClassName = (itemName) =>
    classNames(
      "navItem",
      this.isNavItemSelected(itemName) && "navItem-selected"
    );

  render() {
    if (this.state.toAgreement) {
      return <Redirect to={{ pathname: "/agreement"}} />;
    }

    return (
      <Nav bsStyle="pills" className="navBar">
        <NavItem href="/streamer" className={this.getNavItemClassName("streamer")}>
          <NavStreamerIcon className="navIcon" />
          <span className="text-center">
            ストリーマー情報
          </span>
        </NavItem>
        <NavItem href="/streamer/previews" className={this.getNavItemClassName("previews")}>
          <NavPreviewsIcon className="navIcon" />
          <span className="text-center">
            ライブ予約
          </span>
        </NavItem>
        {this.props.permission.seller && (
          <NavItem href="/" className={this.getNavItemClassName("seller")}>
            <NavSellerIcon className="navIcon navIcon-fill" />
            <span className="text-center">
              TAGsAPI for Sellerへ
            </span>
          </NavItem>
        )}
        {this.props.permission.reception && (
          <NavItem href="/receptionist" className={this.getNavItemClassName("receptionist")}>
            <NavReceptionIcon className="navIcon navIcon-fill" />
            <span className="text-center">
              TAGsAPI for Receptionistへ
            </span>
          </NavItem>
        )}
      </Nav>
    );
  }
}
