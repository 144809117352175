import React, { Component } from 'react';
import { Button, DropdownButton, MenuItem, Nav, NavItem } from 'react-bootstrap';
import moment from 'moment';

import './App.css';
import SellerNav from './SellerNav';
import firebase from './firebase';
import model from './Model';

const db = firebase.firestore();

export default class Earnings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      shopIndex: 0,
      shops: [],
      monthIndex: 0,
      months: [],
      orders: [],
      earnings: 0,
      users: 0,
    };
  }

  componentDidMount() {
    const today = moment();
    const year = today.year();
    const months = [];
    for (let m = 0; m <= today.month(); m++) {
      const startDate = moment([year, m]);
      const endDate = moment(startDate).endOf('month');
      months.push({ startDate, endDate, name: `${year}年${m + 1}月` });
    }
    this.setState({ months, monthIndex: today.month() });

    const uid = firebase.auth().currentUser.uid;
    db.collection('shops').where('admin', '==', uid).get().then((data) => {
      if (!data.empty) {
        this.setState({ shops: model.listData(data) });
      } else {
        db.collection('shops').where('uid', '==', uid).get().then(
          (data) => this.setState({ shops: model.listData(data) }));
      }
    });
  }

  selectShop = (shopIndex) => this.setState({ shopIndex });

  selectMonth = (monthIndex) => this.setState({ monthIndex });

  sumEarnings = (orders) => {
    return orders.reduce((sum, order) => {
      if (order.status === 'fullfilled' || order.status === 'returnSuccess') {
        return sum + order.price - (order.refundPrice ? order.refundPrice : 0);
      } else {
        return sum;
      }
    }, 0);
  };

  sumUsers = (orders) => {
    const users = orders.reduce((users, order) => {
      if (!(order.uid in users)) {
        users[order.uid] = true;
      }
      return users;
    }, {});
    return Object.keys(users).length;
  };

  queryEarnings = () => {
    const month = this.state.months[this.state.monthIndex];
    db.collection('orders').where('shopId', '==', this.state.shops[this.state.shopIndex].id)
      .where('created', '>', month.startDate.toDate()).where('created', '<', month.endDate.toDate())
      .get().then((data) => {
        const orders = model.listData(data);
        const earnings = this.sumEarnings(orders);
        const users = this.sumUsers(orders);
        this.setState({ orders, earnings, users });
      });
  };

  renderOrders = () => (
    <ul>
      {this.state.orders.map((order) => (
        <li key={order.id}>
         <span className="cell">{model.orderStatus(order.status)}</span>
         <span className="cell">{moment(order.created.toDate()).format('YYYY年M月D日')}</span>
         <span className="nameCell">{order.name}</span>
         <span className="cell">¥{order.price}</span>
         <a className="cell" href={`/orders/${order.id}`}>詳しく見る</a>
        </li>
      ))}
    </ul>
  );

  render() {
    const state = this.state;
    return (
      <div>
      <SellerNav permission={this.props.permission}/>
      <div className="content">
        <h2>売上管理</h2>
        <Nav bsStyle="pills">
          <NavItem href="/earnings">売上照会</NavItem>
          <NavItem href="/payout">振込口座設定</NavItem>
        </Nav>
        <div className="card">
        {state.shops.length > 0 &&
        <DropdownButton title={state.shops[state.shopIndex].name} id="shop">
          {state.shops.map((shop, i) => (
            <MenuItem key={i} eventKey={i} active={i === state.shopIndex}
              onSelect={this.selectShop}>
              {shop.name}
            </MenuItem>
          ))}
        </DropdownButton>}

        {state.months.length > 0 &&
        <DropdownButton title={state.months[state.monthIndex].name} id="month">
          {state.months.map((month, i) => (
            <MenuItem key={i} eventKey={i} active={i === state.monthIndex}
              onSelect={this.selectMonth}>
              {month.name}
            </MenuItem>
          ))}
        </DropdownButton>}

        {state.shops.length > 0 && state.months.length > 0 &&
        <Button type="button" bsStyle="warning" onClick={this.queryEarnings}>照会</Button>}

        <h4>月売上：¥{state.earnings}</h4>
        <h4>手数料10%：¥{Math.round(state.earnings * 0.1)}</h4>
        <h4>月ユーザー数：{state.users}</h4>

        <h4>注文履歴：</h4>
        {state.orders.length > 0 && this.renderOrders()}
        </div>
      </div>
      </div>
    );
  }
}
