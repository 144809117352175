import React, { Component } from 'react';
import { Nav, NavItem } from 'react-bootstrap';

import SellerNav from './SellerNav';
import firebase from './firebase';

const db = firebase.firestore();

export default class Themes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      themes: [],
    };
  }

  componentDidMount() {
    const uid = firebase.auth().currentUser.uid;
    db.collection('admins').doc(uid).get().then((admin) => {
      const query = admin.exists ? db.collection('themes') : db.collection('themes').where('uid', '==', uid);
      query.get().then((querySnapshot) => {
        const themes = querySnapshot.docs.map((documentSnapshot) => documentSnapshot.data());
        this.setState({ themes });
      });
    });
  }

  render = () => (
    <div>
      <SellerNav permission={this.props.permission}/>
      <div className="content">
        <h2>テーマ管理</h2>
        <Nav bsStyle="pills" className="detail-nav nav-m-bk">
          <NavItem href="/themes" active><strong>テーマ一覧</strong></NavItem>
          <NavItem href="/themes/new">新しいテーマ</NavItem>
        </Nav>
        <div className="card">
          <ul>
            <li className="dtTablelist">
              <span className="inlineBlock w200">ID</span>
              <span className="inlineBlock w110">アイテム表示</span>
            </li>
            {this.state.themes.map((theme) => (
              <li className="ddTablelist" key={theme.id}>
                <span className="inlineBlock w200">
                  {theme.id}
                </span>
                <span className="inlineBlock w110">
                  {theme.itemListStyle}
                </span>
                <a className="normalBtn inlineBtn" href={`/themes/edit/${theme.id}`}>編集する</a>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
}