import React, { Component } from 'react';
import helpIcon from './img/icon/icon_question.svg';
import cancelIcon from './img/icon/icon_cancel.svg';

const urlPatternMsg = (
        <span>文字列または正規表現によりご入力いただけます。<br />文字列は部分一致による判定となりますので、ご入力いただいた文字列がURLに含まれている場合に対象となります。<br /><br />
        例：tagfabと入力した場合<br />URLにtagfabが含まれているものが対象となります。<br /><br />
        https://shop.<span style={{color:'#ed5454'}}>tagfab</span>.jp<br />
        https://shop.jp/<span style={{color:'#ed5454'}}>tagfab</span>/...<br /><br />
        複数のURLパターンをご入力される場合はそれぞれ改行してからご入力ください。<br /><br /></span>
      );

const styles = {
  helpIcon: {
    position: 'relative',
  },
  cancelIcon: {
    position: 'absolute',
    top: '2%',
    right: '3%',
  },
  modal: {
    position: 'absolute',
    bottom: '110%',
    right: '-90%',
    left: '10%',
    padding: '40px 20px 20px',
    zIndex: 1,
    backgroundColor: '#f7f7f7',
    borderRadius: '10px',
    border: 'solid 1px #000',
    textAlign: 'left',
    fontWeight: 'normal',
    animationName: 'fade-in',
    animationDuration: '.3s',
  },
  modalBefore: {
    content: '',
    position: 'absolute',
    display: 'block',
    width: 0,
    height: 0,
    right: '48.8%',
    bottom: '-16px',
    margin:'0 auto',
    borderTop: '15px solid #000',
    borderRight: '15px solid transparent',
    borderLeft: '15px solid transparent',
  },
  modalAfter: {
    content: '',
    position: 'absolute',
    display: 'block',
    width: 0,
    height: 0,
    right: '48.8%',
    bottom: '-15px',
    margin:'0 auto',
    borderTop: '15px solid #f7f7f7',
    borderRight: '15px solid transparent',
    borderLeft: '15px solid transparent',
  }
}

export default class Help extends Component {
  constructor(props) {
    super(props);
    this.dialog = null;
    this.state = {
      isOpen: false,
    };
  }

  componentDidMount = async () => {
    const dialog = this.dialog
    document.addEventListener('click', event => this.onClicked(event, dialog));
  }

  onClicked = (event, dialog) => {
    var isClickInsideElement = dialog.contains(event.target);
    if (!isClickInsideElement && this.state.isOpen) {
      this.setState({ isOpen: false });
    }
  }

  onModal = (e) => {
    e.preventDefault();
    this.setState({ isOpen: !this.state.isOpen });
  };

  render() {
    return (
      <div ref={ref => this.dialog = ref}>
        <style>{`@keyframes fade-in { from { opacity: 0; } to { opacity: 1; }}`}</style>
        <div style={{...styles.modal, display: this.state.isOpen ? 'block' : 'none' }}>
          <a style={styles.cancelIcon} onClick={this.onModal} href="#/"><img src={cancelIcon} alt="close_icon"/></a>

          {urlPatternMsg}
          <span style={styles.modalBefore} />
          <span style={styles.modalAfter} />
        </div>
        <a style={styles.helpIcon} onClick={this.onModal} href="#/"><img src={helpIcon} alt="help_icon"/></a>
      </div>
    );
  }
}
