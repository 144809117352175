import React, { Component } from 'react';
import { Checkbox, DropdownButton, MenuItem, Nav, NavItem } from 'react-bootstrap';
import moment from 'moment';

import './App.css';
import StreamerNav from './StreamerNav';
import firebase from './firebase';
import arrowLeftIcon from './img/icon/icon_arrow_left.svg';
import arrowRightIcon from './img/icon/icon_arrow_right.svg';

const db = firebase.firestore();
const LIMIT = 10;

export default class Previews extends Component {
  constructor(props) {
    super(props);
    const state = props.location.state;
    this.state = {
      lives: [],
      deliveredLiveIds: [],
      uploadedLiveIds: [],
      firstId: null,
      start: null,
      end: null,
      isEnd: true,
      added: state ? state.added : false,
      testId: '',
      checkedItems: []
    };
  }

  componentDidMount() {
    const uid = firebase.auth().currentUser.uid;
    this.livesRef = db.collection('lives').where('uid', '==', uid).where('isTest', '==', false).where('deletedAt', '==', null);
    this.livesRef.orderBy('datetime', 'desc').limit(LIMIT).get().then((data) => {
      if (!data.empty) {
        this.setState({ firstId: data.docs[0].id });
        this.setData(data, false);
        this.setDeliveredLiveIds();
      }
    });
    db.collection('lives').where('uid', '==', uid).where('isTest', '==', true).get().then((data) => {
      if (!data.empty) {
        this.setState({ testId: data.docs[0].id });
      }
    });
  }

  setData = async (data, reverse) => {
    if (!data.empty) {
      const docs = reverse ? data.docs.reverse() : data.docs;
      const start = docs[0];
      let end = null;
      let isEnd = true;
      if (data.size === LIMIT) {
        end = docs[data.size - 1];
        const last = await this.livesRef.orderBy('datetime', 'desc').startAfter(end).limit(1).get();
        isEnd = last.empty;
      }
      const lives = docs.map((doc) => doc.data());
      this.setState({ lives, start, end, isEnd, checkedItems: [] });
      this.setDeliveredLiveIds();
    }
  };

  setDeliveredLiveIds = () => {
    const uid = firebase.auth().currentUser.uid;
    this.state.lives.forEach((live) => {
      db.collection('sessions')
        .where('uid', '==', uid)
        .where('liveId', '==', live.id)
        .where('stabilized', '!=', null)
        .orderBy('stabilized', 'desc')
        .orderBy('created', 'desc')
        .limit(1)
        .get()
        .then((data) => {
          if (data.empty) {
            return;
          }
          const session = data.docs[0].data();
          if (session.originalCreated) {
            if (this.state.uploadedLiveIds.includes(live.id)) {
              return;
            }
            this.setState({ uploadedLiveIds: [...this.state.uploadedLiveIds, live.id] });  
          } else {
            if (this.state.deliveredLiveIds.includes(live.id)) {
              return;
            }
            this.setState({ deliveredLiveIds: [...this.state.deliveredLiveIds, live.id] });
          }
        });
    });
  };

  prev = () => {
    this.livesRef.orderBy('datetime').startAfter(this.state.start).limit(LIMIT).get()
      .then((data) => this.setData(data, true));
  };

  next = () => {
    this.livesRef.orderBy('datetime', 'desc').startAfter(this.state.end).limit(LIMIT).get()
      .then((data) => this.setData(data, false));
  };

  removeChecked = (e) => {
    if (!window.confirm(`選択されたライブを削除します。よろしいですか？`)) {
      return;
    }
    this.state.checkedItems.forEach((liveId) => {
      db.collection('lives').doc(liveId).update({
        deletedAt: firebase.firestore.FieldValue.serverTimestamp(),
      });
    });
    const lives = this.state.lives.filter(live => !this.state.checkedItems.includes(live.id));
    this.setState({ lives, checkedItems: [] });
  }

  updateChecked = async (e, option) => {
    let label = null;
    switch (option) {
      case 'public': {
        label = '公開';
        break;
      }
      case 'unlist': {
        label = '限定公開';
        break;
      }
      case 'privateMode': {
        label = '非公開';
        break;
      }
      default: {
        break;
      }
    }
    if (label) {
      if (!window.confirm(`選択されたライブを「${label}」に変更します。よろしいですか？`)) {
        return;
      }
    }
    let releaseOption = {}
    if (option === 'public') {
      releaseOption = {
        unlist: false,
        privateMode: false,
      }
    } else {
      releaseOption = {
        unlist: option === 'unlist',
        privateMode: option === 'privateMode',
      }
    }
    
    function updateLives(lives, liveId, releaseOption) {
      return lives.map(live => {
        if (live.id === liveId) {
          return {...live, ...releaseOption};
        }
        return live;
      });
    }
    for (var liveId of this.state.checkedItems) {
      await db.collection('lives').doc(liveId).update(releaseOption);
      const lives = updateLives(this.state.lives, liveId, releaseOption);
      this.setState({ lives, checkedItems: [] });
    }
  }

  onChangeCheckItem = (e) => {
    const val = e.target.value;
    let checkedItems = [...this.state.checkedItems];
    if (val === 'all') {
      if (e.target.checked) {
        checkedItems = this.state.lives.map(live => live.id);
      } else {
        checkedItems = [];
      }
    } else {
      if (checkedItems.includes(val)) {
        checkedItems.splice(checkedItems.indexOf(val), 1);
      } else {
        checkedItems.push(val);
      }
    }
    this.setState({ checkedItems });
  }

  render = () => (
    <div>
      <StreamerNav permission={this.props.permission}/>
      <div className="content previews">
        <h2>ライブ予約</h2>
        <Nav bsStyle="pills" className="detail-nav nav-m-bk">
          <NavItem href="/streamer/previews" active><strong>ライブ予約一覧</strong></NavItem>
          <NavItem href="/streamer/previews/new">新しいライブ予約</NavItem>
          <NavItem href="/streamer/previews/trash">削除済みライブ</NavItem>
        </Nav>
        <div className="previews_detailNavButtonsArea">
            <a className="normalBtn inlineBtn " href="/streamer/report">アカウントレポート</a>
          </div>
        <div className="card">
          {this.state.added && <p className="message green">ライブが登録されました</p>}
          <ul className="tableList">
          {this.state.testId && (
            <li className="testLiveList">
              <span className="dtIdCell">
                {this.state.testId}
                <span className="previews_liveListTestId">テスト用IDになります</span>
              </span>
              <span className="dtDateTimeCell">―</span>
              <span className="previews_ddButtonsCell">
                <a className="normalBtn inlineBtn btn-narrow"
                  href={`/streamer/previews/edit/${this.state.testId}`}>編集する</a>
                <a className="normalBtn-strongColor inlineBtn btn-narrow"
                  href={`/streamer/live/${this.state.testId}`}>ライブ配信</a>
              </span>
            </li>)}
            <li className="dtTablelist">
              <span className="checkboxCell">
                <Checkbox className="nomargin" name="checkItem" value="all" onChange={this.onChangeCheckItem} checked={this.state.checkedItems.length === this.state.lives.length} />
              </span>
              <span className="dtIdCell">ID / タイトル</span>
              <span className="dtDateTimeCell">配信日時</span>
              <span className="previews_dtButtonsCell">
                <DropdownButton title="操作" disabled={!this.state.checkedItems.length}>
                  <MenuItem onSelect={(e) => this.updateChecked(e, 'public')}>公開</MenuItem>
                  <MenuItem onSelect={(e) => this.updateChecked(e, 'unlist')}>限定公開</MenuItem>
                  <MenuItem onSelect={(e) => this.updateChecked(e, 'privateMode')}>非公開</MenuItem>
                  <MenuItem onSelect={(e) => this.removeChecked(e)}>削除</MenuItem>
                </DropdownButton>
              </span>
            </li>
            {this.state.lives.map((live) => (
              <li className="ddTablelist" key={live.id}>
                <span className="checkboxCell">
                  <Checkbox name="checkItem" value={live.id} onChange={this.onChangeCheckItem} checked={this.state.checkedItems.includes(live.id)} />
                </span>
                <span className="ddIdCell">
                  {live.id}
                  <span className="previews_liveName">{live.name}</span>
                </span>
                <span className="dateTimeCell">
                  {(!live.unlist && !live.privateMode) && (
                    <span className="delivered">公開</span>
                  )}
                  {live.unlist && (
                    <span className="delivered">限定公開</span>
                  )}
                  {live.privateMode && (
                    <span className="delivered">非公開</span>
                  )}
                  {this.state.deliveredLiveIds.includes(live.id) && (
                    <span className="delivered ml5">配信済み</span>
                  )}
                  {this.state.uploadedLiveIds.includes(live.id) && (
                    <span className="delivered ml5">アップロード済み</span>
                  )}
                  <div>
                    {moment(live.datetime.toDate()).format('YYYY年M月D日')}
                    {moment(live.datetime.toDate()).format('HH:mm')}
                  </div>
                </span>
                <span className="previews_ddButtonsCell">
                  <a className="normalBtn inlineBtn btn-narrow" href={`/streamer/previews/edit/${live.id}`}>編集する</a>
                  <a className="normalBtn inlineBtn btn-narrow" href={`/streamer/messages/${live.id}`}>コメント管理</a>
                  <a className="normalBtn inlineBtn btn-narrow" href={`/streamer/apidata/${live.id}`}>レポート</a>
                  <a className="normalBtn-strongColor inlineBtn btn-narrow" href={`/streamer/live/${live.id}`}>ライブ配信</a>
                </span>
              </li>
            ))}
          </ul>
          <div className="pager">
            <ul className="pagination">
              {this.state.start && this.state.start.id !== this.state.firstId &&
                <li className="prev">
                  <a href="#/" onClick={this.prev}>
                    <span>
                      <img src={arrowLeftIcon} className="mr10" alt="前へ" />
                      前へ
                    </span>
                  </a>
                </li>
              }
              {this.state.end && !this.state.isEnd &&
                <li className ="next">
                  <a href="#/" onClick={this.next}>
                    <span>
                      次へ
                      <img src={arrowRightIcon} className="ml10" alt="次へ" />
                    </span>
                  </a>
                </li>
              }
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
