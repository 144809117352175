import moment from 'moment';

export const ApiCSVData = (target, data, enumerateDays) => {
  let csv = [];

  if (target === 'ApiData') {

    const liveID = ['liveID:',data.liveId];
    csv.push(liveID);
    const measurementPeriod = ['計測期間:', data.startDate.format("YYYY年MM月DD日"), '~', data.endDate.format("YYYY年MM月DD日")];
    csv.push(measurementPeriod);
    const dailyReportHeader = [
      ['日付別レポート','','','','','','','','','','','','','','','','','','','','','','','',''],
      ['','ウェイティング','','','','','','','','ライブ再生','','','','','','','','アーカイブ再生','','','','','','','','ライブ・アーカイブ再生','','','','','','',''],
      ['','訪問','商品クリック','掲載商品購入','','','全商品商品購入','','','動画再生','商品クリック','掲載商品購入','','','全商品商品購入','','','動画再生	','商品クリック','掲載商品購入','','','全商品商品購入','','','動画再生	','商品クリック','掲載商品購入','','','全商品商品購入','',''],
      ['日付','人数','クリック人数','購入個数','購入金額','購入者数','購入個数','購入金額','購入者数','人数','クリック人数','購入個数','購入金額','購入者数','購入個数','購入金額	','購入者数','人数','クリック人数','購入個数','購入金額','購入者数','購入個数','購入金額','購入者数','人数','クリック人数','購入個数','購入金額','購入者数','購入個数','購入金額','購入者数'],
    ];
    dailyReportHeader.forEach((unit) => csv.push(unit));

  } else {

    const domain = ['ドメイン:',data.domain];
    csv.push(domain);
    const measurementPeriod = ['計測期間:', data.startDate.format("YYYY年MM月DD日"), '~', data.endDate.format("YYYY年MM月DD日")];
    csv.push(measurementPeriod);
    const dailyReportHeader = [
      ['アカウントレポート','','','','','','','','','','','','','','','','','','','','','','','',''],
      ['','ウェイティング','','','','','','','','ライブ再生','','','','','','','','アーカイブ再生','','','','','','','','ライブ・アーカイブ再生','','','','','','',''],
      ['','訪問','商品クリック','掲載商品購入','','','全商品商品購入','','','動画再生','商品クリック','掲載商品購入','','','全商品商品購入','','','動画再生	','商品クリック','掲載商品購入','','','全商品商品購入','','','動画再生	','商品クリック','掲載商品購入','','','全商品商品購入','',''],
      ['','人数','クリック人数','購入個数','購入金額','購入者数','購入個数','購入金額','購入者数','人数','クリック人数','購入個数','購入金額','購入者数','購入個数','購入金額	','購入者数','人数','クリック人数','購入個数','購入金額','購入者数','購入個数','購入金額','購入者数','人数','クリック人数','購入個数','購入金額','購入者数','購入個数','購入金額','購入者数'],
    ];
    dailyReportHeader.forEach((unit) => csv.push(unit));
  }
  const dailyReportTotal = [
    'total',
    data.previewUsers['total'],
    data.previewClicks['total'],
    data.purchaseData.preview.liveQuan['total'] || 0,
    data.purchaseData.preview.liveSum['total'] || 0,
    data.purchaseData.preview.liveNum['total'] || 0,
    data.purchaseData.preview.allQuan['total'] || 0,
    data.purchaseData.preview.allSum['total'] || 0,
    data.purchaseData.preview.allNum['total'] || 0,

    data.liveUsers['total'],
    data.liveClicks['total'],
    data.purchaseData.live.liveQuan['total'] || 0,
    data.purchaseData.live.liveSum['total'] || 0,
    data.purchaseData.live.liveNum['total'] || 0,
    data.purchaseData.live.allQuan['total'] || 0,
    data.purchaseData.live.allSum['total'] || 0,
    data.purchaseData.live.allNum['total'] || 0,

    data.archiveUsers['total'],
    data.archiveClicks['total'],
    data.purchaseData.archive.liveQuan['total'] || 0,
    data.purchaseData.archive.liveSum['total'] || 0,
    data.purchaseData.archive.liveNum['total'] || 0,
    data.purchaseData.archive.allQuan['total'] || 0,
    data.purchaseData.archive.allSum['total'] || 0,
    data.purchaseData.archive.allNum['total'] || 0,
    data.liveArchiveUsers['total'],
    data.liveArchiveClicks['total'],
    (data.purchaseData.live.liveQuan['total'] || 0) + (data.purchaseData.archive.liveQuan['total'] || 0),
    (data.purchaseData.live.liveSum['total'] || 0) + (data.purchaseData.archive.liveSum['total'] || 0),
    data.purchaseData.liveArchive.liveNum['total'] || 0,
    (data.purchaseData.live.allQuan['total'] || 0) + (data.purchaseData.archive.allQuan['total'] || 0),
    (data.purchaseData.live.allSum['total'] || 0) + (data.purchaseData.archive.allSum['total'] || 0),
    data.purchaseData.liveArchive.allNum['total'] || 0,
  ];
  csv.push(dailyReportTotal);

  if (target === 'ApiData') {
    enumerateDays.forEach((date) => {
      csv.push([
        date,
        'N/A',
        'N/A',
        data.purchaseData.preview.liveQuan[date] || 0,
        data.purchaseData.preview.liveSum[date] || 0,
        Object.keys(data.purchaseData.preview.liveNum[date] || {}).length,
        data.purchaseData.preview.allQuan[date] || 0,
        data.purchaseData.preview.allSum[date] || 0,
        Object.keys(data.purchaseData.preview.allNum[date] || {}).length,
        Object.keys(data.liveUsers[date] || {}).length,
        Object.keys(data.liveClicks[date] || {}).length,
        data.purchaseData.live.liveQuan[date] || 0,
        data.purchaseData.live.liveSum[date] || 0,
        Object.keys(data.purchaseData.live.liveNum[date] || {}).length,
        data.purchaseData.live.allQuan[date] || 0,
        data.purchaseData.live.allSum[date] || 0,
        Object.keys(data.purchaseData.live.allNum[date] || {}).length,
        Object.keys(data.archiveUsers[date] || {}).length,
        Object.keys(data.archiveClicks[date] || {}).length,
        data.purchaseData.archive.liveQuan[date] || 0,
        data.purchaseData.archive.liveSum[date] || 0,
        Object.keys(data.purchaseData.archive.liveNum[date] || {}).length,
        data.purchaseData.archive.allQuan[date] || 0,
        data.purchaseData.archive.allSum[date] || 0,
        Object.keys(data.purchaseData.archive.allNum[date] || {}).length,
        Object.keys(data.liveArchiveUsers[date] || {}).length,
        Object.keys(data.liveArchiveClicks[date] || {}).length,
        (data.purchaseData.archive.liveQuan[date] || 0) + (data.purchaseData.live.liveQuan[date] || 0),
        (data.purchaseData.archive.liveSum[date] || 0) + (data.purchaseData.live.liveSum[date] || 0),
        Object.keys(data.purchaseData.liveArchive.liveNum[date] || {}).length,
        (data.purchaseData.archive.allQuan[date] || 0) + (data.purchaseData.live.allQuan[date] || 0),
        (data.purchaseData.archive.allSum[date] || 0) + (data.purchaseData.live.allSum[date] || 0),
        Object.keys(data.purchaseData.liveArchive.allNum[date] || {}).length,
      ]);
    });
  }

  if (target === 'ApiData') {
    const liveReportHeader = [
      ['','','','','','','','','',''],
      ['掲載商品別レポート','','','','','','','','',''],
      ['','','ウェイティング','','ライブ再生','','アーカイブ再生','','合計',''],
      ['SKU ID','商品名','購入個数','購入金額（円）','購入個数','購入金額（円）','購入個数','購入金額（円）','購入個数','購入金額（円）'],
    ];
    liveReportHeader.forEach((unit) => csv.push(unit));

    data.unitIds.forEach((unitId) => {
      csv.push([
        unitId,
        data.unitStats[unitId].name,
        data.unitStats[unitId].preview.quantity,
        data.unitStats[unitId].preview.price,
        data.unitStats[unitId].live.quantity,
        data.unitStats[unitId].live.price,
        data.unitStats[unitId].archive.quantity,
        data.unitStats[unitId].archive.price,
        data.unitStats[unitId].preview.quantity +
        data.unitStats[unitId].live.quantity +
        data.unitStats[unitId].archive.quantity,
        data.unitStats[unitId].preview.price +
        data.unitStats[unitId].live.price +
        data.unitStats[unitId].archive.price,
      ]);
    });

    const skuReportTotal = [
      '合計',
      '',
      data.unitStats.total.preview.quantity || 0,
      data.unitStats.total.preview.price || 0,
      data.unitStats.total.live.quantity || 0,
      data.unitStats.total.live.price || 0,
      data.unitStats.total.archive.quantity || 0,
      data.unitStats.total.archive.price || 0,
      data.unitStats.total.total.quantity || 0,
      data.unitStats.total.total.price || 0,
    ];
    csv.push(skuReportTotal);

  } else {

    const liveReportHeader = [
      ['','','','','','','','','','','','','','','','','','','','','','','','','','',''],
      ['LIVE別レポート','','','','','','','','','','','','','','','','','','','','','','','','','',''],
      ['','','','ウェイティング','','','','','','','','ライブ再生','','','','','','','','アーカイブ再生','','','','','','','','ライブ・アーカイブ再生','','','','','','',''],
      ['','','','訪問','商品クリック','掲載商品購入','','','全商品商品購入','','','動画再生','商品クリック','掲載商品購入','','','全商品商品購入','','','動画再生	','商品クリック','掲載商品購入','','','全商品商品購入','','','動画再生	','商品クリック','掲載商品購入','','','全商品商品購入','',''],
      ['Live ID','タイトル','日付','人数','クリック人数','購入個数','購入金額','購入者数','購入個数','購入金額','購入者数','人数','クリック人数','購入個数','購入金額','購入者数','購入個数','購入金額	','購入者数','人数','クリック人数','購入個数','購入金額','購入者数','購入個数','購入金額','購入者数','人数','クリック人数','購入個数','購入金額','購入者数','購入個数','購入金額','購入者数'],
    ];
    liveReportHeader.forEach((unit) => csv.push(unit));

    data.lives && data.lives.forEach((live) => {
      csv.push([
        live.id,
        live.name,
        moment(live.datetime.toDate()).utcOffset('+0900').format('YYYY年M月D日HH:mm'),
        Object.keys(data.previewUsers[live.id] || {}).length,
        Object.keys(data.previewClicks[live.id] || {}).length,
        data.purchaseData.preview.liveQuan[live.id] || 0,
        data.purchaseData.preview.liveSum[live.id] || 0,
        Object.keys(data.purchaseData.preview.liveNum[live.id] || {}).length,
        data.purchaseData.preview.allQuan[live.id] || 0,
        data.purchaseData.preview.allSum[live.id] || 0,
        Object.keys(data.purchaseData.preview.allNum[live.id] || {}).length,
        Object.keys(data.liveUsers[live.id] || {}).length,
        Object.keys(data.liveClicks[live.id] || {}).length,
        data.purchaseData.live.liveQuan[live.id] || 0,
        data.purchaseData.live.liveSum[live.id] || 0,
        Object.keys(data.purchaseData.live.liveNum[live.id] || {}).length,
        data.purchaseData.live.allQuan[live.id] || 0,
        data.purchaseData.live.allSum[live.id] || 0,
        Object.keys(data.purchaseData.live.allNum[live.id] || {}).length,
        Object.keys(data.archiveUsers[live.id] || {}).length,
        Object.keys(data.archiveClicks[live.id] || {}).length,
        data.purchaseData.archive.liveQuan[live.id] || 0,
        data.purchaseData.archive.liveSum[live.id] || 0,
        Object.keys(data.purchaseData.archive.liveNum[live.id] || {}).length,
        data.purchaseData.archive.allQuan[live.id] || 0,
        data.purchaseData.archive.allSum[live.id] || 0,
        Object.keys(data.purchaseData.archive.allNum[live.id] || {}).length,
        Object.keys(data.liveArchiveUsers[live.id] || {}).length,
        Object.keys(data.liveArchiveClicks[live.id] || {}).length,
        (data.purchaseData.archive.liveQuan[live.id] || 0) + (data.purchaseData.live.liveQuan[live.id] || 0),
        (data.purchaseData.archive.liveSum[live.id] || 0) + (data.purchaseData.live.liveSum[live.id] || 0),
        Object.keys(data.purchaseData.liveArchive.liveNum[live.id] || {}).length,
        (data.purchaseData.archive.allQuan[live.id] || 0) + (data.purchaseData.live.allQuan[live.id] || 0),
        (data.purchaseData.archive.allSum[live.id] || 0) + (data.purchaseData.live.allSum[live.id] || 0),
        Object.keys(data.purchaseData.liveArchive.allNum[live.id] || {}).length,
      ]);
    });
  }

  return csv;
};
