import React, { Component } from 'react';
import Dropzone from 'react-dropzone'
import {
  Col,
  ControlLabel,
  FormGroup,
  FormControl,
} from 'react-bootstrap';
import moment from 'moment';

import './App.css';
import SellerNav from './SellerNav';
import firebase from './firebase';
import model from './Model';

export default class ItemData extends Component {
  constructor(props) {
    super(props);
    this.opened = moment();
    this.state = {
      loading: true,
      shop: null,
      shops: [],
      adminEmail: '',
      adminMessage: '',
      adminError: '',
      itemFile: null,
      itemFileUploaded: null,
      itemMessage: '',
      itemsTsvImportResult: null,
    };
  }

  componentDidMount() {
    const uid = firebase.auth().currentUser.uid;
    this.offItemsTsvImportResult = (
      model
        .collection('items_tsv_import_results')
        .where('uid', '==', uid)
        .where('manual', '==', true)
        .orderBy('created', 'desc')
        .limit(1)
    ).onSnapshot((query) => {
      if (query.empty) {
        return;
      }
      const itemsTsvImportResult = model.listData(query)[0];
      if (this.opened.isAfter(itemsTsvImportResult.created.toDate())) {
        return;
      }
      this.setState({ itemsTsvImportResult });
    });
    this.offShop = model.collection('shops').where('uid', '==', uid)
      .onSnapshot((data) => {
      if (!data.empty) {
        const shop = data.docs[0].data();
        this.setState({ shop: shop, adminEmail: shop.adminEmail, loading: false });
      } else {
        this.setState({ loading: false });
      }
    });
    this.offShops = model.collection('shops')
      .where('admin', '==', firebase.auth().currentUser.uid).onSnapshot((data) => {
        const shops = model.listData(data);
        this.setState({ shops, loading: false });
      }
    );
  }

  componentWillUnmount() {
    this.offItemsTsvImportResult();
    this.offShop();
    this.offShops();
  }

  onAdminChange = (e) => this.setState({ adminEmail: e.target.value });

  onSubmitAdmin = () => {
    this.setState({ adminMessage: '', adminError: '' });
    model.fetch(`api/shops/${this.state.shop.id}`, 'PUT', { adminEmail: this.state.adminEmail })
      .then(() => this.setState({ adminMessage: '管理者アカウントを登録されました' }))
      .catch((error) => this.setState({ adminError: '入力された管理者アカウントが存在しません' }));
  };

  onItemFileChange = (files) => {
    const file = files[0];
    if (file) {
      if (!file.name.match('.(tsv|txt)$')) {
        alert('tsvファイルをアップロードしてください。');
        return;
      }
      this.setState({ itemFile: file });
    }
  };

  onSubmitItemFile = () => {
    if (this.state.itemFile) {
      this.setState({
        itemFileUploaded: new Date(),
        itemMessage: '商品情報TSVファイルを送信中です',
      });
      const name = `items${moment().format('YYYYMMDDHHmmss')}#manual.tsv`;
      firebase.app().storage(model.PRIVATE_BUCKET).ref()
        .child(`${firebase.auth().currentUser.uid}/${name}`).put(this.state.itemFile);
      this.setState({
        itemMessage: '商品情報TSVファイルを送信しました',
      });
    }
  };

  render() {
    const {
      loading,
      shop,
      shops,
      adminEmail,
      adminMessage,
      adminError,
      itemFile,
      itemFileUploaded,
      itemMessage,
      itemsTsvImportResult,
    } = this.state;
    if (loading) {
      return <div />;
    } else if (!(shop && shop.name) && !(shops[0] && shops[0].name)) {
      return (
        <div className="content">
          <div className="loginContainer notitle">
            <div className="AppTitleContainer">
            </div>
            <div className="cardMini">
              <div className="cardMiniContainer">
                <p className="mb20">まずショップ情報を登録してください。</p>
                  <a className="strongBtn largeBtn" href="/shop">登録へ</a>
              </div>
            </div>
          </div>
        </div>
      );
    }
    return (
      <div>
      <SellerNav permission={this.props.permission}/>
      <div className="content itemData">
        <h2>商品管理</h2>
        <ul className="detail-nav nav-m-bk nav nav-pills">
          <li role="presentation" className="active"><a href="/itemdata">一括登録</a></li>
          <li role="presentation"><a href="/items">商品一覧</a></li>
          <li role="presentation"><a href="/itemfeed">自動連携</a></li>
        </ul>

        <a
          type="button"
          href="https://firebasestorage.googleapis.com/v0/b/tagfab-shop/o/moffly%2FTSV_template.xlsx?alt=media"
          className="normalBtn inlineBlock itemData_downloadTsvSpec"
        >
          TSV仕様書をダウンロード
        </a>

        <div className="card">
        <form className="form form-horizontal">
          {shop &&
          <div>
            <FormGroup controlId="admin">
              <Col componentClass={ControlLabel} sm={4}>管理者アカウント</Col>
              <Col sm={8}>
                <FormControl name="admin" type="text" placeholder={adminEmail}
                  value={adminEmail} onChange={this.onAdminChange}
                />
                <div className="formcaption">
                  管理者のメールアドレスを入力してください。ショップ・アカウントが、それ以外のショップ・アカウントから管理者として指定されることで複数ショップの商品管理を行うことができるようになります。
                </div>
                <button type="button" className="strongBtn mt20 ml20" onClick={this.onSubmitAdmin}>
                  登録する
                </button>
                <p className="green">{adminMessage}</p>
                <p className="error">{adminError}</p>
              </Col>
            </FormGroup>
            <FormGroup className="itemData_shops">
              <Col componentClass={ControlLabel} sm={4}>管理中のショップ</Col>
              <Col sm={8}>
                <ul className="itemDataTableList">
                  <li className="dtTablelist">
                    <span className="dtshopType inlineBlock">種類</span>
                    <span className="dtshopName inlineBlock">ショップ名</span>
                    <span className="dtIdCell inlineBlock">ID</span>
                  </li>
                  <li className="ddTablelist textonly">
                    <span className="ddshopType inlineBlock"><strong>あなたの<br />ショップ</strong></span>
                    <span className="ddshopName inlineBlock">{this.state.shop.name}</span>
                    <span className="ddIdCell inlineBlock">{this.state.shop.id}</span>
                  </li>
                  {this.state.shops.length > 0 && this.state.shops.map((shop, index) => (
                    <li key={shop.id} className="ddTablelist textonly">
                      <span className="ddshopType inlineBlock">
                        {index === 0 ? <strong>管理中の<br />ショップ</strong> : '　'}
                      </span>
                      <span className="ddshopName inlineBlock">{shop.name}</span>
                      <span className="ddIdCell inlineBlock">{shop.id}</span>
                    </li>
                  ))}
                </ul>
              </Col>
            </FormGroup>
          </div>}

          {shops.length > 0 &&
          <div>
            <FormGroup controlId="itemFile">
              <Col componentClass={ControlLabel} sm={4}>商品情報TSVファイル</Col>
              <Col sm={8}>
                <Dropzone name="itemFile" onDrop={this.onItemFileChange} accept={{"text/*" : [".tsv", ".txt"]}}>
                  {({getRootProps, getInputProps}) => (
                    <section className="dropzoneContainer">
                      <div className="dropzone" {...getRootProps()}>
                        <input {...getInputProps()} />
                        <p>ファイルアップロード</p>
                        <input type="button" value="ファイルを選択" onChange={this.onItemFileChange}></input>
                      </div>
                    </section>)}
                </Dropzone>
                {itemFile && <p>{itemFile.name}</p>}
              </Col>
              <Col smOffset={4} sm={8}>
                <button type="button" className="strongBtn mt20" onClick={this.onSubmitItemFile}>
                  送信する
                </button>
                {(!itemsTsvImportResult || (itemsTsvImportResult.updated && itemsTsvImportResult.updated.toDate() <= itemFileUploaded)) && itemMessage && (
                  <p className="mt20 green">{itemMessage}</p>
                )}
                {itemsTsvImportResult && !itemsTsvImportResult.updated && (
                  <p className="mt20 green">
                    商品情報TSVファイルを登録中です
                  </p>
                )}
                {itemsTsvImportResult && itemsTsvImportResult.updated && itemsTsvImportResult.updated.toDate() > itemFileUploaded && itemsTsvImportResult.done && (
                  <p className="mt20 green">
                    商品情報TSVファイルの登録が完了しました
                  </p>
                )}
                {itemsTsvImportResult && itemsTsvImportResult.updated && itemsTsvImportResult.updated.toDate() > itemFileUploaded && itemsTsvImportResult.errors.length > 0 && (
                  <React.Fragment>
                    <p className="mt20 green">
                      商品情報TSVファイルの登録が失敗しました
                    </p>
                    <p className="mt20 error">
                      <ul>
                        {itemsTsvImportResult.errors.map((error, index) => (
                          <li key={index}>{error}</li>
                        ))}
                      </ul>
                    </p>
                  </React.Fragment>
                )}
              </Col>
            </FormGroup>
          </div>}
        </form>
        </div>
      </div>
      </div>
    );
  }
}
