import React, { Component } from 'react';
import {
  Col,
  ControlLabel,
  Row,
} from 'react-bootstrap';
import { CSVLink } from "react-csv";
import moment from 'moment';

import './App.css';
import SellerNav from './SellerNav';
import firebase from './firebase';
import model from './Model';

export default class ItemFeed extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      itemsTsvImportResults: [],
    };
  }

  componentDidMount() {
    const uid = firebase.auth().currentUser.uid;
    (
      model
        .collection('items_tsv_import_results')
        .where('uid', '==', uid)
        .where('manual', '==', false)
        .orderBy('created', 'desc')
        .limit(30)
        .get()
    ).then((query) => {
      const itemsTsvImportResults = model.listData(query);
      this.setState({ loading: false, itemsTsvImportResults });
    });
  }

  render() {
    const {
      loading,
      itemsTsvImportResults,
    } = this.state;
    if (loading) {
      return <div />;
    }
    return (
      <div>
        <SellerNav permission={this.props.permission}/>
        <div className="content itemData">
          <h2>商品管理</h2>
          <ul className="detail-nav nav-m-bk nav nav-pills">
            <li role="presentation"><a href="/itemdata">一括登録</a></li>
            <li role="presentation"><a href="/items">商品一覧</a></li>
            <li role="presentation" className="active"><a href="/itemfeed">自動連携</a></li>
          </ul>
          {itemsTsvImportResults && itemsTsvImportResults.length ? (
            <div className="card">
              <Row>
                <Col componentClass={ControlLabel} xs={2} rowSpan={2}></Col>
                <Col componentClass={ControlLabel} xs={4} colSpan={2} style={{ textAlign: 'center' }}>SKU</Col>
                <Col componentClass={ControlLabel} xs={4} colSpan={2} style={{ textAlign: 'center' }}>ブランド品番</Col>
                <Col componentClass={ControlLabel} xs={2} rowSpan={2}></Col>
              </Row>
              <Row>
                <Col componentClass={ControlLabel} xs={2} style={{ textAlign: 'center' }}>登録日</Col>
                <Col componentClass={ControlLabel} xs={2} style={{ textAlign: 'right' }}>登録件数</Col>
                <Col componentClass={ControlLabel} xs={2} style={{ textAlign: 'right' }}>削除件数</Col>
                <Col componentClass={ControlLabel} xs={2} style={{ textAlign: 'right' }}>登録件数</Col>
                <Col componentClass={ControlLabel} xs={2} style={{ textAlign: 'right' }}>削除件数</Col>
                <Col componentClass={ControlLabel} xs={2} style={{ textAlign: 'right' }}>エラー件数</Col>
              </Row>
              {itemsTsvImportResults.map((result) => (
                <Row>
                  <Col xs={2} style={{ textAlign: 'center' }}>{moment(result.created.toDate()).format('yyyy.MM.DD')}</Col>
                  <Col xs={2} style={{ textAlign: 'right' }}>{(result.updatedSKUs || 0).toLocaleString()}</Col>
                  <Col xs={2} style={{ textAlign: 'right' }}>{(result.deletedSKUs || 0).toLocaleString()}</Col>
                  <Col xs={2} style={{ textAlign: 'right' }}>{(result.updatedBrands || 0).toLocaleString()}</Col>
                  <Col xs={2} style={{ textAlign: 'right' }}>{(result.deletedBrands || 0).toLocaleString()}</Col>
                  <Col xs={2} style={{ textAlign: 'right' }}>
                    {result.errors && result.errors.length > 0 ? (
                      <CSVLink
                        data={result.errors.map((error) => [error])}
                        filename={"errors.csv"}
                      >
                        {result.errors.length.toLocaleString()}
                      </CSVLink>
                    ) : (
                      <React.Fragment>0</React.Fragment>
                    )}
                  </Col>
                </Row>
              ))}
            </div>
          ) : (
            <div className="card">
              データがありません。
            </div>
          )}
        </div>
      </div>
    );
  }
}
