import FileSaver from 'file-saver';
import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import moment from 'moment';
import 'moment-duration-format';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { DateRangePicker } from 'react-dates';
import {
  Nav,
  NavItem,
} from 'react-bootstrap';
import { CSVLink } from 'react-csv';

import './App.css';
import StreamerNav from './StreamerNav';
import { ApiCSVData } from './ApiCSVData';
import firebase from './firebase';
import model from './Model';
import Utilities from './Utilities';
import LiveReportGraph1 from './LiveReportGraph1';
import LiveReportGraph2 from './LiveReportGraph2';

const db = firebase.firestore();

const ENABLE_LIVESTATS = true;
const ENABLE_LIVESTATS_GRAPH = false;

export default class ApiData extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect: false,
      live: null,
      liveStatus: {},
      session: null,
      sessions: null,
      startDate: null,
      endDate: null,
      focusedInput: null,
      averageTime: '',
      likes: 0,
      views: 0,
      domain: '',
      liveUsers: {},
      archiveUsers: {},
      liveArchiveUsers: {},
      previewUsers: {},
      liveClicks: {},
      archiveClicks: {},
      liveArchiveClicks: {},
      previewClicks: {},
      purchaseData: {
        preview: {
          liveQuan: {},
          liveNum: {},
          liveSum: {},
          allQuan: {},
          allNum: {},
          allSum: {}
        },
        live: {
          liveQuan: {},
          liveNum: {},
          liveSum: {},
          allQuan: {},
          allNum: {},
          allSum: {}
        },
        archive: {
          liveQuan: {},
          liveNum: {},
          liveSum: {},
          allQuan: {},
          allNum: {},
          allSum: {}
        },
        liveArchive: {
          liveNum: {},
          allNum: {},
        }
      },
      unitIds: [],
      loading: false,
      results: null,
      unitStats: null,
      orderProgress: 0,
      orderTotal: 0,
      isIE: false,
      liveStartTime: null, //ライブ開始時間
      liveEndTime: null, //ライブ終了時間
      liveMinutesArray: [], //ライブ時間(分）
      watchKeepCountArray: [], //ライブ視聴維持率(分）
      purchaseCountArray: [], //ライブ中購入数
      productClickCountArray: [], //ライブ中商品クリック数
      graph1Data: {
        labels: [], //時間(分)
        datasets: [
          {
            type: 'line',
            label: '視聴維持率',
            borderColor: 'rgb(255, 99, 132)',
            borderWidth: 2,
            fill: false,
            data: [],
            yAxisID: 'y',
          },
          {
            type: 'bar',
            label: '購入数',
            backgroundColor: 'rgb(75, 192, 192)',
            data: [],
            borderColor: 'white',
            borderWidth: 2,
            yAxisID: 'y2',
          },
        ],
      },
      graph2Data: {
        labels: [], //時間(分)
        datasets: [
          {
            type: 'line',
            label: '商品クリック数',
            borderColor: 'rgb(255, 99, 132)',
            borderWidth: 2,
            fill: false,
            data: [],
            yAxisID: 'y',
          },
        ],
      },
    };
  }

  componentDidMount() {
    this.checkLive();
  }

  checkLive() {
    if (ENABLE_LIVESTATS) {
      this.setState({
        liveTotalViewers: 0,
        liveViewStats: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        liveOrderStats: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      });
    }
    return new Promise((resolve) => {
      const uid = firebase.auth().currentUser.uid;
      this.liveId = this.props.match.params.id;

      db.collection('lives').doc(this.liveId).get().then((liveDoc) => {
        const live = liveDoc.data();
        if (live.uid !== uid) {
          this.setState({ redirect: true });
          resolve();
          return;
        }
        db.collection('live_statuses').doc(this.liveId).get().then((liveStatusDoc) => {
          if (!liveStatusDoc.exists) {
            resolve();
            return;
          }
          const liveStatus = liveStatusDoc.data();
          this.setState({ liveStatus });
        });
        db.collection('shops').doc(live.shopId).get().then((shopDoc) => {
          const domain = shopDoc.data().domain || '';
          (
            db
              .collection('sessions')
              .where('domain', '==', domain)
              .where('isTest', '==', false)
              .orderBy('created')
              .get()
          ).then(async (sessionsQuery) => {
            const liveUnitsQueries = await Promise.all(
              live.items.map((item) => db.collection('units').where('itemId', '==', item.id).get())
            );
            const liveUnits = liveUnitsQueries.reduce((previous, current) => [
              ...previous,
              ...current.docs.map((doc) => {
                const unit = doc.data();
                return {
                  code: unit.code,
                  name: unit.name,
                  image: unit.image,
                  itemId: unit.itemId,
                };
              }),
            ], []);
            let sessions = sessionsQuery.docs.map((sessionDoc) => sessionDoc.data()).filter((session) => session.broadcastId);
            let session = sessions.findLast((value) => value.liveId === this.liveId);
            if (session) {
              if (!session.items) {
                session.items = [];
              }
              if (!session.units) {
                session.units = [];
              }
              live.items.forEach((item) => {
                if (session.items.findIndex((v) => v.id === item.id) === -1) {
                  session.items.push(item);
                }
                liveUnits.filter((unit) => unit.itemId === item.id).forEach((unit) => {
                  if (session.units.findIndex((v) => v.code === unit.code) === -1) {
                    session.units.push(unit);
                  }
                });
              });
            } else {
              session = {
                created: live.datetime,
                liveId: this.liveId,
                domain,
                isTest: live.isTest,
                items: live.items,
                units: liveUnits,
              };
              sessions = [session];
            }
            const liveStartTime = this.state.liveStartTime || moment(session.created.toDate()).add((session.stabilizationTime || 0), 'seconds');
            const startDate = this.state.startDate || liveStartTime.clone().startOf('day');
            let endDate = this.state.endDate || startDate.clone().add(30, 'days').endOf('day');
            if (endDate.isAfter(moment())) {
              endDate = moment().endOf('day');
            }
            this.setState({
              live,
              session,
              sessions,
              startDate,
              endDate,
              domain,
              isIE: Utilities.isIE(),
              liveStartTime,
            });
            const liveMinutes = Math.ceil((session.duration - (session.stabilizationTime || 0)) / 60);
            if (liveMinutes) {
              this.setState({
                liveEndTime: liveStartTime.clone().add(liveMinutes, 'minutes'),
                liveMinutesArray: [...Array(liveMinutes).keys()].map((i) => (i + 1)),
                watchKeepCountArray:  [...Array(liveMinutes).keys()].map(() => 0),
                purchaseCountArray: [...Array(liveMinutes).keys()].map(() => 0),
                productClickCountArray: [...Array(liveMinutes).keys()].map(() => 0),
              });
            }
            (
              db.collection('lives').doc(this.liveId).collection('views').get()
            ).then((viewsQuery) => {
              const { watchKeepCountArray } = this.state;
              const { liveViewStats } = ENABLE_LIVESTATS ? this.state : {};
              let totalTime = 0;
              viewsQuery.forEach((viewDoc) => {
                const view = { uid: viewDoc.id, ...viewDoc.data() };
                totalTime += view.seconds;
                if (ENABLE_LIVESTATS) {
                  const endTime = moment(view.updated.toDate());
                  const endMinutes = endTime.diff(liveStartTime, 'minutes');
                  const endIndex = Math.floor(endMinutes / 5);
                  const startIndex = Math.floor((endMinutes - view.seconds / 60) / 5);
                  for (let i = startIndex; i <= endIndex; i++) {
                    if (liveViewStats[i] >= 0) {
                      liveViewStats[i] += 1;
                    }
                  }
                }
                if (!watchKeepCountArray.length) {
                  return;
                }
                const liveUserEndTime = moment(view.updated.toDate());
                const liveUserStartTime = liveUserEndTime.clone().subtract(view.seconds, 'seconds');
                const startEventPosition = liveUserStartTime.diff(liveStartTime, 'minutes');
                const endEventPosition = liveUserEndTime.diff(liveStartTime, 'minutes');
                for (let i = 0; i < endEventPosition - startEventPosition; i++) {
                  const position = startEventPosition + i;
                  if (position < watchKeepCountArray.length) {
                    watchKeepCountArray[position] += 1;
                  }
                }
              });
              const averageTime = moment.duration(totalTime / viewsQuery.size, 'seconds').format('mm:ss');
              this.setState({
                averageTime,
                watchKeepCountArray: [...watchKeepCountArray],
              });
              if (ENABLE_LIVESTATS) {
                this.setState({
                  liveViewStats,
                  liveTotalViewers: viewsQuery.size,
                });
              }
              resolve();
            });      
          });
        });
      });
    });
  }

  checkViews() {
    return new Promise((resolve) => {
      const {
        liveStatus,
        startDate,
        endDate,
        liveStartTime,
        watchKeepCountArray,
      } = this.state;
      (
        db
          .collection('events')
          .where('name', '==', 'live')
          .where('liveId', '==', this.liveId)
          .where('created', '>=', startDate.toDate())
          .where('created', '<=', endDate.toDate())
          .get()
      ).then((eventsQuery) => {
        const liveUsers = {}, archiveUsers = {}, liveArchiveUsers = {}, previewUsers = {};
        const liveDay = liveStartTime.utcOffset('+0900').format('MMDD');
        liveUsers[liveDay] = {};
        liveArchiveUsers[liveDay] = {};
        eventsQuery.forEach((doc) => {
          const event = doc.data();
          const eventTime = moment(event.created.toDate());
          const day = eventTime.utcOffset('+0900').format('MMDD');
          if (!liveUsers[day]) {
            liveUsers[day] = {};
          }
          if (!archiveUsers[day]) {
            archiveUsers[day] = {};
          }
          if (!liveArchiveUsers[day]) {
            liveArchiveUsers[day] = {};
          }
          if (!previewUsers[day]) {
            previewUsers[day] = {};
          }
          if (event.type === 'live') {
            liveUsers[liveDay][event.uid] = true;
            liveArchiveUsers[liveDay][event.uid] = true;
          } else if (event.type === 'archive') {
            archiveUsers[day][event.uid] = true;
            liveArchiveUsers[day][event.uid] = true;
          } else if (event.type === 'preview') {
            previewUsers[day][event.uid] = true;
          }
        });
        const reducer = (accumulator, currentValue) => Object.assign(accumulator, currentValue);
        liveUsers['total'] = Object.keys(Object.values(liveUsers).reduce(reducer, {})).length;
        archiveUsers['total'] = Object.keys(Object.values(archiveUsers).reduce(reducer, {})).length;
        liveArchiveUsers['total'] = Object.keys(Object.values(liveArchiveUsers).reduce(reducer, {})).length;
        previewUsers['total'] = Object.keys(Object.values(previewUsers).reduce(reducer, {})).length;
        this.setState({
          liveUsers,
          archiveUsers,
          liveArchiveUsers,
          previewUsers,
          watchKeepCountArray: watchKeepCountArray.map((v) => Math.round((v / liveUsers['total']) * 100)),
        });

        // New view count data is only correct after this date.
        if (liveStartTime.isAfter(moment('2021-04-30'))) {
          this.setState({
            views: liveStatus.views || 0,
            likes: liveStatus.likes || 0,
          });
        }
        resolve();
      });
    });
  }

  checkClicks() {
    return new Promise((resolve) => {
      const {
        startDate,
        endDate,
        liveStartTime,
        liveEndTime,
        productClickCountArray,
      } = this.state;
      (
        db
          .collection('events')
          .where('name', '==', 'item')
          .where('liveId', '==', this.liveId)
          .where('created', '>=', startDate.toDate())
          .where('created', '<=', endDate.toDate())
          .get()
      ).then((eventsQuery) => {
        const liveClicks = {}, archiveClicks = {}, liveArchiveClicks = {}, previewClicks = {};
        const liveDay = liveStartTime.utcOffset('+0900').format('MMDD');
        liveClicks[liveDay] = {};
        liveArchiveClicks[liveDay] = {};
        eventsQuery.forEach((doc) => {
          const event = doc.data();
          const eventTime = moment(event.created.toDate());
          const day = eventTime.utcOffset('+0900').format('MMDD');
          if (!liveClicks[day]) {
            liveClicks[day] = {};
          }
          if (!archiveClicks[day]) {
            archiveClicks[day] = {};
          }
          if (!liveArchiveClicks[day]) {
            liveArchiveClicks[day] = {};
          }
          if (!previewClicks[day]) {
            previewClicks[day] = {};
          }
          if (event.type === 'preview') {
            previewClicks[day][event.uid] = true;
          } else if (event.type === 'live') {
            liveClicks[liveDay][event.uid] = true;
            liveArchiveClicks[liveDay][event.uid] = true;
          } else if (event.type === 'archive') {
            archiveClicks[day][event.uid] = true;
            liveArchiveClicks[day][event.uid] = true;
          } else if (eventTime.isAfter(liveStartTime) && eventTime.isBefore(liveEndTime)) {
            liveClicks[day][event.uid] = true;
            liveArchiveClicks[day][event.uid] = true;
          } else if (eventTime.isAfter(liveEndTime)) {
            archiveClicks[day][event.uid] = true;
            liveArchiveClicks[day][event.uid] = true;
          } else if (eventTime.isBefore(liveStartTime)) {
            previewClicks[day][event.uid] = true;
          }
          if (event.type === 'live') {
            const position = eventTime.diff(liveStartTime, 'minutes');
            if (position < productClickCountArray.length) {
              productClickCountArray[position] += 1;
            }
          }
        });
        const reducer = (accumulator, currentValue) => Object.assign(accumulator, currentValue);
        liveClicks['total'] = Object.keys(Object.values(liveClicks).reduce(reducer, {})).length;
        archiveClicks['total'] = Object.keys(Object.values(archiveClicks).reduce(reducer, {})).length;
        liveArchiveClicks['total'] = Object.keys(Object.values(liveArchiveClicks).reduce(reducer, {})).length;
        previewClicks['total'] = Object.keys(Object.values(previewClicks).reduce(reducer, {})).length;
        this.setState({
          liveClicks,
          archiveClicks,
          liveArchiveClicks,
          previewClicks,
          productClickCountArray: [...productClickCountArray],
        });
        resolve();
      });
    });
  }

  queryOrders() {
    return new Promise((resolve) => {
      const {
        session,
        sessions,
        startDate,
        endDate,
        domain,
        liveStartTime,
        liveEndTime,
        purchaseCountArray,
      } = this.state;
      const {
        mainLiveUnitIds,
        otherLiveUnitIds,
      } = model.getLiveUnitIds([this.liveId], sessions);
      (
        db
          .collection('events')
          .where('name', '==', 'order')
          .where('domain', '==', domain)
          .where('created', '>=', startDate.toDate())
          .where('created', '<=', endDate.toDate())
          .orderBy('created')
          .get()
      ).then((eventsQuery) => {
        const orders = eventsQuery.docs.map((eventDoc) => eventDoc.data());
        const unitIds = session.units.map((unit) => unit.code);
        const unitStats = {
          total: {
            preview: { quantity: 0, price: 0 },
            live: { quantity: 0, price: 0 },
            archive: { quantity: 0, price: 0 },
            total: { quantity: 0, price: 0 }
          }
        };
        session.units.forEach((unit) => {
          unitStats[unit.code] = {
            name: unit.name,
            preview: { quantity: 0, price: 0 },
            live: { quantity: 0, price: 0 },
            archive: { quantity: 0, price: 0 },
          };
        });
        this.setState({ unitIds, unitStats, orderTotal: eventsQuery.size });

        const orderIds = [];
        const outputPromises = [];
        orders.forEach((order, i) => {
          const orderTime = moment(order.created.toDate());
          if (order.uid && Array.isArray(order.units) && !orderIds.includes(order.orderId)) {
            orderIds.push(order.orderId);
            this.setState({ orderProgress: i + 1 });

            if (order.viewData && model.shouldUseNewCookies(orderTime)) {
              const lastView = model.getLastView(order, mainLiveUnitIds, otherLiveUnitIds);
              if (lastView && lastView.liveId === this.liveId) {
                outputPromises.push(new Promise((resolve) => {
                  resolve(model.getOrderData(order, lastView, sessions));
                }));
              }
            } else {
              outputPromises.push(new Promise((resolve) => {
                (
                  db
                    .collection('events')
                    .where('name', '==', 'live')
                    .where('domain', '==', domain)
                    .where('uid', '==', order.uid)
                    .get()
                ).then((viewData) => {
                  let result = null;
                  if (viewData.size) {
                    // Prioritize view type live > archive > preview
                    let topViewEvent = null;
                    for (let i = 0; i < viewData.size; i++) {
                      const viewEvent = viewData.docs[i].data();
                      const viewTime = moment(viewEvent.created.toDate());
                      if (viewEvent.liveId === this.liveId && orderTime.isAfter(viewTime) &&
                          orderTime.diff(viewTime, 'days') <= 30 &&
                          (!topViewEvent || viewEvent.type === 'live' ||
                            topViewEvent.type === 'preview')) {
                        topViewEvent = viewEvent;
                      }
                    }
                    if (topViewEvent) {
                      result = model.getOrderData(order, topViewEvent, sessions);
                    }
                  }
                  resolve(result);  
                });
              }));
            }
          }
        });
        Promise.all(outputPromises).then((output) => output.filter((x) => x)).then((data) => {
          const { purchaseData } = this.state;
          purchaseData['preview'].allQuan['total'] = 0;
          purchaseData['preview'].allNum['total'] = 0;
          purchaseData['preview'].allSum['total'] = 0;
          purchaseData['preview'].liveQuan['total'] = 0;
          purchaseData['preview'].liveNum['total'] = 0;
          purchaseData['preview'].liveSum['total'] = 0;
          purchaseData['live'].allQuan['total'] = 0;
          purchaseData['live'].allNum['total'] = 0;
          purchaseData['live'].allSum['total'] = 0;
          purchaseData['live'].liveQuan['total'] = 0;
          purchaseData['live'].liveNum['total'] = 0;
          purchaseData['live'].liveSum['total'] = 0;
          purchaseData['archive'].allQuan['total'] = 0;
          purchaseData['archive'].allNum['total'] = 0;
          purchaseData['archive'].allSum['total'] = 0;
          purchaseData['archive'].liveQuan['total'] = 0;
          purchaseData['archive'].liveNum['total'] = 0;
          purchaseData['archive'].liveSum['total'] = 0;
          purchaseData['liveArchive'].allNum['total'] = 0;
          purchaseData['liveArchive'].liveNum['total'] = 0;

          const { liveOrderStats } = ENABLE_LIVESTATS ? this.state : {};
          data.forEach((order) => {
            const { uid, type, day, price, quantity, livePrice, liveQuantity, created } = order;
            const liveArchive = (type === 'live' || type === 'archive');
            if (!purchaseData[type].allQuan[day]) {
              purchaseData[type].allQuan[day] = 0;
            }
            if (!purchaseData[type].allNum[day]) {
              purchaseData[type].allNum[day] = {};
            }
            if (!purchaseData['liveArchive'].allNum[day] && liveArchive) {
              purchaseData['liveArchive'].allNum[day] = {};
            }
            if (!purchaseData[type].allSum[day]) {
              purchaseData[type].allSum[day] = 0;
            }
            if (!purchaseData[type].liveQuan[day]) {
              purchaseData[type].liveQuan[day] = 0;
            }
            if (!purchaseData[type].liveNum[day]) {
              purchaseData[type].liveNum[day] = {};
            }
            if (!purchaseData['liveArchive'].liveNum[day] && liveArchive) {
              purchaseData['liveArchive'].liveNum[day] = {};
            }
            if (!purchaseData[type].liveSum[day]) {
              purchaseData[type].liveSum[day] = 0;
            }
            purchaseData[type].allQuan[day] += quantity;
            purchaseData[type].allNum[day][uid] = true;
            if (liveArchive) purchaseData['liveArchive'].allNum[day][uid] = liveArchive;
            purchaseData[type].allSum[day] += price;
            if (livePrice > 0) {
              purchaseData[type].liveQuan[day] += liveQuantity;
              purchaseData[type].liveNum[day][uid] = true;
              if (liveArchive) purchaseData['liveArchive'].liveNum[day][uid] = liveArchive;
              purchaseData[type].liveSum[day] += livePrice;
            }
            purchaseData[type].allQuan['total'] += quantity;
            purchaseData[type].allSum['total'] += price;
            if (livePrice > 0) {
              purchaseData[type].liveQuan['total'] += liveQuantity;
              purchaseData[type].liveSum['total'] += livePrice;
            }
            const orderTime = moment(created);
            if (type === 'live' && orderTime.isBefore(liveEndTime)) {
              const position = orderTime.diff(liveStartTime, 'minutes');
              if (position < purchaseCountArray.length) {
                purchaseCountArray[position] += 1;
              }
              if (ENABLE_LIVESTATS) {
                const i = Math.floor(position / 5);
                if (liveOrderStats[i] >= 0) {
                  liveOrderStats[i] += 1;
                }
              }
            }
          });

          Utilities.countTotalPurchasers(purchaseData);
          const unitStats = this.state.unitStats;
          data.forEach((result) => {
            result.unitStat.forEach((unit) => {
              const unitId = unit.unitId;
              const type = unit.type;
              unitStats[unitId][type].quantity += unit.quantity;
              unitStats[unitId][type].price += unit.price;
              unitStats.total[type].quantity += unit.quantity;
              unitStats.total[type].price += unit.price;
              unitStats.total.total.quantity += unit.quantity;
              unitStats.total.total.price += unit.price;
            });
          });
          this.setState({
            purchaseData,
            loading: false,
            results: this.orderLog(data),
            unitStats,
            purchaseCountArray: [...purchaseCountArray],
          });
          this.setState({ purchaseData, loading: false, results: this.orderLog(data), unitStats });
          resolve();
        });
      });
    });
  }

  async buildLiveStats() {
    const {
      liveMinutesArray,
      watchKeepCountArray,
      purchaseCountArray,
      productClickCountArray,
    } = this.state;

    if (!liveMinutesArray.length) {
      return;
    }

    const graph1Data = {
      labels: liveMinutesArray,
      datasets: [
        {
          type: 'line',
          label: '視聴維持率',
          fill: false,
          data: watchKeepCountArray,
          borderColor: 'rgb(255, 99, 132)',
          borderWidth: 2,
          yAxisID: 'y'
        },
        {
          type: 'bar',
          label: '購入数',
          backgroundColor: 'rgb(75, 192, 192)',
          data: purchaseCountArray,
          borderColor: 'white',
          borderWidth: 2,
          yAxisID: 'y2'
        }
      ]
    };

    const graph2Data = {
      labels: liveMinutesArray,
      datasets: [
        {
          type: 'line',
          label: '商品クリック数',
          borderColor: 'rgb(255, 99, 132)',
          borderWidth: 2,
          fill: false,
          data: productClickCountArray,
          yAxisID: 'y'
        }
      ]
    };

    this.setState({
      graph1Data,
      graph2Data,
    });
  }

  orderLog = (data) => {
    const header = [`注文ID\t購入日時\t全商品購入\t紹介商品購入\tSKU ID\t個数\tユーザー区分\n`];
    const logData = [];
    data.forEach((order) => {
      const units = JSON.parse(order.units);
      units.forEach((unit) => {
        const livePrice = order.unitStat.find((v) => v.unitId === unit.unitId) ? unit.price : 0;
        logData.push(`${order.orderId}\t${order.created}\t${unit.price}\t${livePrice}\t${unit.unitId}\t${unit.quantity}\t${order.type}\n`);
      });
    });
    return header.concat(logData);
  };

  onChangeDomain = (e) => this.setState({ domain: e.target.value });

  download = () => {
    const blob = new Blob(this.state.results, { type: 'text/plain;charset=utf-8' });
    FileSaver.saveAs(blob, `${this.liveId}_orders.tsv`);
  };

  onDatesChange = ({ startDate, endDate }) => {
    // Making a copy of startDate and endDate is needed when when they are same value.
    // If not making a copy of them, both will be exactly the same value at result for some reason...
    if (startDate) {
      this.setState({ startDate: startDate.clone().startOf('day') });
    }
    if (endDate) {
      this.setState({ endDate: endDate.clone().endOf('day') });
    }
  };

  updateData = () => {
    this.setState({
      loading: true,
      results: null,
      liveUsers: {},
      archiveUsers: {},
      liveArchiveUsers: {},
      previewUsers: {},
      liveClicks: {},
      archiveClicks: {},
      liveArchiveClicks: {},
      previewClicks: {},
      purchaseData: {
        preview: {
          liveQuan: {},
          liveNum: {},
          liveSum: {},
          allQuan: {},
          allNum: {},
          allSum: {}
        },
        live: {
          liveQuan: {},
          liveNum: {},
          liveSum: {},
          allQuan: {},
          allNum: {},
          allSum: {}
        },
        archive: {
          liveQuan: {},
          liveNum: {},
          liveSum: {},
          allQuan: {},
          allNum: {},
          allSum: {}
        },
        liveArchive: {
          liveNum: {},
          allNum: {},
        }
      }
    });
    this.checkLive().then(() => {
      Promise.all([
        this.checkViews(),
        this.checkClicks(),
        this.queryOrders(),
      ]
      ).then(() => {
        this.buildLiveStats();
      });
    });
  };

  enumerateDays = () => {
    const days = [];
    const day = this.state.startDate.clone().startOf('day');
    while (day.isBefore(this.state.endDate)) {
      days.push(day.format('MMDD'));
      day.add(1, 'days');
    }
    return days;
  };

  render() {
    const state = this.state;
    if (state.redirect) {
      return <Redirect to="/streamer/previews" />;
    }
    return (
      <div>
        <StreamerNav permission={this.props.permission} />
        <div className="content apiData">
          <Nav bsStyle="pills" className="detail-nav nav-m-bk">
            <NavItem href="/streamer/previews" active>ライブ予約一覧</NavItem>
            <li className="detailNav_navItemChild">レポート</li>
            <NavItem href="/streamer/previews/new">新しいライブ予約</NavItem>
            <NavItem href="/streamer/previews/trash">削除済みライブ</NavItem>
          </Nav>
          <div className="apiData_titleArea">
            <h2>レポート</h2>
          </div>
          <div className="card">
            <ul className="apiData_initialList">
              <li className="apiData_initialListItem">
                <span className="apiData_initialList-dt">ライブID</span>
                <span className="apiData_initialList-dd">{this.liveId}</span>
              </li>
              <li className="apiData_initialListItem">
                <span className="apiData_initialList-dt">ドメイン</span>
                <span className="apiData_initialList-dd">{state.domain}</span>
              </li>

              {state.startDate && state.endDate && (
                <li className="apiData_initialListItem">
                  <span className="apiData_initialList-dt">計測期間</span>
                  <span className="apiData_datePicker">
                    <DateRangePicker
                      minimumNights={0}
                      startDate={state.startDate} // momentPropTypes.momentObj or null,
                      startDateId="your_unique_start_date_id" // PropTypes.string.isRequired,
                      endDate={state.endDate} // momentPropTypes.momentObj or null,
                      endDateId="your_unique_end_date_id" // PropTypes.string.isRequired,
                      onDatesChange={this.onDatesChange}
                      isOutsideRange={(day) => day.isBefore(state.startDate)}
                      focusedInput={state.focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                      disabled={state.loading}
                      displayFormat="YYYY/M/D"
                      onFocusChange={(focusedInput) => this.setState({ focusedInput })}
                    />
                    <button
                      type="button"
                      className="btn normalBtn-strongColor"
                      onClick={this.updateData}
                      disabled={state.loading}
                    >
                      レポートを表示する
                    </button>
                  </span>
                </li>
              )}
            </ul>

            <div className="apiData_progressMessage">
            {state.results
                ? '' : state.loading
                  ? `レポートを出力中です...... ${state.orderProgress}/${state.orderTotal}` : ''
              }
            </div>
            {state.results && (
              <div className="reportTableContainer">
                <p>このレポートは日別のパフォーマンスを確認するためのレポートです。</p>
                <ul className="apiData_reportList">
                  {state.averageTime && (
                    <li>
                      <span className="apiData_reportList-dt">LIVE 平均視聴時間</span>
                      <span className="apiData_reportList-dd">{state.averageTime}</span>
                    </li>
                  )}
                  {Boolean(state.views) && (
                    <li>
                      <span className="apiData_reportList-dt">LIVE 再生数</span>
                      <span className="apiData_reportList-dd">{state.views}</span>
                    </li>
                  )}
                  {model.ENABLE_LIKE && Boolean(state.likes) && (
                    <li>
                      <span className="apiData_reportList-dt">LIVE ライク数</span>
                      <span className="apiData_reportList-dd">{state.likes}hoge</span>
                    </li>
                  )}
                </ul>

                <div className="apidataOverall">
                  <table>
                    <tbody>
                      <tr>
                        <th className="title"></th>
                        <th colSpan="8">ウェイティング</th>
                        <th colSpan="8">ライブ再生</th>
                        <th colSpan="8">アーカイブ再生</th>
                        <th colSpan="8">ライブ・アーカイブ再生</th>
                      </tr>
                      <tr>
                        <th className="title"></th>
                        <th colSpan="1">訪問</th>
                        <th colSpan="1">商品クリック</th>
                        <th colSpan="3">掲載商品購入</th>
                        <th colSpan="3">全商品商品購入</th>
                        <th colSpan="1">動画再生</th>
                        <th colSpan="1">商品クリック</th>
                        <th colSpan="3">掲載商品購入</th>
                        <th colSpan="3">全商品商品購入</th>
                        <th colSpan="1">動画再生</th>
                        <th colSpan="1">商品クリック</th>
                        <th colSpan="3">掲載商品購入</th>
                        <th colSpan="3">全商品商品購入</th>
                        <th colSpan="1">動画再生</th>
                        <th colSpan="1">商品クリック</th>
                        <th colSpan="3">掲載商品購入</th>
                        <th colSpan="3">全商品商品購入</th>
                      </tr>
                      <tr>
                        <th className="title">日付</th>
                        <th>人数</th>
                        <th>クリック人数</th>
                        <th>購入個数</th>
                        <th>購入金額</th>
                        <th>購入者数</th>
                        <th>購入個数</th>
                        <th>購入金額</th>
                        <th>購入者数</th>

                        <th>人数</th>
                        <th>クリック人数</th>
                        <th>購入個数</th>
                        <th>購入金額</th>
                        <th>購入者数</th>
                        <th>購入個数</th>
                        <th>購入金額</th>
                        <th>購入者数</th>

                        <th>人数</th>
                        <th>クリック人数</th>
                        <th>購入個数</th>
                        <th>購入金額</th>
                        <th>購入者数</th>
                        <th>購入個数</th>
                        <th>購入金額</th>
                        <th>購入者数</th>

                        <th>人数</th>
                        <th>クリック人数</th>
                        <th>購入個数</th>
                        <th>購入金額</th>
                        <th>購入者数</th>
                        <th>購入個数</th>
                        <th>購入金額</th>
                        <th>購入者数</th>
                      </tr>

                      <tr>
                        <th className="title">Total</th>
                        <td>{state.previewUsers['total']}</td>
                        <td>{state.previewClicks['total']}</td>
                        <td>{state.purchaseData.preview.liveQuan['total'] || 0}</td>
                        <td>{state.purchaseData.preview.liveSum['total'] || 0}</td>
                        <td>{state.purchaseData.preview.liveNum['total'] || 0}</td>
                        <td>{state.purchaseData.preview.allQuan['total'] || 0}</td>
                        <td>{state.purchaseData.preview.allSum['total'] || 0}</td>
                        <td>{state.purchaseData.preview.allNum['total'] || 0}</td>
                        <td>{state.liveUsers['total']}</td>
                        <td>{state.liveClicks['total']}</td>
                        <td>{state.purchaseData.live.liveQuan['total'] || 0}</td>
                        <td>{state.purchaseData.live.liveSum['total'] || 0}</td>
                        <td>{state.purchaseData.live.liveNum['total'] || 0}</td>
                        <td>{state.purchaseData.live.allQuan['total'] || 0}</td>
                        <td>{state.purchaseData.live.allSum['total'] || 0}</td>
                        <td>{state.purchaseData.live.allNum['total'] || 0}</td>
                        <td>{state.archiveUsers['total']}</td>
                        <td>{state.archiveClicks['total']}</td>
                        <td>{state.purchaseData.archive.liveQuan['total'] || 0}</td>
                        <td>{state.purchaseData.archive.liveSum['total'] || 0}</td>
                        <td>{state.purchaseData.archive.liveNum['total'] || 0}</td>
                        <td>{state.purchaseData.archive.allQuan['total'] || 0}</td>
                        <td>{state.purchaseData.archive.allSum['total'] || 0}</td>
                        <td>{state.purchaseData.archive.allNum['total'] || 0}</td>
                        <td>{state.liveArchiveUsers['total']}</td>
                        <td>{state.liveArchiveClicks['total']}</td>
                        <td>{(state.purchaseData.archive.liveQuan['total'] || 0) + (state.purchaseData.live.liveQuan['total'] || 0)}</td>
                        <td>{(state.purchaseData.archive.liveSum['total'] || 0) + (state.purchaseData.live.liveSum['total'] || 0)}</td>
                        <td>{state.purchaseData.liveArchive.liveNum['total'] || 0}</td>
                        <td>{(state.purchaseData.archive.allQuan['total'] || 0) + (state.purchaseData.live.allQuan['total'] || 0)}</td>
                        <td>{(state.purchaseData.archive.allSum['total'] || 0) + (state.purchaseData.live.allSum['total'] || 0)}</td>
                        <td>{state.purchaseData.liveArchive.allNum['total'] || 0}</td>
                      </tr>

                      {this.enumerateDays().map((date) => (
                        <tr key={date}>
                          <th className="title">{date}</th>
                          <td>N/A</td>
                          <td>N/A</td>
                          <td>{state.purchaseData.preview.liveQuan[date] || 0}</td>
                          <td>{state.purchaseData.preview.liveSum[date] || 0}</td>
                          <td>{Object.keys(state.purchaseData.preview.liveNum[date] || {}).length}</td>
                          <td>{state.purchaseData.preview.allQuan[date] || 0}</td>
                          <td>{state.purchaseData.preview.allSum[date] || 0}</td>
                          <td>{Object.keys(state.purchaseData.preview.allNum[date] || {}).length}</td>
                          <td>{Object.keys(state.liveUsers[date] || {}).length}</td>
                          <td>{Object.keys(state.liveClicks[date] || {}).length}</td>
                          <td>{state.purchaseData.live.liveQuan[date] || 0}</td>
                          <td>{state.purchaseData.live.liveSum[date] || 0}</td>
                          <td>{Object.keys(state.purchaseData.live.liveNum[date] || {}).length}</td>
                          <td>{state.purchaseData.live.allQuan[date] || 0}</td>
                          <td>{state.purchaseData.live.allSum[date] || 0}</td>
                          <td>{Object.keys(state.purchaseData.live.allNum[date] || {}).length}</td>
                          <td>{Object.keys(state.archiveUsers[date] || {}).length}</td>
                          <td>{Object.keys(state.archiveClicks[date] || {}).length}</td>
                          <td>{state.purchaseData.archive.liveQuan[date] || 0}</td>
                          <td>{state.purchaseData.archive.liveSum[date] || 0}</td>
                          <td>{Object.keys(state.purchaseData.archive.liveNum[date] || {}).length}</td>
                          <td>{state.purchaseData.archive.allQuan[date] || 0}</td>
                          <td>{state.purchaseData.archive.allSum[date] || 0}</td>
                          <td>{Object.keys(state.purchaseData.archive.allNum[date] || {}).length}</td>
                          <td>{Object.keys(state.liveArchiveUsers[date] || {}).length}</td>
                          <td>{Object.keys(state.liveArchiveClicks[date] || {}).length}</td>
                          <td>{(state.purchaseData.archive.liveQuan[date] || 0) + (state.purchaseData.live.liveQuan[date] || 0)}</td>
                          <td>{(state.purchaseData.archive.liveSum[date] || 0) + (state.purchaseData.live.liveSum[date] || 0)}</td>
                          <td>{Object.keys(state.purchaseData.liveArchive.liveNum[date] || {}).length}</td>
                          <td>{(state.purchaseData.archive.allQuan[date] || 0) + (state.purchaseData.live.allQuan[date] || 0)}</td>
                          <td>{(state.purchaseData.archive.allSum[date] || 0) + (state.purchaseData.live.allSum[date] || 0)}</td>
                          <td>{Object.keys(state.purchaseData.liveArchive.allNum[date] || {}).length}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>

                <div className="apiData_subtitle">掲載商品別レポート</div>
                {state.unitStats && (
                  <div className="apidataOverall">
                    <table>
                      <tbody>
                        <tr>
                          <th className="title" colSpan="2"></th>
                          <th colSpan="2">ウェイティング</th>
                          <th colSpan="2">ライブ再生</th>
                          <th colSpan="2">アーカイブ再生</th>
                          <th colSpan="2">合計</th>
                        </tr>
                        <tr>
                          <th className="title">SKU ID</th>
                          <th className="title">商品名</th>
                          <th>購入個数</th>
                          <th>購入金額（円）</th>
                          <th>購入個数</th>
                          <th>購入金額（円）</th>
                          <th>購入個数</th>
                          <th>購入金額（円）</th>
                          <th>購入個数</th>
                          <th>購入金額（円）</th>
                        </tr>
                        {state.unitIds.map((unitId) => (
                          <tr key={unitId}>
                            <th className="title">{unitId}</th>
                            <th className="title">{state.unitStats[unitId].name}</th>
                            <td>{state.unitStats[unitId].preview.quantity}</td>
                            <td>{state.unitStats[unitId].preview.price}</td>
                            <td>{state.unitStats[unitId].live.quantity}</td>
                            <td>{state.unitStats[unitId].live.price}</td>
                            <td>{state.unitStats[unitId].archive.quantity}</td>
                            <td>{state.unitStats[unitId].archive.price}</td>
                            <td>{state.unitStats[unitId].preview.quantity + state.unitStats[unitId].live.quantity + state.unitStats[unitId].archive.quantity}</td>
                            <td>{state.unitStats[unitId].preview.price + state.unitStats[unitId].live.price + state.unitStats[unitId].archive.price}</td>
                          </tr>
                        ))}
                        <tr>
                          <th className="title" colSpan="2">合計</th>
                          <td>{state.unitStats.total.preview.quantity}</td>
                          <td>{state.unitStats.total.preview.price}</td>
                          <td>{state.unitStats.total.live.quantity}</td>
                          <td>{state.unitStats.total.live.price}</td>
                          <td>{state.unitStats.total.archive.quantity}</td>
                          <td>{state.unitStats.total.archive.price}</td>
                          <td>{state.unitStats.total.total.quantity}</td>
                          <td>{state.unitStats.total.total.price}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                )}

                {ENABLE_LIVESTATS && window.location.search.indexOf('livestats') !== -1 && (
                  <div>
                    <h5>Live Stats</h5>
                    <div className="apidataOverall">
                      <table>
                        <tbody>
                          <tr>
                            <th>#Live viewer</th>
                            {state.liveViewStats.map((numViewers) =>
                              <td>{Math.floor(numViewers / state.liveTotalViewers * 100)}%</td>)}
                          </tr>
                          <tr>
                            <th>#Transaction</th>
                            {state.liveOrderStats.map((numOrders) => <td>{numOrders}</td>)}
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                )}

                {ENABLE_LIVESTATS_GRAPH && state.liveMinutesArray.length > 0 && (
                  <div>
                    <div className="apiData_subtitle">Live Stats</div>
                    <h5>グラフ1</h5>
                    <div className="apidataOverall">
                      <table>
                        <tbody>
                          <tr>
                            <th className="title">時間（分）</th>
                            {state.liveMinutesArray.map((minutes, index) => (
                              <td key={index}>{minutes}</td>
                            ))}
                          </tr>
                          <tr>
                            <th className="title">視聴維持率</th>
                            {state.watchKeepCountArray.map((watchKeepCount, index) => (
                              <td key={index}>{watchKeepCount} %</td>
                            ))}
                          </tr>
                          <tr>
                            <th className="title">購入数</th>
                            {state.purchaseCountArray.map((purchaseCount, index) => (
                              <td key={index}>{purchaseCount}</td>
                            ))}
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <LiveReportGraph1 data={state.graph1Data} />

                    <h5>グラフ2</h5>
                    <div className="apidataOverall">
                      <table>
                        <tbody>
                          <tr>
                            <th className="title">時間（分）</th>
                            {state.liveMinutesArray.map((minutes, index) => (
                              <td key={index}>{minutes}</td>
                            ))}
                          </tr>
                          <tr>
                            <th className="title">商品クリック数</th>
                            {state.productClickCountArray.map((productClickCount, index) => (
                              <td key={index}>{productClickCount}</td>
                            ))}
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <LiveReportGraph2 data={state.graph2Data} />
                  </div>
                )}

                <div className="apiData_bottomArea">
                  <div className="apiData_downloadButtons">
                    <button
                      type="button"
                      className="strongBtn apiData_downloadButton"
                      onClick={this.download}
                    >
                      注文ログのダウンロード
                    </button>
                    <CSVLink
                      data={ApiCSVData('ApiData', this.state, this.enumerateDays())}
                      filename={'report.csv'}
                      className="strongBtn apiData_downloadButton"
                    >
                      レポートデータのダウンロード
                    </CSVLink>
                  </div>
                  {this.state.isIE && (
                    <div className="error mt10 ml10">
                      <span>
                        お使いの環境では、レポートダウンロード機能はご利用いただけません、Google Chromeにてご利用ください
                      </span>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}
