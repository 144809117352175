import React, { Component } from 'react';
import firebase from './firebase';
import model from './Model';
import { Redirect } from 'react-router';

export default class Agreement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      shop: null,
      agreementFlag: false,
      shopDetailFlag: false,
    };
  }

  componentDidMount() {
    model.collection('shops').where('uid', '==', firebase.auth().currentUser.uid).get().then((data) => {
      const shop = data.docs[0].data();
      this.setState({
        shop: shop,
        loading: false,
        agreementFlag: Boolean(shop.agreementFlag),
        shopDetailFlag: shop.sellerName && shop.sellerEmail,
      });
    });
  }

  sendAgreement = (e) => {
    e.preventDefault();
    const shopsRef = model.collection('shops');
    if (this.state.shop.id) {
      shopsRef.doc(this.state.shop.id).update({
        agreementFlag: true,
        agreementTime: firebase.firestore.FieldValue.serverTimestamp(),
      }).then(() => {
        shopsRef.doc(this.state.shop.id).get().then((data) => {
          const time = data.data().agreementTime.toDate();
          model.fetch('api/sendAgreementMail/', 'POST', {
            name: this.state.shop.sellerName,
            mail: this.state.shop.sellerEmail,
            time: time.toLocaleString('ja-JP')
          })
        });
        this.setState({ agreementFlag: true });
      });
    }
  };

  render() {
    if (this.state.loading) {
      return <div />;
    }
    if (this.state.agreementFlag) {
      return <Redirect to={{ pathname: "/shop"}} />;
    }
    return (
      <div>
        <div className="loginContainer notitle">
          <div className="AppTitleContainer">
          </div>
          <div className="cardMini">
            <div className="cardMiniContainer">
              {this.state.shopDetailFlag ? (
                <React.Fragment>
                  <p className="mb20">TAGsAPI利用規約</p>
                  <p className="mb20">TAGsAPIを利用するには、<br></br>下記の<a className="" href="https://tagsapi.com/terms/" target="_blank" rel="noopener noreferrer">利用規約</a>への同意が必要です。</p>
                  <a className="mr10 normalBtn largeBtn" href="/shop">キャンセル</a>
                  <a className="strongBtn largeBtn" onClick={this.sendAgreement} href="#/">利用規約に同意する</a>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <p className="mb20">TAGsAPI アカウントの準備中です。</p>
                  <p className="mb20">ご利用の準備が完了するまでお待ちください。</p>
                </React.Fragment>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
