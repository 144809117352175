import React from 'react';
import { connectPagination } from 'react-instantsearch-dom';

import arrowLeftIcon from './img/icon/icon_arrow_left.svg';
import arrowRightIcon from './img/icon/icon_arrow_right.svg';

const Pagination = ({ currentRefinement, nbPages, refine, createURL }) => {
  const prevPage = currentRefinement - 1;
  const nextPage = currentRefinement + 1;
  const prevStyle = prevPage < 1 ? { pointerEvents: 'none', opacity: 0.5 } : null;
  const nextStyle = nextPage > nbPages ? { pointerEvents: 'none', opacity: 0.5 } : null;
  return (
    <div className="pager">
      <ul className="pagination">
        <li className="prev" style={prevStyle}>
          <a href={createURL(prevPage)} onClick={event => { event.preventDefault(); refine(prevPage); }}>
            <span style={prevStyle}>
              <img src={arrowLeftIcon} className="mr10" alt="前へ" />
              前へ
            </span>
          </a>
        </li>
        <li className="next" style={nextStyle}>
          <a href={createURL(nextPage)} onClick={event => { event.preventDefault(); refine(nextPage); }}>
            <span style={nextStyle}>
              次へ
              <img src={arrowRightIcon} className="ml10" alt="次へ" />
            </span>
          </a>
        </li>
      </ul>
    </div>
  );
};

export default connectPagination(Pagination);
