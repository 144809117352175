import React, { Component } from 'react';
import {
  Button,
  Col,
  ControlLabel,
  FormGroup,
  FormControl,
  Nav,
  NavItem,
} from 'react-bootstrap';

import './App.css';
import SellerNav from './SellerNav';
import firebase from './firebase';

const db = firebase.firestore();

export default class Payout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      payout: {
        bank: '',
        branch: '',
        type: '',
        account: '',
        name: '',
      },
      error: '',
    };
  }

  componentDidMount() {
    this.uid = firebase.auth().currentUser.uid;
    db.collection('payouts').doc(this.uid).get().then((doc) => {
      if (doc.exists) {
        this.setState({ payout: doc.data() });
      }
    });
  }

  onChange = (e) => {
    const { payout } = this.state;
    payout[e.target.name] = e.target.value;
    this.setState({ payout });
  };

  updatePayout = (e) => {
    e.preventDefault();
    db.collection('payouts').doc(this.uid).set(this.state.payout).then(() => {
      db.collection('shops').where('uid', '==', this.uid).get().then((data) =>
        data.forEach((doc) => db.collection('shops').doc(doc.id).update({ payout: this.uid })));
      db.collection('shops').where('admin', '==', this.uid).get().then((data) =>
        data.forEach((doc) => db.collection('shops').doc(doc.id).update({ payout: this.uid })));
    });
  };

  render() {
    const { payout, error } = this.state;
    return (
      <div>
      <SellerNav permission={this.props.permission}/>
      <div className="content">
        <h2>売上管理</h2>
        <Nav bsStyle="pills">
          <NavItem href="/earnings">売上照会</NavItem>
          <NavItem href="/payout">振込口座設定</NavItem>
        </Nav>
        <div className="card">
          <form className="form form-horizontal" onSubmit={this.updatePayout}>
            <FormGroup controlId="bank">
              <Col componentClass={ControlLabel} sm={4}>銀行名</Col>
              <Col sm={8}>
                <FormControl name="bank" type="text" placeholder="銀行名" value={payout.bank}
                  onChange={this.onChange} />
              </Col>
            </FormGroup>

            <FormGroup controlId="branch">
              <Col componentClass={ControlLabel} sm={4}>支店名</Col>
              <Col sm={8}>
                <FormControl name="branch" type="text" placeholder="支店名" value={payout.branch}
                  onChange={this.onChange} />
              </Col>
            </FormGroup>

            <FormGroup controlId="type">
              <Col componentClass={ControlLabel} sm={4}>口座種類</Col>
              <Col sm={8}>
                <FormControl name="type" type="text" placeholder="口座種類" value={payout.type}
                  onChange={this.onChange} />
              </Col>
            </FormGroup>

            <FormGroup controlId="account">
              <Col componentClass={ControlLabel} sm={4}>口座番号</Col>
              <Col sm={8}>
                <FormControl name="account" type="text" placeholder="口座番号" value={payout.account}
                  onChange={this.onChange} />
              </Col>
            </FormGroup>

            <FormGroup controlId="name">
              <Col componentClass={ControlLabel} sm={4}>口座名義</Col>
              <Col sm={8}>
                <FormControl name="name" type="text" placeholder="口座番号" value={payout.name}
                  onChange={this.onChange} />
              </Col>
            </FormGroup>

            <FormGroup>
              <Col smOffset={4} sm={8}>
                <Button type="submit" bsStyle="warning">登録する</Button>
              </Col>
            </FormGroup>

            <FormGroup>
              <Col smOffset={4} sm={8}>
                <p className="error">{error}</p>
              </Col>
            </FormGroup>
          </form>

        </div>
      </div>
      </div>
    );
  }
}
